import React from 'react'
import { useTranslation } from 'react-i18next';
import GetStaffType from '../../../../Components/GetStaffType';
import { staffTypesSubMenu } from '../../../../utils/constants';
import styles from '../formStyles.module.css';

function StaffMemberTypeSelector() {
    const { t } = useTranslation();

    return (
        <div className={styles['custom-form-row']} id={styles['custom-form-choose-staff-type']}>
            <div >
                {
                    staffTypesSubMenu.slice(0, 6).map((type) => {
                        return <div className={styles["custom-form-professor-checkbox"]}>
                            <input
                                type="radio"
                                id={type.value}
                                value={type.value}
                                name="staff-type-radio"
                            />
                            <label>{t(GetStaffType(type.value))}</label>
                        </div>
                    })
                }
            </div>
            <div>
                {
                    staffTypesSubMenu.slice(6, 12).map((type) => {
                        return <div className={styles["custom-form-professor-checkbox"]}>
                            <input
                                type="radio"
                                id={type.value}
                                value={type.value}
                                name="staff-type-radio"
                            />
                            <label>{t(GetStaffType(type.value))}</label>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default StaffMemberTypeSelector