import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function getAnnouncement(id) {
    const announcementResponse = await makeRequest(
        'get',
        `${API_URL}/announcements/${id}`,
        {},
        {},
        {}
    );
    return announcementResponse.data;
}

export async function getAnnouncementsWithLimit(limit) {
    const announcementsResponse = await makeRequest(
        'get',
        `${API_URL}/announcements?limit=${limit}`,
        {},
        {},
        {}
    );
    return announcementsResponse.data;
}

export async function createAnnouncement(data) {
    await makeRequest(
        'post',
        `${API_URL}/announcements/add`,
        data,
        {},
        getRequestHeaders(),
    );
}


export async function patchAnnouncement(id, data) {
    await makeRequest(
        'patch',
        `${API_URL}/announcements/${id}`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function deleteAnnouncement(id) {
    await makeRequest(
        'delete',
        `${API_URL}/announcements/${id}`,
        {},
        {},
        getRequestHeaders(),
    );
}