import React, { useEffect, useState } from 'react';
import MSL from '../../assets/msl-logo.png';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Footer, CustomNavbar as Navbar } from '../../Components';
import styles from './History.module.css';
import { getAllLabs } from '../../utils/API/labRequests';

function History() {
    const { t } = useTranslation();
    const [labs, setLabs] = useState([]);
    // Check if user is logged in
    useEffect(() => {
        const fetchLabs = async () => {
            const labResponse = await getAllLabs();
            setLabs(labResponse);
        }
        fetchLabs();
    }, [])
    return (
        <div className={styles['history-container']}>
            <Navbar />
            <div className={styles['history-container-body']}>
                <div className={styles['history-container-img']}>
                    <img src={MSL} alt="msl-logo" />
                </div>
                <h2>{t('history-title')}</h2>
                <p>
                    <strong>{t('history-msl-name')}</strong>
                    {t('history-first-paragraph')}
                </p>
                <p>
                    {
                        `${t('history-second-paragraph')} ${labs.length} ${t('history-second-paragraph-part02')}`
                    }
                </p>
                <ul>
                    {
                        labs.map((lab) => {
                            let title = `title_${i18next.language}`;
                            return (
                                <li key={lab.id}>
                                    {lab[title]}
                                </li>
                            )
                        })
                    }
                </ul>
                <p>{t('history-third-paragraph')}</p>
            </div>
            <Footer />
        </div>
    )
}

export default History