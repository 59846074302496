import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ModelsAndTechniques.module.css';

function ModelsAndTechniques() {

    const { t } = useTranslation();
    const [linear, setLinear] = useState(true);
    const [integerProgramming, setIntegerProgramming] = useState(false);
    const [networks, setNetworks] = useState(false);
    const [otherMathematicalProgramming, setOtherMathematicalProgramming] = useState(false);
    const [multicriteriaAnalysis, setMulticriteriaAnalysis] = useState(false);
    const [decisionAnalysis, setDecisionAnalysis] = useState(false);
    const [riskManagement, setRiskManagement] = useState(false);
    const [simulation, setSimulation] = useState(false);
    const [solvedProblems, setSolvedProblems] = useState(false);

    return (
        <div className={styles['student-support-models-and-techniques-container']}>

            <h3>
                {t('student-support-sidebar-menu-01')}
            </h3>

            <div className={styles['models-and-techniques-menu']}>
                <div className={styles['models-and-techniques-menu-row']}>
                    <p
                        onClick={() => { setLinear(true); setIntegerProgramming(false); setNetworks(false); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(false); setDecisionAnalysis(false); setRiskManagement(false); setSimulation(false); setSolvedProblems(false); }}
                    >Linear Programming</p>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(true); setNetworks(false); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(false); setDecisionAnalysis(false); setRiskManagement(false); setSimulation(false); setSolvedProblems(false); }}
                    >Integer Programming</p>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(false); setNetworks(true); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(false); setDecisionAnalysis(false); setRiskManagement(false); setSimulation(false); setSolvedProblems(false); }}
                    >Networks and Vehicle Routing</p>
                </div>
                <div className='models-and-techniques-menu-row'>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(false); setNetworks(false); setOtherMathematicalProgramming(true); setMulticriteriaAnalysis(false); setDecisionAnalysis(false); setRiskManagement(false); setSimulation(false); setSolvedProblems(false); }}
                    >Other Mathematical Programming</p>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(false); setNetworks(false); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(true); setDecisionAnalysis(false); setRiskManagement(false); setSimulation(false); setSolvedProblems(false); }}
                    >Multicriteria Analysis</p>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(false); setNetworks(false); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(false); setDecisionAnalysis(true); setRiskManagement(false); setSimulation(false); setSolvedProblems(false); }}
                    >Decision Analysis</p>
                </div>
                <div className={styles['models-and-techniques-menu-row']}>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(false); setNetworks(false); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(false); setDecisionAnalysis(false); setRiskManagement(true); setSimulation(false); setSolvedProblems(false); }}
                    >Risk Management</p>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(false); setNetworks(false); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(false); setDecisionAnalysis(false); setRiskManagement(false); setSimulation(true); setSolvedProblems(false); }}
                    >Simulation</p>
                    <p
                        onClick={() => { setLinear(false); setIntegerProgramming(false); setNetworks(false); setOtherMathematicalProgramming(false); setMulticriteriaAnalysis(false); setDecisionAnalysis(false); setRiskManagement(false); setSimulation(false); setSolvedProblems(true); }}
                    >Solved Problems</p>
                </div>
            </div>

            <hr />

            <div className={styles['models-and-techniques-content']}>

                {
                    linear &&
                    <div className={styles['models-and-techniques-content-linear']}>

                        <p>
                            Linear Programming is the most developed technique of Management Science and Operations Research, and we present a selection of interesting links on it.
                        </p>

                        <h5>
                            Introduction
                        </h5>
                        <a href='https://neos-guide.org/lp-faq' target="_blank" rel="noreferrer">
                            Frequently Asked Questions on Linear Programming
                        </a>
                        <p>
                            This is an excellent presentation of Linear Programming, in the form of structured questions and answers, prepared and maintained by Prof. R. Fourer of Northwestern University, in collaboration with Argonne National Laboratories.
                        </p>
                        <a href='https://neos-guide.org/Optimization-Guide' target="_blank" rel="noreferrer">
                            Linear Programming review
                        </a>
                        <p>
                            It is a brief review on Linear Programming that mainly includes the history and the mathematical basis for this method.
                        </p>

                        <h5>
                            Courses
                        </h5>
                        <a href='https://vanderbei.princeton.edu/542/lectures.html' target="_blank" rel="noreferrer">
                            Lecture notes from Princeton University on Linear Programming
                        </a>
                        <p>
                            Lecture notes based on the book "Foundations and Extensions" for Graduate Level students. The book is available on-line. (Refer to the "Mathematical Formulation" section)
                        </p>
                        <a href='https://mat.gsia.cmu.edu/classes/QUANT/NOTES/chap7/chap7.html' target="_blank" rel="noreferrer">
                            The Simplex Method
                        </a>
                        <p>
                            Course notes on the simplex method by Michael Trick from the Carnegie Mellon Universtity.
                        </p>
                        <a href='https://www.math.uwaterloo.ca/~hwolkowi//henry/teaching/f05/350.f05/' target="_blank" rel="noreferrer">
                            Linear Optimization course: link 1
                        </a>
                        &amp;
                        <a href='http://www.math.uwaterloo.ca/~harvey/F10/' target="_blank" rel="noreferrer">
                            link 2
                        </a>
                        <p>
                            This site consists of seven assignments, its solutions, twenty lectures and general material from the University of Waterloo.
                        </p>

                        <h5>
                            Mathematical Formulation of LP Problems
                        </h5>
                        <a href='https://vanderbei.princeton.edu/LPbook/' target="_blank" rel="noreferrer">
                            Linear Programming: Foundations and Extensions
                        </a>
                        <p>
                            A book about covers a wide range of subjects concerning optimization and is a valuable source for those who want to learn more about the mathematical formulation of this type of problems. It is written by Robert J. Vanderbei from the Department of Operations Research and Financial Engineering of Princeton University and is used a course book from many Universities.
                        </p>
                        <a href='https://neos-guide.org/content/simplex-method' target="_blank" rel="noreferrer">
                            The Simplex method
                        </a>
                        <p>
                            The simplex method was the first method developed to solve linear programs. In these pages, this site attempts to teach how the simplex method works. Users can select the size of the problem that they want to solve, enter the data, and watch the simplex method go through each step, of every iteration, of the method. This is a brief review on Simplex Method that mainly includes the history and the mathematical basis for this method.
                        </p>

                        <h5>
                            Tutorials on the Simplex Method
                        </h5>
                        <p>
                            Stefan Waner and Steven R. Costenoble from the Department of Mathematics in Hofstra University, provide us with easy tutorials and an quick quiz in linear programming.
                        </p>
                        <a href='https://www.zweigmedia.com/RealWorld/tutorialsf4/framesSimplex.html' target="_blank" rel="noreferrer">
                            Introduction to Linear Programming and Simplex method (1)
                        </a>
                        <br />
                        <a href='people.hofstra.edu/Stefan_Waner/RealWorld/tutorialsf4/frames4_4.html' target="_blank" rel="noreferrer">
                            Introduction to Linear Programming and Simplex method (2)
                        </a>
                        <br />
                        <a href='people.hofstra.edu/Stefan_Waner/RealWorld/quizindex.html' target="_blank" rel="noreferrer">
                            Quiz in Linear Programming
                        </a>
                        <p>
                            A tutorial on Linear Programming, with emphasis on the mathematical formulation, by Michael Trick from the Graduate School of Industrial Administration of the Carnegie Mellon University.
                        </p>

                        <h5>
                            Student Problems in English
                        </h5>
                        <a href='https://msl.aueb.gr/files/LP-Formulation.pdf' target="_blank" rel="noreferrer">
                            Formulation of LP problems
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/LP-Solution.pdf' target="_blank" rel="noreferrer">
                            Solution of LP problems
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/Duality&Sensitivity(1).pdf' target="_blank" rel="noreferrer">
                            Sensitivity Analysis and Duality
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/LP-Duality&sensitivitySolver.pdf' target="_blank" rel="noreferrer">
                            Sensitivity Analysis and Duality using Solver
                        </a>

                        <h5>
                            Student Problems in Greek
                        </h5>
                        <a href='https://msl.aueb.gr/files/Grammikos_programmatismos.pdf' target="_blank" rel="noreferrer">
                            Διατύπωση προβλημάτων Γραμμικού Προγραμματισμού
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/Epilysi_provlimatwn_grammikou_progr.pdf' target="_blank" rel="noreferrer">
                            Επίλυση προβλημάτων Γραμμικού Προγραμματισμού
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/Analysi_eyaisthisias_Solver.pdf' target="_blank" rel="noreferrer">
                            Ανάλυση Ευαισθησίας και Δυϊκότητα με τη χρήση Solver
                        </a>

                        <h5>
                            Applications
                        </h5>
                        <a href='https://neos-guide.org/content/diet-problem' target="_blank" rel="noreferrer">
                            The Diet Problem: A Linear Programming Problem
                        </a>

                        <h5>
                            Software
                        </h5>
                        <a href='www.orms-today.org/surveys/LP/LP-survey.html' target="_blank" rel="noreferrer">
                            Software Review by OR/MS Today
                        </a>
                        <p>
                            This is an overview article, published in OR/MS Today, presenting the various software for Linear Programming
                        </p>
                        <a href='https://neos-guide.org/content/linear-programming-software' target="_blank" rel="noreferrer">
                            LP Software Review by Argonne National Laboratories
                        </a>
                        <p>
                            This is an overview article, published in Argonne National Laboratories, presenting various software for Linear Programming.
                        </p>

                    </div>
                }

                {
                    integerProgramming &&
                    <div className={styles['models-and-techniques-content-integer-programming']}>

                        <p>
                            Integer programming is a major area of research and applications in Operations Research and Management Science. The following links present interesting articles on Integer Programming methods, applications, and software.
                        </p>

                        <h5>
                            Introduction
                        </h5>
                        <a href='https://neos-guide.org/' target="_blank" rel="noreferrer">
                            Topics of Integer Programming
                        </a>
                        <p>
                            This web page, maintained by the Optimization Technology Center in collaboration with the Mathematical programming Society, features an interesting presentation on a number of topics on Integer Programming.
                        </p>

                        <h5>
                            Courses
                        </h5>
                        <a href='http://faculty.bus.olemiss.edu/crego/courses/mgmt674/mgmt674.htm' target="_blank" rel="noreferrer">
                            Course outline with emphasis on integer programming
                        </a>
                        <p>
                            This is a course outline on Advanced Operations Management, from the School of Business Administration of the University of Mississippi.
                        </p>
                        <a href='http://www.or.unc.edu/courses/indexfall.html#OR214' target="_blank" rel="noreferrer">
                            Course outline in Integer Programming with applications
                        </a>
                        <p>
                            This is a course outline on Integer Programming from the Department of Operations Research of the University of North Carolina.
                        </p>

                        <h5>
                            Mathematical Formulation
                        </h5>
                        <a href='http://people.brunel.ac.uk/~mastjjb/jeb/or/ip.html' target="_blank" rel="noreferrer">
                            Integer Programming: Introductory Notes
                        </a>
                        <p>
                            These notes are a series of introductory notes on topics that fall under the broad heading of the field of operations research (OR). They are written by J E Beasley and used as course notes in an introductory OR course at Imperial College.
                        </p>

                        <h5>
                            Tutorials on Integer Programming
                        </h5>
                        <a href='https://mat.gsia.cmu.edu/orclass/integer/integer.html' target="_blank" rel="noreferrer">
                            A Tutorial on Integer Programming
                        </a>
                        <p>
                            The above link refers to a tutorial on Integer Programming developed by Prof. M. Trick of the GSIA School, of Carnegie Mellon University.
                        </p>

                        <h5>
                            Applications on Integer Programming
                        </h5>
                        <a href='http://newton.cheme.cmu.edu/interfaces/' target="_blank" rel="noreferrer">
                            Applications on IP
                        </a>
                        <p>
                            This site, developed by the Carnegie Mellon University, provides applications and an interactive web-based software for Integer Programming Problems.
                        </p>


                        <h5>
                            Student Problems
                        </h5>
                        <a href='https://msl.aueb.gr/files/IntegerProgramming.pdf' target="_blank" rel="noreferrer">
                            Student Problems in English
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/Akeraios_programmatismos.pdf' target="_blank" rel="noreferrer">
                            Student Problems in Greek
                        </a>

                    </div>
                }

                {
                    networks &&
                    <div className={styles['models-and-techniques-content-networks']}>

                        <h5>
                            Networks
                        </h5>

                        <p>
                            Networks are a major area of research and applications in Operations Research and Management Science. They are also widely used in project management.
                        </p>

                        <a href='http://people.brunel.ac.uk/~mastjjb/jeb/or/netanal.html' target="_blank" rel="noreferrer">
                            Notes on Networks (project management)
                        </a>

                        <p>
                            These notes are a series of introductory notes on topics that fall under the broad heading of the field of operations research (OR). They are written by J E Beasley and used as course notes in an introductory OR course at Imperial College.
                        </p>

                        <a href='https://mat.gsia.cmu.edu/classes/networks/networks.html' target="_blank" rel="noreferrer">
                            A Tutorial on Networks, by Prof. M. Trick
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/Provlimata_diktywn.pdf' target="_blank" rel="noreferrer">
                            Student problems in Greek
                        </a>
                        <br />
                        <a href='http://support.sas.com/rnd/app/or/research/netWorks.html' target="_blank" rel="noreferrer">
                            SAS/OR Software
                        </a>



                        <h5>
                            Vehicle Routing Problem
                        </h5>
                        <p>
                            The vehicle routing problem can be defined as the problem of designing routes for delivery vehicles and is considered to be a challenging optimization task, as an optimal solution does not exist. Hence, the problem consists in designing routes for the vehicles with the objective of minimising a variable such as the total distance travelled.
                        </p>
                        <a href='neo.lcc.uma.es/radi-aeb/WebVRP/index.html?bibliography.html' target="_blank" rel="noreferrer">
                            The VRP Web
                        </a>
                        <p>
                            This is a very useful and interesting website about Vehicle Routing. It has been made in collaboration between AUREN and the Languages and Computation Sciences department of the University of Malaga by Bernabe Dorronsoro Diaz. It provides lot of information concerning the Vehicle Routing Problem, as well as an informed bibliography with the best-so-far solutions of some well-known instances of the problem.
                        </p>
                        <a href='www.orms-today.org/orms-8-00/vehiclerouting.html' target="_blank" rel="noreferrer">
                            Vehicle Routing Today, An Overview
                        </a>

                        <h5>
                            Software
                        </h5>
                        <a href='www.orms-today.org/surveys/Vehicle_Routing/vrss.html' target="_blank" rel="noreferrer">
                            Vehicle Routing Software Survey, by Randolph W. Hall
                        </a>
                        <p>
                            A side-by-side comparison of twenty-four vehicle routing software packages from twenty-one vendors including pricing and performance information, special features and significant installations.
                        </p>

                        <h5>
                            Data Sets
                        </h5>
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            Capacitated Vehicle Routing Problem (CVRP)
                        </a>
                        <p>
                            This set of data files are the 14 test problems from Chapter 11 of N.Christofides, A.Mingozzi, P.Toth and C.Sandi (eds) "Combinatorial optimization", John Wiley, Chichester 1979
                        </p>
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            Capacitated Vehicle Routing Problem with Time Windows (CVRPTW)
                        </a>
                        <p>
                            This set of data files are the 56 test problems of Solomon M.M (1987) "Algorithms for the vehicle routing and scheduling with time windows constraints" Operations Research 35, p.45-65
                        </p>
                        <p>
                            <strong>
                                Multi Compartment Commodity Heterogeneous Fixed Fleet Vehicle Routing Problem with Time Windows (MCCHFFVRPTW)
                            </strong>
                        </p>
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            Customers and Depot Data
                        </a>
                        <p>
                            The customers and depot data generated using Solomon's 100 customers VRPTW benchmark instances. More specifically, each customer's demand coupled with a particular commodity, while the total number of customers along with their coordinates, demand, service time and time window intervals maintainted intact. Below the data sets with two and three different commodities are given. The .zip files contain classes R1(4), RC1(4), R2(4), RC2(4). The structure of these files is the following: the first column refers to the customer indetification No, the second and third columns refer to x and y coordinates, the forth and fifth columns refer to the demand along with the respective commodity, and finally the rest columns provide the earliest, latest and service times, respectively. Note that the customer with identification No 0 represents the depot.
                        </p>
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            Vehicle Fleet Mix Characteristics
                        </a>
                        <p>
                            The vehicle fleet data was generated using Liu and Shen Fleet Size and Mix VRPTW benchmark instances. To define vehicle capacities and fixed costs Liu and Shen (1999b) proposed three different subclasses of instances a, b and c, each characterized by different fixed vehicle costs. The VFC.zip file provided below contains the fixed costs of the vehicles for subclass a, while A, B, C, D, E, F refer to the vehicle types. Moreover, for each the number of compartments along their associated capacities are given.
                        </p>
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            Fleet Composition (Fixed Fleet)
                        </a>
                        <p>
                            In order to define the available fleet composition and mix of vehicles, the best fleet mix obtained in [2] is assumed to be the given fixed fleet. The latter approach followed also by [3] and [4] for solving the Heterogeneous Fixed Fleet VRP. The fixed fleet used is given below for each respective problem instance. Below, the VFFC.zip contains for each respective problem instance the given fleet composition (Fixed Fleet).
                            <br />
                            References
                            <br />
                            [1] Solomon, M.M. (1987). Algorithms for the vehicle routing and scheduling with time windows contraints, Operations Research 35, 254-265.
                            <br />
                            [2] Liu, F.H. and S.Y. Shen (1999b). The Fleet Size and Mix Routing Problem with Time Windows, Journal of the Operational Research Society 50(7), 721-732.
                            <br />
                            [3] Tarantilis, C.D., Kiranoudis, C.T., Vassiliadis, V.S. (2004) A threshold accepting metaheuristic for the heterogeneous fixed fleet vehicle routing problem. European Journal of Operations Research, 148-158.
                            <br />
                            [4] Taillard, E.D.,(1999) A heuristic column generation method for the heterogeneous fleet VRP. RAIRO 1-14.
                        </p>
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            Vehicle Routing Problem with Intermediate Replenishment Facilities (VRPIRF)
                        </a>
                        <br />
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            The Capacitated Vehicle Routing Problem with Three-Dimensional Loading Constraints (3L-CVRP)
                        </a>
                        <br />
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            The Inventory Routing Problem over a finite periodic planning horizon
                        </a>
                        <br />
                        <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                            Moving products between location pairs: Cross-Docking versus Direct-Shipping
                        </a>

                    </div>
                }

                {
                    otherMathematicalProgramming &&
                    <div className={styles['models-and-techniques-content-other-mathematical-programming']}>

                        <h5>
                            Introduction
                        </h5>


                        <a href='https://glossary.informs.org/index.php?page=myths.html?page=myths.html' target="_blank" rel="noreferrer">
                            Myths and Counterexamples in Mathematical Programming
                        </a>
                        <p>
                            This link has been developed by Harvey J. Greenberg, and provides us with useful counterexamples to some mathematical statements that seem plausible.
                        </p>

                        <a href='https://glossary.informs.org/ver2/mpgwiki/index.php?title=Main_Page' target="_blank" rel="noreferrer">
                            Mathematical Programming Glossary
                        </a>
                        <p>
                            This web site is developed by Harvey J. Greenberg from Waterloo University and contains terms specific to mathematical programming, and some terms from other disciplines, notably economics, computer science, and mathematics, that are directly related.
                        </p>

                        <a href='https://mat.gsia.cmu.edu/classes/QUANT/NOTES/chap0/node7.html' target="_blank" rel="noreferrer">
                            Mathematical Modeling Today
                        </a>
                        <p>
                            This is a brief article describing applications of mathematical methods in management and economics.
                        </p>


                        <h5>
                            Non Linear Programming
                        </h5>
                        <a href='www.neos-guide.org/non-lp-faq' target="_blank" rel="noreferrer">
                            Frequently Asked Questions on Non-Linear Programming
                        </a>
                        <p>
                            This is an excellent presentation of Non-Linear Programming, in the form of structured questions and answers, prepared and maintained by Prof. R. Fourer of Northwestern University, in collaboration with Argonne National Laboratories.
                        </p>
                        <a href='https://neos-guide.org/content/quadratic-assignment-problem' target="_blank" rel="noreferrer">
                            The Quadratic Assignment Case Study. A Combinatorial Optimization Problem
                        </a>
                        <br />
                        <a href='https://neos-guide.org/content/quadratic-programming-software' target="_blank" rel="noreferrer">
                            Quadratic Programming Software Review
                        </a>
                        <p>
                            This is an overview article, published in Argonne National Laboratories, presenting various software for Quadratic Programming. It's an interactive case study that tests your skills at finding solutions to quadratic assignment problems.
                        </p>

                        <h5>
                            Stochastic Programming
                        </h5>
                        <a href='https://neos-guide.org/' target="_blank" rel="noreferrer">
                            Stochastic Programming
                        </a>
                        <p>
                            This is an overview article, published in Argonne National Laboratories, presenting various aspects of Stochastic Programming.
                        </p>
                        <a href='https://www2.isye.gatech.edu/~sahmed/splinks.html#Papers' target="_blank" rel="noreferrer">
                            Recent Papers on Stochastic Programming
                        </a>
                        <p>
                            Interesting papers referring on Continuous and Applied Integer programming, provided by the MORE institute in Arizona.
                        </p>
                        <a href='http://vlsicad.eecs.umich.edu/BK/Slots/cache/www.sie.arizona.edu/faculty/addenda/sen/encycl3.pdf' target="_blank" rel="noreferrer">
                            Stochastic Programming: Computational Issues and Challenges
                        </a>
                        <p>
                            This is another article about Stochastic Programming by Suvrajeet Sen from the University of Arizona.
                        </p>
                        <a href='https://neos-guide.org/Case-Studies' target="_blank" rel="noreferrer">
                            The Natural Gas Planning Case Study. A Stochastic Programming Problem
                        </a>
                        <p>
                            Most of the model formulations have assumed that the data for the given problem are known accurately. However, for many actual problems, the problem data cannot be known accurately for a variety of reasons. Therefore stochastic programming can be used to make some optimal decisions. This case illustrates this problem, taking this uncertainty into account.
                        </p>

                        <h5>
                            Dynamic Programming
                        </h5>
                        <a href='https://mat.gsia.cmu.edu/classes/dynamic/dynamic.html' target="_blank" rel="noreferrer">
                            Dynamic Programming: A Tutorial
                        </a>
                        <p>
                            A tutorial on Dynamic programming, prepared by Prof. M. Trick.
                        </p>
                        <a href='https://msl.aueb.gr/files/La5.pdf' target="_blank" rel="noreferrer">
                            Course notes in Dynamic programming
                        </a>
                        <p>
                            Course notes for a course in optimization, with dynamic and stochastic elements, taught to final year Cambridge mathematics students.
                        </p>
                        <a href='https://msl.aueb.gr/files/Dynamikos_programmatismos.pdf' target="_blank" rel="noreferrer">
                            Dynamic Programming problems in Greek
                        </a>
                    </div>
                }

                {
                    multicriteriaAnalysis &&
                    <div className={styles['models-and-techniques-content-multicriteria']}>

                        <h5>
                            Introduction
                        </h5>

                        <p>
                            Multicriteria analysis is a major area of research and applications in Operations Research and Management Science. We provide some useful links mainly about Analytical Hierarchy Process that is a common method in Decision Making. Other multicriteria methods include Goal programming and Electre method for ranking projects.
                        </p>


                        <a href='https://professorforman.com/Publications/default.html' target="_blank" rel="noreferrer">
                            The Analytic Hierarchy Process in Decision Making
                        </a>
                        <p>
                            A book written by Prof Forman of George Washington University on Decision Making with multiple criteria, and the Analytic Hierarchy Process. Introduction to Expert Choice.
                        </p>

                        <a href='https://mat.gsia.cmu.edu/classes/mstc/multiple/node5.html' target="_blank" rel="noreferrer">
                            Goal programming: Introduction
                        </a>
                        <p>
                            This is a simple problem that can be used as an introduction to Goal Programming, provided by Prof. M. Trick.
                        </p>

                        <h5>
                            Papers
                        </h5>
                        <a href='http://www.7kt.org/' target="_blank" rel="noreferrer">
                            Ranking Methods
                        </a>
                        <p>
                            A collection of papers on the ranking methods by Tsoukias, Vincke, Bouyssou, et.al
                        </p>
                        <a href='https://msl.aueb.gr/files/electre.pdf' target="_blank" rel="noreferrer">
                            Ranking projects using the ELECTRE method
                        </a>
                        <p>
                            This paper, written by John Buchanan from the Department of Management Systems of the University of Waikato, describes a real application of project selection using ELECTRE approach.
                        </p>
                        <a href='https://php.portals.mbs.ac.uk/Portals/49/docs/jyang/YangYang_MSM_Minigoal.pdf' target="_blank" rel="noreferrer">
                            Minimax Goal Programming for Managerial Decision Making
                        </a>
                        <p>
                            This is an interesting article by ShiFang Yang and J B Yang referring to Goal Programming and multi-objective optimization.
                        </p>
                        <a href='https://php.portals.mbs.ac.uk/Portals/49/docs/jyang/YangYang_MSM_Minigoal.pdf' target="_blank" rel="noreferrer">
                            Generalized Goal Programming: Polynomial Methods and Applications
                        </a>
                        <p>
                            This is an article by Emilio Carrizosa, from the University of Seville, that addresses a general Goal Programming problem with linear objectives and analyzes it from a geometrical and a computational viewpoint.
                        </p>

                        <h5>
                            Student Problems and Course Notes
                        </h5>
                        <a href='https://mat.gsia.cmu.edu/classes/mstc/multiple/node4.html#SECTION00040000000000000000' target="_blank" rel="noreferrer">
                            A Case Study on Analytic Hierarchy Process (AHP), by Michael A. Trick
                        </a>
                        <p>
                            Is there any organized way that one might think about determining the relative importance of the factors that influence a decision and then go about comparing alternative sites? One technique that is used is the Analytic Hierarchy Process (AHP), which uses very simple calculations to try to put numerical values on factors and alternatives. This site includes a case study that presents this technique.
                        </p>

                        <h5>
                            Societies and Software
                        </h5>
                        <a href='hhttp://www.mcdmsociety.org/' target="_blank" rel="noreferrer">
                            The International Society on Multiple Criteria Decision Making
                        </a>
                        <p>
                            A useful site with a number of resources and links on Multicriteria Decision Making
                        </p>
                        <a href='https://www.uc.pt/en/org/inescc/~ewgmcda/' target="_blank" rel="noreferrer">
                            The European Society for Multiple Criteria Decision Making
                        </a>
                        <p>
                            Another useful site on the European Group, call for meetings, books, etc
                        </p>

                    </div>
                }

                {
                    decisionAnalysis &&
                    <div className={styles['models-and-techniques-content-decision-analysis']}>

                        <p>
                            Decision analysis is a major area of research and applications in Operations Research and Management Science. This page provides a number of links on Decision Analysis - Decision Trees, including methodological papers, application references, software reviews, and additional resources.
                        </p>

                        <h5>
                            Introduction
                        </h5>

                        <a href='https://www.informs.org/Community/DAS' target="_blank" rel="noreferrer">
                            Decision Analysis Resources by INFORMS
                        </a>
                        <p>
                            An excellent site including many support material for Decision Analysis, by the Decision Analysis Society of INFORMS
                        </p>

                        <a href='https://hbswk.hbs.edu/archive/putting-real-options-to-work-to-improve-project-planning-project-analysis-climb-the-decision-tree' target="_blank" rel="noreferrer">
                            Project Analysis. Climb the Decision Tree, by Fabian D'Souza
                        </a>
                        <p>
                            When used as a strategic planning tool, decision analysis, can help managers address issues such as how to allocate resources to ensure that the project meets specific deadlines, when to scale up or delay investments, and when to exit a project.
                        </p>

                        <h5>
                            Mathematical Formulation
                        </h5>
                        <a href='https://mat.gsia.cmu.edu/classes/QUANT/NOTES/chap9/chap9.html' target="_blank" rel="noreferrer">
                            Decision Theory
                        </a>
                        <p>
                            The mathematical formulation of decision under risk and some examples of Decision Trees by Michael Trick from the Graduate School of Industrial Administration of the Carnegie Mellon University.
                        </p>
                        <a href='http://people.brunel.ac.uk/~mastjjb/jeb/or/dectree.html' target="_blank" rel="noreferrer">
                            Decision Trees
                        </a>
                        <p>
                            These notes are a series of introductory notes on topics that fall under the broad heading of the field of operations research (OR). They are written by J E Beasley and used as course notes in an introductory OR course at Imperial College.
                        </p>

                        <h5>
                            Student Problems
                        </h5>
                        <a href='https://msl.aueb.gr/files/DA_Exercises.pdf' target="_blank" rel="noreferrer">
                            Student Problems in English
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/Provlimata_sti_theoria_apofasewn.pdf' target="_blank" rel="noreferrer">
                            Student Problems in Greek
                        </a>

                        <h5>
                            Student Cases
                        </h5>
                        <a href='https://msl.aueb.gr/files/DA_Case_1_Flex.pdf' target="_blank" rel="noreferrer">
                            Flex Silicon Products Case
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/DA_Case_2_Inter.pdf' target="_blank" rel="noreferrer">
                            Intermodular Semiconductor Systems Case
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/DA_Case_3_Mominco.pdf' target="_blank" rel="noreferrer">
                            Mominco Case
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/DA_Case_4_Xanidu.pdf' target="_blank" rel="noreferrer">
                            Xanidu Traders Case
                        </a>

                        <h5>
                            Societies and Software
                        </h5>
                        <a href='hhttp://www.mcdmsociety.org/' target="_blank" rel="noreferrer">
                            The International Society on Multiple Criteria Decision Making
                        </a>
                        <p>
                            A useful site with a number of resources and links on Multicriteria Decision Making
                        </p>
                        <a href='https://www.uc.pt/en/org/inescc/~ewgmcda/' target="_blank" rel="noreferrer">
                            The European Society for Multiple Criteria Decision Making
                        </a>
                        <p>
                            Another useful site on the European Group, call for meetings, books, etc
                        </p>

                    </div>
                }

                {
                    riskManagement &&
                    <div className={styles['models-and-techniques-content-risk-management']}>
                        <a href='https://pubsonline.informs.org/magazine/orms-today' target="_blank" rel="noreferrer">
                            Managing Risk,Reaping Rewards
                        </a>
                        <p>
                            A very interesting article from OR/MS Today, by Stavros A.Zenios, referring to enterprise-wide risk management and the benefits of operations research to this subject.
                        </p>
                        <a href='http://ccs.mit.edu/dell/riskmgt.html' target="_blank" rel="noreferrer">
                            Tools for Systematic Operational Risk Management
                        </a>
                        <p>
                            This is a link to an article hosted in MIT web site, proposing a knowledge-based approach for designing robust business processes. It also provides links to related papers.
                        </p>
                        <a href='https://www.federalreserve.gov/boarddocs/speeches/2004/20040716/default.htm' target="_blank" rel="noreferrer">
                            Using Enterprise-wide Risk Management to Effectively Execute Business Strategies
                        </a>
                        <p>
                            This is a speech at a Conference that refers to the components of successful risk management, and describes how risk management can support more effective implementation of business strategies.
                        </p>
                        <a href='http://www.riskmania.com/pdsdata/Enterprise-wide%20Risk%20Management%20and%20the%20Role%20of%20the%20Chief%20Risk%20Officer-erisk.pdf' target="_blank" rel="noreferrer">
                            Enterprise-wide risk management and the role of the chief risk officer
                        </a>
                        <p>
                            This is an article by James Lam stressing the importance of Enterprise-wide risk management and explaining why it is fast becoming the best practice standard.
                        </p>
                        <a href='https://www.bis.org/publ/bcbs42.htm' target="_blank" rel="noreferrer">
                            Operational Risk Management
                        </a>
                        <p>
                            The provisions of the Bank of International Settlements concerning the Operational Risk Management for Banks.
                        </p>
                        <a href='https://pubsonline.informs.org/magazine/orms-today' target="_blank" rel="noreferrer">
                            Risky Business
                        </a>
                        <p>
                            This is an article from the ORMS journal emphasizing on operational risk management in financial services written by Fotios C. Harmantzis.
                        </p>
                    </div>
                }

                {
                    simulation &&
                    <div className={styles['models-and-techniques-content-simulation']}>
                        <p>
                            This page provides a number of links on Simulation - System Dynamics
                        </p>
                        <h5>
                            Introduction
                        </h5>
                        <a href='http://wwwu.uni-klu.ac.at/gossimit/linklist.php' target="_blank" rel="noreferrer">
                            System Dynamics Mega Link
                        </a>
                        <p>
                            This web site contains more than 300 different links about System Dynamics and Systems Thinking resources in educational and business contexts.
                        </p>
                        <a href='https://www.iseesystems.com/' target="_blank" rel="noreferrer">
                            High Performance Systems
                        </a>

                        <h5>
                            Student Problems
                        </h5>
                        <a href='https://msl.aueb.gr/files/Prosomeiwsi.pdf' target="_blank" rel="noreferrer">
                            Student Problems in Greek
                        </a>
                    </div>
                }

                {
                    solvedProblems &&
                    <div className={styles['models-and-techniques-content-solved-problems']}>
                        <p>
                            This section includes solved problems from Prof. Gregory Prastacos' recent book entitled "Managerial Decision Making".
                        </p>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/introLP.html' target="_blank" rel="noreferrer">
                                    Introduction to Linear Programming
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/solverLP.html' target="_blank" rel="noreferrer">
                                    Using SOLVER for Linear Programming
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/sa.html' target="_blank" rel="noreferrer">
                                    Sensitivity Analysis
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/ip.html' target="_blank" rel="noreferrer">
                                    Integer Programming
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/da.html' target="_blank" rel="noreferrer">
                                    Decision Analysis
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/msm.html' target="_blank" rel="noreferrer">
                                    Marketing and Sales Management
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/pim.html' target="_blank" rel="noreferrer">
                                    Production and Inventory Management
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/translog.html' target="_blank" rel="noreferrer">
                                    Transportation and Logistics
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/finv.html' target="_blank" rel="noreferrer">
                                    Finance and Investments
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/ufin.html' target="_blank" rel="noreferrer">
                                    Uncertainty in Finance
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/hrm.html' target="_blank" rel="noreferrer">
                                    Human Resources Management
                                </a>
                            </li>
                        </ul>

                    </div>
                }

            </div>

        </div>
    )
}

export default ModelsAndTechniques