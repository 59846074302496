import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GeneralMaterial.module.css';

function GeneralMaterial() {
    const { t } = useTranslation();
    const [intro, setIntro] = useState(true);
    const [courses, setCourses] = useState(false);
    const [articles, setArticles] = useState(false);
    const [decision, setDecision] = useState(false);
    return (
        <div className={styles['student-support-general-material-container']}>
            <h3>
                {t('student-support-sidebar-menu-00')}
            </h3>
            <div className={styles['general-material-menu']}>
                <p
                    onClick={() => { setIntro(true); setCourses(false); setArticles(false); setDecision(false); }}
                >Introduction</p>
                <p
                    onClick={() => { setIntro(false); setCourses(true); setArticles(false); setDecision(false); }}
                >Courses</p>
                <p
                    onClick={() => { setIntro(false); setCourses(false); setArticles(true); setDecision(false); }}
                >General Articles</p>
                <p
                    onClick={() => { setIntro(false); setCourses(false); setArticles(false); setDecision(true); }}
                >Decision Support Systems</p>
            </div>
            <hr/>
            <div className='general-material-content'>

                {
                    intro &&
                    <div className={styles['general-material-content-intro']}>
                        <a href='https://www.informs.org/' target="_blank" rel="noreferrer">
                            The INFORMS Resource Collection
                        </a>
                        <p>
                            This is an excellent overview site provided by INFORMS, the Institute for Operations Research and the Management Sciences, that includes a general overview of resources concerning management science such as courses, links, software providers, etc.
                        </p>

                        <a href='https://pubsonline.informs.org/magazine/orms-today' target="_blank" rel="noreferrer">
                            A brief history of OR/ MS, by Peter Horner
                        </a>
                        <p>
                            In 2002, INFORMS celebrated 50 years of problems, solutions, anecdotes and achievement. Peter Horner, the editor of OR/MS Today, describes the evolution of OR since the formulation of Operations Research Society of America, which was formed in the US in 1952.
                        </p>

                        <a href='https://www.ibm.com/products?types[0]=software' target="_blank" rel="noreferrer">
                            Solving Real-World Linear Programs: A Decade and More of Progress
                        </a>
                        <p>
                            This is a paper by Robert E. Bixby, from Rice University, referring to the evolution of Linear Programming. This paper is an invited contribution to the 50th anniversary issue of the journal Operations Research, published by the Institute of Operations Research and Management Science (INFORMS). It describes one person's perspective on the development of computational tools for linear programming. It concludes with a more detailed look at the evolution of computational linear programming since 1987.
                        </p>

                        <a href='https://www.ewp.rpi.edu/hartford/library/' target="_blank" rel="noreferrer">
                            The Cole Library of Rensselear At Hartford
                        </a>
                        <p>
                            This link includes documents on a variety of topics, including linear, integer and non-linear mathematical programming, as well as topics on Data Envelopment Analysis provided by Cole Library. The Cole Library has a specialized collection of books and journals in Computer Science, Engineering, and Business Management.
                        </p>

                        <a href='https://hbswk.hbs.edu/item/2544.html' target="_blank" rel="noreferrer">
                            Some Tips about Successful Decision Making
                        </a>
                        <p>
                            As you weigh the options for your company's next step, how do you decide which way to turn? HBS professors David A. Garvin and Michael A. Roberto offer some tips in this excerpt from Harvard Business Review. The trick, they believe, is to "periodically assess the decision-making process, even as it is under way. Scholars now have considerable evidence showing that a small set of process traits is closely linked with superior outcomes. While they are no guarantee of success, their combined presence sharply improves the odds that you'll make a good decision."
                        </p>
                    </div>
                }

                {
                    courses &&
                    <div className={styles['general-material-content-courses']}>
                        <a href='https://mat.tepper.cmu.edu/trick/' target="_blank" rel="noreferrer">
                            Management Science for Consultants
                        </a>
                        <p>
                            This is a site containing an overview of Management Science, developed by Prof. Michael Trick of the Graduate School of Industrial Administration (GSIA) of Carnegie Mellon University. This course is designed to give consultants (and others who want to improve their quantitative skills) an introduction to a variety of useful techniques in management science. Emphasis is on practicality, with many readings from the practitioner literature and small projects
                        </p>
                    </div>
                }

                {
                    articles &&
                    <div className={styles['general-material-content-articles']}>
                        <a href='https://hbswk.hbs.edu/archive/charting-you-company-s-future-your-company-strategy-in-pictures' target="_blank" rel="noreferrer">
                            Your Company's Strategy-in Pictures, by W. Chan Kim and Renee Mauborgne
                        </a>
                        <p>
                            Preparing a company strategy can bury you in data. How do you deal with both qualitative and quantitative data? Make it easier by drawing a strategy picture, a strategy canvas, advises this excerpt from Harvard Business Review. Here's how Southwest Airlines found "The Big Picture."
                        </p>

                        <a href='https://hbswk.hbs.edu/item/3074.html' target="_blank" rel="noreferrer">
                            High-Stakes Decision Making: The Lessons of Mount Everest
                        </a>
                        <p>
                            On May 10, 1996, five mountaineers from two teams perished while climbing Mount Everest. Is there anything business leaders can learn from the tragedy? HBS professor Michael A. Roberto used the tools of management to find out. The paper suggests that leaders must pay close attention to how they balance competing pressures in their organizations, and how their words and actions shape the perceptions and beliefs of organization members. In addition, the case provides insight regarding how firms approach learning from past failures.
                        </p>
                    </div>
                }

                {
                    decision &&
                    <div className={styles['general-material-content-decision']}>
                        <p>
                            Nowadays companies use extensively management information systems to solve semi-structured problems and perform complicated tasks, such as sensitivity tests and goal-seeking analysis. These systems, known as Decision Support Systems, try to bring together human judgment and objective information in an attempt to improve the decision making process.
                        </p>
                        <h5>
                            Introduction
                        </h5>
                        <a href='http://dssresources.com/history/dsshistory.html' target="_blank" rel="noreferrer">
                            DSS -- A Brief History of Decision Support Systems
                        </a>
                        <p>
                            This article written by D.J. Power, explores the developments in DSS from the 1960s until the late 1980s referring to the original theory developments as well as to their implementation.
                        </p>
                        <a href='http://dssresources.com/papers/whatisadss/' target="_blank" rel="noreferrer">
                            DSS -- What is a Decision Support System?
                        </a>
                        <p>
                            This comprehensive power point presentation, by D.J. Power describes the main points of a Decision Support System and is addressed to those who have not prior exposure to this field.
                        </p>
                        <a href='http://www.umsl.edu/~sauterv/DSS4BI/links.html' target="_blank" rel="noreferrer">
                            Decision Support Systems
                        </a>
                        <p>
                            This is useful site referring to DSS with several links developed by Vicki L. Sauter, from the University of Missouri.
                        </p>
                        <h5>
                            Articles
                        </h5>
                        <a href='https://pubsonline.informs.org/magazine/orms-today' target="_blank" rel="noreferrer">
                            Operations Research Management Science Today - June 2001 - Decision Support System
                        </a>
                        <p>
                            Improved information Technology and decision support systems help operations research practitioners.
                        </p>
                        <a href='https://www.k-praxis.com/archives/unstructured_information_management/decision_support_systems_and_unstructured_information.html' target="_blank" rel="noreferrer">
                            Unstructured information and DSS
                        </a>
                        <p>
                            This is a short article from K-Praxis referring to unstructured information, DSS, and several software vendors.
                        </p>
                        <h5>
                            Software
                        </h5>
                        <a href='https://www.tibco.com/' target="_blank" rel="noreferrer">
                            Information Builders
                        </a>
                    </div>
                }

            </div>
        </div>
    )
}

export default GeneralMaterial