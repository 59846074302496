import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import { Footer, CustomNavbar as Navbar } from '../../Components';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import styles from './VisionAndPerspective.module.css';
import { getAllLabs } from '../../utils/API/labRequests';

function VisionAndPerspective() {
    const { t } = useTranslation();
    const [labs, setLabs] = useState([{}]);
    useEffect(() => {
        const fetchData = async () => {
            const labsResponse = await getAllLabs();
            setLabs(labsResponse);
        }
        fetchData();
    }, []);

    return (
        <div className={styles['vision-container']}>
            <Navbar />
            <div className={styles['vision-container-content']}>
                <div className={styles['vision-container-header']}>
                    <h3>{t('navbar-vision')}</h3>
                </div>
                <div className={styles['vision-container-body']}>
                    <p>
                        {HTMLReactParser(t('vision-and-perspective-01'))}
                        <br /><br />
                        {t('vision-and-perspective-02')}
                        <br /><br />
                        <ul>
                            <li>
                                {t('vision-and-perspective-03')}
                            </li>
                            <li>
                                {t('vision-and-perspective-04')}
                            </li>
                            <li>
                                {t('vision-and-perspective-05')}
                            </li>
                            <li>
                                {HTMLReactParser(t('vision-and-perspective-06'))}
                            </li>
                        </ul>
                        <br /><br />
                        {t('vision-and-perspective-07')}
                        <br /><br />
                        {t('vision-and-perspective-08')}
                        <br /><br />
                        {t('vision-and-perspective-09')}
                        <br /><br />
                        {`${t('vision-and-perspective-10')} ${labs.length} ${t('vision-and-perspective-10.1')}`}
                        <br /><br />
                        <ul>
                            {
                                labs.map((lab) => {
                                    return <li>
                                        <a href={`https://aueb-msl.gr/#/${t(lab[`title_${i18n.language}`])}`} target="_blank" rel="noreferrer">{lab[`title_${i18n.language}`]}</a>
                                    </li>
                                })
                            }
                        </ul>
                        <br /><br />
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default VisionAndPerspective