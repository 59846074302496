import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomNavbar, Loading, Success } from '../../../../Components'
import LabDropdownSelector from '../LabDropdownSelector.component';
import TextInput from '../TextInput.component';
import SubmitButton from '../SubmitButton.component';
import styles from '../formStyles.module.css';
import { getAllLabs } from '../../../../utils/API/labRequests';
import { createConference } from '../../../../utils/API/conferncesRequests';
import { createActivity } from '../../../../utils/API/activitiesRequests';

function AddActivityOrConferenceForm() {
  const userInfo = localStorage.getItem("msl-unique-user-identifiers");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [labs, setLabs] = useState([{}]);
  const [blankError, setBlankError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const location = useLocation();
  const pathName = location.pathname;
  const isAddConferences = (pathName.includes('/add-conference'));
  const dataToBeCreated = isAddConferences ? 'Conference' : 'Activity';

  const [dataInfo, setDataInfo] = useState({
    title_en: "",
    title_el: "",
    lab: "",
    year: "",
    url: ""
  });

  const setHideForm = (hide) => {
    setLoading(hide);
    setShowForm(!hide);
  }

  useEffect(() => {
    const fetchData = async () => {
      const labResponse = await getAllLabs();
      setLabs(labResponse);
    }
    setHideForm(true);
    if (!userInfo) {
      setHideForm(false);
      navigate(`/`, { replace: true });
    }
    setShowForm(false);
    fetchData()
      .then(() => {
        setHideForm(false);
      });
  }, [navigate, userInfo]);

  const emptyFieldsErrorHandler = (field) => {
    setBlankError(true);
    setErrorMessage(`Please provide a ${field} for the conference you are about to add.`);
    setLoading(false);
    setShowForm(true);
  }

  const isFormIncomplete = () => {
    if (!dataInfo.title_en) {
      emptyFieldsErrorHandler("Title (EN)");
      return true;
    } else if (!dataInfo.title_el) {
      emptyFieldsErrorHandler("Title (EL)");
      return true;
    } else if (!dataInfo.year && isAddConferences) {
      emptyFieldsErrorHandler("Year");
      return true;
    } else if (!document.getElementById('add-announcement-lab') || document.getElementById('add-announcement-lab').value === 'None') {
      emptyFieldsErrorHandler("Lab");
      return true;
    }
    return false;
  }

  const createData = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      setHideForm(true);
      if (isFormIncomplete()) {
        return;
      }
      const labID = document.getElementById('add-announcement-lab').value;
      let lab_name_en = "";
      let lab_name_el = "";
      if (labID === "General") {
        lab_name_en = "MSL";
        lab_name_el = "MSL";
      } else {
        const tempLab = labs.find(lab => lab._id === labID);
        if (tempLab) {
          lab_name_en = tempLab.title_en;
          lab_name_el = tempLab.title_el;
        }
      }
      if (isAddConferences) {
        await createConference({
          title_en: dataInfo.title_en,
          title_el: dataInfo.title_el,
          lab: labID,
          lab_title_en: lab_name_en,
          lab_title_el: lab_name_el,
          year: dataInfo.year,
          url: dataInfo.url
        });
      } else {
        await createActivity({
          title_en: dataInfo.title_en,
          title_el: dataInfo.title_el,
          lab: labID,
          lab_title_en: lab_name_en,
          lab_title_el: lab_name_el,
          url: dataInfo.url
        });
      }
      setSuccess(true);
      setHideForm(false);
    } catch (error) {
      setHideForm(false);
      console.error(error);
      throw error;
    }
  }

  return (
    <div>
      <CustomNavbar />
      <div className={styles['custom-form-container']}>
        {
          success && <Success action={'created'} data={`new ${dataToBeCreated}`} />
        }
        {loading && <Loading />}
        {
          !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={createData}>
            {
              isAddConferences ? <h3>Add New Conference</h3> : <h3>Add New Activity</h3>
            }

            <TextInput
              label={"Title | EN"}
              name={"title_en"}
              dataInfo={dataInfo}
              setDataInfo={setDataInfo}
              dataToBeCreated={dataToBeCreated}
            />

            <TextInput
              label={"Title | GR"}
              name={"title_el"}
              dataInfo={dataInfo}
              setDataInfo={setDataInfo}
              dataToBeCreated={dataToBeCreated}
            />

            {
              isAddConferences && <TextInput
                label={"Year"}
                name={"year"}
                dataInfo={dataInfo}
                setDataInfo={setDataInfo}
                dataToBeCreated={dataToBeCreated}
              />
            }

            <TextInput
              label={"Link"}
              name={"url"}
              dataInfo={dataInfo}
              setDataInfo={setDataInfo}
              dataToBeCreated={dataToBeCreated}
            />

            <LabDropdownSelector
              labs={labs}
              isAdd={false}
              currentLabTitle={''}
            />

            <SubmitButton blankError={blankError} errorMessage={errorMessage} />
          </form>
        }
      </div>
    </div>
  )
}

export default AddActivityOrConferenceForm