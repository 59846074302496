import React from 'react';
import FileBase64 from 'react-file-base64';
import styles from '../formStyles.module.css';

function FileUploader({ projectInfo, setProjectInfo, setOpen }) {
    return (
        <div className={styles['custom-form-row']} id='add-staff-cv'>
            <label>File:</label>
            <FileBase64
                multiple={false}
                value={projectInfo.file}
                onDone={({ base64 }) => setProjectInfo({
                    ...projectInfo,
                    file: base64
                })}
            />
            <div>
                <button class="main-btn" onClick={() => setOpen(true)}>Preview</button>
            </div>
        </div>
    )
}

export default FileUploader