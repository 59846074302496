import React from 'react';
import MSLLogo from '../../../assets/logo.png';
import Hermis from '../../../assets/hermis_logo.jpg';
import AuebLeft from '../../../assets/auebBulding1.jpg';
import AuebRight from '../../../assets/auebBulding2.jpg';
import AuebCenter from '../../../assets/aueb_entrance.jpg';
import { useTranslation } from 'react-i18next';
import styles from './HomepageBanner.module.css';

function HomepageBanner() {
  const { t } = useTranslation();

  return (
    <div className={styles['homepage-banner-container']}>
      <div className={styles['homepage-banner-row']} id={styles['homepage-banner-row01']}>
        <img src={MSLLogo} alt="MSLLogo" />
        <h1>{t("MSL")}</h1>
        <img src={Hermis} alt="WorkTogether" />
      </div>
      <div className={styles['homepage-banner-row']} id={styles['homepage-banner-row02']}>
        <img src={AuebLeft} alt="AuebLeft" />
        <img src={AuebCenter} alt="AuebCenter" />
        <img src={AuebRight} alt="AuebCenter" />
      </div>
    </div>
  )
}

export default HomepageBanner