import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function getProjects() {
    const projectsResponse = await makeRequest(
        'get',
        `${API_URL}/projects`,
        {},
        {},
        {}
    );
    return projectsResponse.data;
}

export async function getProjectsByStatus(status) {
    const projectsResponse = await makeRequest(
        'get',
        `${API_URL}/projects?active=${status}`,
        {},
        {},
        {}
    );
    return projectsResponse.data;
}

export async function getProject(id) {
    const projectResponse = await makeRequest(
        'get',
        `${API_URL}/projects/${id}`,
        {},
        {},
        {}
    );
    return projectResponse.data;
}

export async function createProject(data) {
    await makeRequest(
        'post',
        `${API_URL}/projects/add`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function patchProject(id, data) {
    await makeRequest(
        'patch',
        `${API_URL}/projects/${id}`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function deleteProject(id) {
    await makeRequest(
        'delete',
        `${API_URL}/projects/${id}`,
        {},
        {},
        getRequestHeaders(),
    );
}