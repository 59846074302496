import React from 'react';
import { Link } from 'react-router-dom';

function AdminButtons({ url, setOpen, id }) {
    return (
        <div>
            <Link to={url} state={id}>
                <button className='main-btn'>
                    Edit
                </button>
            </Link>
            <button className='danger-btn' onClick={() => { setOpen(true) }}>
                Delete
            </button>
        </div>
    )
}

export default AdminButtons