import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function getUsers() {
    const userResponse = await makeRequest(
        'get',
        `${API_URL}/users`,
        {},
        {},
        getRequestHeaders(),
    );
    return userResponse.data;
}

export async function getUser(id) {
    const userResponse = await makeRequest(
        'get',
        `${API_URL}/users/${id}`,
        {},
        {},
        {}
    );
    return userResponse.data;
}

export async function createUser(data) {
    await makeRequest(
        'post',
        `${API_URL}/users/add`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function logInUser(data) {
    const userResponse = await makeRequest(
        'post',
        `${API_URL}/users/login`,
        data,
        {},
        {
            "Content-type": "application/json"
        }
    );
    localStorage.setItem('msl-unique-user-identifiers', JSON.stringify(userResponse.data));
}

export async function patchUser(id, data) {
    console.log(data);
    await makeRequest(
        'patch',
        `${API_URL}/users/${id}`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function deleteUser(id) {
    await makeRequest(
        'delete',
        `${API_URL}/users/${id}`,
        {},
        {},
        getRequestHeaders(),
    );
}