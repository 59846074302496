import React from 'react'
import i18next from 'i18next';
import GetStaffType from '../../../Components/GetStaffType';
import { useTranslation } from 'react-i18next';
import styles from '../LabView.module.css';
import { Link } from 'react-router-dom';

function LabStaffMember({ staff }) {
    const { t } = useTranslation();
    return (
        <div className={styles['lab-view-lab-members-single-member']} key={staff._id}>
            <div className={styles['lab-view-lab-members-single-member-card']}>
                <div className={styles['lab-view-lab-members-single-member-card-img']}>
                    <img src={`/staffMembersImages/${staff._id}.jpg`} alt={staff[`lastname_${i18next.language}`]} />
                </div>
                <div className={styles['lab-view-lab-members-single-member-card-name']}>
                    {`${staff[`lastname_${i18next.language}`]} ${staff[`firstname_${i18next.language}`]}`}
                </div>
                <div className={styles['lab-view-lab-members-single-member-card-staff-type']}>
                    {t(`${GetStaffType(staff.staff_type)}`)}
                </div>
            </div>
            <div className={styles['lab-view-lab-members-single-member-body']}>
                <div className={styles['lab-view-lab-members-single-member-body-name']}>
                    {`${staff[`lastname_${i18next.language}`]} ${staff[`firstname_${i18next.language}`]}`}
                </div>
                <div className={styles['lab-view-lab-members-single-member-body-type']}>
                    {t(`${GetStaffType(staff.staff_type)}`)}
                </div>
                <div className={styles['lab-view-lab-members-single-member-body-contact']}>
                    {`${staff.email} | ${staff.phone}`}
                </div>
                <div>
                    {
                        staff.social_links && staff.social_links.length > 0 && staff.social_links.map((social) => {
                            return <a href={social.url} target="_blank" rel="noreferrer" className={styles['lab-view-lab-members-single-member-body-social-link']} key={`${social.name}_${staff._id}`}>{social.name}</a>
                        })
                    }
                </div>
                <Link to={`../../../staff-members/${staff.lastname_en}-${staff.firstname_en}`} state={{ staffID: staff._id }}>
                    <button className={`main-btn ${styles['lab-view-lab-members-single-member-body-btn']}`}>View All Details</button>
                </Link>
                <hr />
            </div>
        </div>
    )
}

export default LabStaffMember