import React from 'react';
import Add from '../../../../assets/add.png';
import styles from '../formStyles.module.css';

function SocialMediaEditor({ socialMediaRows, AddSocialRow }) {
    return (
        <div className={styles['custom-form-row']}>
            <div className={styles['custom-form-row-social']}>
                <label>Social Media Links</label>
                <div className={styles['custom-form-row-social-img']}>
                    <img src={Add} alt="add-new-social" onClick={AddSocialRow} />
                </div>
            </div>
            <div>
                {
                    socialMediaRows.map((row) => {
                        return <div className={styles['custom-social-row']}>
                            <input
                                id={`social-name-${row}`}
                                type="text"
                                name="social-name"
                                className={styles["form-input"]}
                                placeholder="Social Media"
                            />
                            <input
                                id={`social-url-${row}`}
                                type="text"
                                name="social-url"
                                className={styles["form-input"]}
                                placeholder="Social Media URL"
                            />
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default SocialMediaEditor