import React from 'react'
import i18next from 'i18next';
import { Link } from 'react-router-dom'
import styles from '../LabPublications.module.css';
import { useTranslation } from 'react-i18next';

function LabPublicationsListNode({publication}) {
  const { t } = useTranslation();
  return (
    <Link to={`../..//publications/${publication._id}`}>
      <div className={styles['lab-view-single-publication']}>
        <h5>{publication[`title_${i18next.language}`]}</h5>
        <p>
          {t("authors")}:&nbsp;
          {
            publication[`lab_authors_names_${i18next.language}`] && publication[`lab_authors_names_${i18next.language}`].map((author) => {
              return <span key={author} className={styles["lab-view-single-publication-single-author"]}>
                {author}&nbsp; | &nbsp;
              </span>
            })
          }
        </p>
      </div>
    </Link>
  )
}

export default LabPublicationsListNode