import React from 'react'
import { useTranslation } from 'react-i18next';
import styles from '../LabView.module.css';
import LabStaffMember from '../LabStaffMember/LabStaffMember.component';

function LabViewStaffMembersList({ labStaff, headmasterID, additionalLabMember }) {
    const { t } = useTranslation();
    const staffArray = labStaff.filter(function (item) {
        return item.ID !== headmasterID;
    });
    return <div className={styles['lab-view-lab-members']}>
        <h4>{t("lab-view-lab-members")}</h4>
        {
            staffArray.length > 0 && staffArray.map((staff) => {
                return <LabStaffMember staff={staff} />
            })
        }
        {
            additionalLabMember.firstname_en && <LabStaffMember staff={additionalLabMember} />
        }
    </div>
}

export default LabViewStaffMembersList