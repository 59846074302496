import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CustomNavbar, NewsLoading } from '../../Components';
import { API_URL } from '../../utils/constants';
import { makeRequest, sendMultipleRequests } from '../../utils/API/lifRequest';
import styles from './AllAnnouncementsAndNews.module.css';

function AllAnnouncementsAndNews() {
  const userInfo = localStorage.getItem("msl-unique-user-identifiers");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const pathName = location.pathname;
  const isNews = (pathName === '/news');
  const url = isNews ? 'news' : 'announcements';
  const [data, setData] = useState([{}]);
  const [labs, setLabs] = useState([{}]);
  const [loading, setLoading] = useState(false);
  let title = `title_${i18n.language}`;

  function getLabName(labID) {
    if (labID === "General") {
      return isNews ? t("general-news") : t("general-announcements");
    }
    return labs.find(lab => lab._id === labID)[title];
  }

  async function updateData(labID) {
    setLoading(true);
    const dataRequestParam = labID === "All" ? '' : `?lab=${labID}`
    const dataResponse = await makeRequest(
      'get',
      `${API_URL}/${url}${dataRequestParam}`,
      {},
      {},
      {}
    );
    setData(dataResponse.data);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const dataRequest = {
        method: 'get',
        url: `${API_URL}/${url}`,
        data: {},
        params: {},
        headers: {}
      };
      const labsRequest = {
        method: 'get',
        url: `${API_URL}/lab`,
        data: {},
        params: {},
        headers: {}
      };
      const responses = await sendMultipleRequests([dataRequest, labsRequest]);
      const dataResponse = responses[0];
      const labsResponse = responses[1];
      setData(dataResponse.data);
      setLabs(labsResponse.data);
    }
    if (!userInfo) {
      navigate(`/`, { replace: true });
    }
    fetchData()
      .then(() => {
        setLoading(false);
      });
  }, [navigate, userInfo, url]);

  return (
    <div>
      <CustomNavbar />
      {loading && <NewsLoading />}
      {
        !loading && <div className={styles['all-news-and-announcements-content']}>
          <div className={styles['all-news-and-announcements-sidebar']}>
            <div className={styles['all-news-and-announcements-sidebar-element']} onClick={() => {
              updateData("All");
            }}>
              {
                isNews ? t("all-news") : t("all-announcements")
              }
            </div>
            <div className={styles['all-news-and-announcements-sidebar-element']} onClick={() => {
              updateData("General");
            }}>
              {
                isNews ? t("general-news") : t("general-announcements")
              }
            </div>
            {
              (labs) && (labs.length > 0) ? labs.map((lab) => {
                return <div key={lab._id} className={styles['all-news-and-announcements-sidebar-element']} onClick={() => {
                  updateData(lab._id);
                }}>
                  {lab[title]}
                </div>
              }) : <NewsLoading />
            }
          </div>

          <div className={styles['all-news-and-announcements-body']}>
            {
              data.length <= 0 && <h3>{`No ${url} have been created for this lab.`}</h3>
            }
            {
              (data.length > 0) && data.map((news_section) => {
                if (news_section.createdAt) {
                  let temp = news_section.createdAt.substring(0, 10);
                  temp = temp.split("-");
                  return <div className={styles['all-news-and-announcements-body-row']}>
                    <Link to={`../../../news/${news_section._id}`}>
                      <div className={styles['all-news-and-announcements-title']}>
                        {news_section[title]}
                      </div>
                      <div className={styles['all-news-and-announcements-date']}>
                        {`${temp[2]}-${temp[1]}-${temp[0]}`}
                      </div>
                      <div className={styles['all-news-and-announcements-lab']}>
                        {(labs.length > 0) && getLabName(news_section.lab)}
                      </div>
                    </Link>
                    <hr />
                  </div>
                }
              })
            }
          </div>
        </div>
      }
    </div>
  )
}

export default AllAnnouncementsAndNews