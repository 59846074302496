import React from 'react';
import styles from './formStyles.module.css';

function StaffCheckboxSelector({ staff }) {
  return (
    <div className={styles['custom-form-row']}>
      <label>Staff</label>
      {
        staff.map((staff_member) => {
          return <div key={staff_member._id} >
            <input type="checkbox"
              name={staff_member._id}
              value={staff_member._id}
              id={staff_member._id}
            />
            &nbsp;
            {`${staff_member.lastname_en} ${staff_member.firstname_en}`}
          </div>
        })
      }
    </div>
  )
}

export default StaffCheckboxSelector