import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { getAnnouncementsWithLimit } from '../../../utils/API/announcementsRequests';
import styles from './Announcements.module.css';

function Announcements({ setIsAnnouncementsLoading }) {
  const { t } = useTranslation();
  const [announcements, setAnnouncements] = useState([{}]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const announcementsResponse = await getAnnouncementsWithLimit(8);
      setAnnouncements(announcementsResponse);
    };
    if (setIsAnnouncementsLoading !== undefined) {
      setIsAnnouncementsLoading(true);
      fetchAnnouncements()
        .then(() => {
          setIsAnnouncementsLoading(false);
        });
    } else {
      fetchAnnouncements();
    }
  }, [setIsAnnouncementsLoading]);

  return (
    (announcements) && (announcements.length > 1) &&
    <div className={styles['announcements-container']}>
      <h3>{t('homepage-announcements')}</h3>
      {
        announcements.map((announcement) => {
          const date = announcement.createdAt.substring(0, 10).split("-");
          return <div className={styles['announcement']} key={announcement._id}>
            <Link to={`/announcements/${announcement._id}`}>
              <div className={styles['announcement-row']}>
                <div className={styles['announcement-date']}>
                  {`${date[2]}-${date[1]}-${date[0]}`}
                </div>
                <div className={styles['announcement-title']}>
                  {announcement[`title_${i18n.language}`]}
                </div>
              </div>
            </Link>
          </div>
        })
      }
      <div className={styles['announcements-container-btn']}>
        <Link to="/announcements">
          <button className='main-btn'>{t("homepage-all-announcements-btn")} </button>
        </Link>
      </div>
    </div>
  )
}

export default Announcements