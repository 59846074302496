import React from 'react'
import { Spinner } from 'react-bootstrap';
import styles from './NewsLoading.module.css';

function NewsLoading() {
    return <div className={styles['news-loading-container']}>
        <Spinner animation="border" className={styles['spinner']} />
        <br />
        <h5>Loading...</h5>
    </div>;
}

export default NewsLoading