import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNavbar } from '../../../Components';
import styles from './UserRights.module.css';
import { deleteUser, getUsers } from '../../../utils/API/userRequests';
import SignUpUser from './SignUpUser/SignUpUser.component';
import { Checkbox, Modal } from 'antd';

function UserRights() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const [users, setUsers] = useState({});
    const [userTobeDeleted, setUserTobeDeleted] = useState("");
    const [userTobeEdited, setUserTobeEdited] = useState("");
    const [addUser, setAddUser] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteUser(userTobeDeleted._id);
        await getNewUsers();
        setOpen(false);
        setConfirmLoading(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const userResponse = await getUsers();
            setUsers(userResponse);
        }
        if (!userInfo) {
            navigate(`/`, { replace: true });
        }
        fetchData();
    }, [navigate, userInfo]);

    async function getNewUsers() {
        const newUsers = await getUsers();
        setUsers(newUsers);
    }

    return (
        <div className={styles['user-rights-container']}>
            <CustomNavbar />
            <h2>Super Admin Panel</h2>
            <div className={styles['user-rights-container-control-buttons']}>
                <button className='main-btn' onClick={() => { setAddUser(true); setUserTobeEdited('') }}>Add User</button>
                <button className='main-btn' onClick={() => { setAddUser(false) }}>View All Users</button>
            </div>
            <div className={styles['user-rights-container-content']}>
                {
                    addUser && <div className={styles['user-rights-container-content-col']}>
                        <SignUpUser setUsers={setUsers} setAddUser={setAddUser} userTobeEdited={userTobeEdited} />
                    </div>
                }
                {
                    !addUser && <div className={styles['user-rights-container-content-col']}>
                        <div className={styles['user-rights-users-container']}>
                            {
                                (users) && (users.length > 0) && users.map((user) => {
                                    return <div className={styles['single-user-container']}>
                                        <h5>{user.username}</h5>
                                        <p>{user.email}</p>
                                        <Checkbox checked={user.superadmin} disabled={true}>
                                            Superadmin
                                        </Checkbox>
                                        <hr/>
                                        <button className='main-btn' onClick={() => { setUserTobeEdited(user); setAddUser(true) }}>Edit</button>
                                        <button className='danger-btn' onClick={() => { setUserTobeDeleted(user); setOpen(true) }}>Delete</button>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                }


            </div>
            <Modal
                title={'Delete User'}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>
                    {`Are you sure you want to delete user '${userTobeDeleted.username}'?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>

        </div>
    )
}

export default UserRights