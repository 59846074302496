import React from 'react';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { makeRequest } from '../../../utils/API/lifRequest';
import { API_URL, staffTypesSubMenu } from '../../../utils/constants';
import { Menu } from 'antd';

function PersonnelSidebar({ staff, setStaffToShow, headmasters, labs, setLoading }) {
    const { t } = useTranslation();

    function showAllMembers() {
        setLoading(true);
        setStaffToShow(staff);
        setLoading(false);
    }

    function showHeadmasters() {
        setLoading(true);
        setStaffToShow(headmasters);
        setLoading(false);
    }

    async function updateStaffByLabOrType(data, param) {
        setLoading(true);
        const staffResponse = await makeRequest(
            'get',
            `${API_URL}/staff?${data}=${param}`,
            {},
            {},
            {}
        );
        setStaffToShow(staffResponse.data);
        setLoading(false);
    }

    return (
        <Menu selectedKeys={'userData.username'} mode="vertical" data-cy={'custom-slider-menu'} >
            <Menu.Item key={t("all-personel")} onClick={showAllMembers}>
                <p>{t("all-personel")}</p>
            </Menu.Item>
            <Menu.Item key={t("headmasters")} onClick={showHeadmasters}>
                <p>{t("headmasters")}</p>
            </Menu.Item>
            {
                (labs) && <Menu.SubMenu key="Filter By Lab" title={'Filter By Lab'} data-cy={'custom-slider-submenu'}>
                    {
                        labs.map((lab) => {
                            return <Menu.Item key={lab[`title_${i18n.language}`]}
                                onClick={() => {
                                    updateStaffByLabOrType('lab', `${lab._id}`);
                                }}
                            >
                                {lab[`title_${i18n.language}`]}
                            </Menu.Item>
                        })
                    }
                </Menu.SubMenu>
            }
            <Menu.SubMenu key="Filter By Type" title={'Filter By Type'} data-cy={'custom-slider-submenu'}>
                {
                    staffTypesSubMenu.map((option) => {
                        return <Menu.Item key={t(option.text)}
                            onClick={() => {
                                updateStaffByLabOrType('type', option.value);
                            }}
                        >
                            {t(option.text)}
                        </Menu.Item>
                    })
                }
            </Menu.SubMenu>
        </Menu>
    )
}

export default PersonnelSidebar