import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function getStaffMembers() {
    const staffResponse = await makeRequest(
        'get',
        `${API_URL}/staff`,
        {},
        {},
        {}
    );
    return staffResponse.data;
}

export async function getStaffMember(id) {
    const staffResponse = await makeRequest(
        'get',
        `${API_URL}/staff/${id}`,
        {},
        {},
        {},
    );
    return staffResponse.data;
}

export async function getLabStaffMembers(id) {
    const staffResponse = await makeRequest(
        'get',
        `${API_URL}/staff?lab=${id}`,
        {},
        {},
        {}
    );
    return staffResponse.data;
}

export async function getStaffMembersByType(id) {
    const staffResponse = await makeRequest(
        'get',
        `${API_URL}/staff?type=${id}`,
        {},
        {},
        {}
    );
    return staffResponse.data;
}

export async function getStaffMemberByName(name) {
    const staffMemberResponse = await makeRequest(
        'get',
        `${API_URL}/staff?lastname=${name.split("-")[0]}`,
        {},
        {},
        {}
    );
    return staffMemberResponse.data;
}

export async function createStaffMember(data) {
    await makeRequest(
        'post',
        `${API_URL}/staff/add`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function patchStaffMember(id, data) {
    await makeRequest(
        'patch',
        `${API_URL}/staff/${id}`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function deleteStaffMember(id) {
    await makeRequest(
        'delete',
        `${API_URL}/staff/${id}`,
        {},
        {},
        getRequestHeaders(),
    );
}