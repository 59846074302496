import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomNavbar, Loading, Success } from '../../../../Components'
import TextInput from '../TextInput.component';
import TextEditor from '../TextEditor.component';
import LabDropdownSelector from '../LabDropdownSelector.component';
import SubmitButton from '../SubmitButton.component';
import FilesUploader from './FilesUploader.component';
import styles from '../formStyles.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllLabs, getLab, getLabByName } from '../../../../utils/API/labRequests';
import { createAnnouncement, getAnnouncement, patchAnnouncement } from '../../../../utils/API/announcementsRequests';

function AnnouncementForm() {
  const [showForm, setShowForm] = useState(true);
  const userInfo = localStorage.getItem("msl-unique-user-identifiers");
  const navigate = useNavigate();
  const location = useLocation();
  const { announcementID } = location.state ? location.state : '';
  const pathName = location.pathname;
  const isAdd = (pathName === '/add-announcement');
  const successAction = isAdd ? 'created' : 'updated';
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [announcementLab, setAnnouncementLab] = useState('');
  const [labs, setLabs] = useState([{}]);
  const [blankError, setBlankError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileExtension, setFilesExtension] = useState('pdf');

  const [announcementInfo, setAnnouncementInfo] = useState({
    title_en: "",
    title_el: "",
    description_en: "",
    description_el: "",
    lab: "",
    files: ""
  });

  const onDescriptionEnChange = (value) => {
    setAnnouncementInfo({
      ...announcementInfo,
      description_en: value
    });
  }

  const onDescriptionElChange = (value) => {
    setAnnouncementInfo({
      ...announcementInfo,
      description_el: value
    });
  }

  const setHideForm = (hide) => {
    setLoading(hide);
    setShowForm(!hide);
  }

  useEffect(() => {
    const fetchData = async () => {
      const labsResponse = await getAllLabs();
      setLabs(labsResponse);
      if (!isAdd) {
        const announcementResponse = await getAnnouncement(announcementID);
        const announcementLabResponse = announcementResponse.lab !== 'General' ? await getLab(announcementResponse.lab) : await getLabByName(announcementResponse.lab);
        setAnnouncementInfo(announcementResponse);
        setAnnouncementInfo({
          ...announcementInfo,
          title_en: announcementResponse.title_en,
          title_el: announcementResponse.title_el,
          description_en: announcementResponse.description_en,
          description_el: announcementResponse.description_el,
          lab: announcementResponse.lab,
          files: announcementResponse.files,
        });
        setStartDate(Date.parse(announcementResponse.createdAt));
        announcementResponse.length 
        ? setAnnouncementLab(announcementLabResponse[0].lab_name_en)
        : setAnnouncementLab('General');
        setFilesExtension(announcementResponse.files.substring(
          announcementResponse.files.indexOf("/") + 1,
          announcementResponse.files.lastIndexOf(";")
        ));
      }
    }
    setLoading(true);
    setHideForm(true);
    if (!userInfo) {
      setHideForm(false);
      navigate(`/`, { replace: true });
    }
    fetchData()
      .then(() => {
        setHideForm(false);
      });
  }, [navigate, userInfo, announcementID, isAdd]);

  const emptyFieldsErrorHandler = (field) => {
    const action = isAdd ? 'add' : 'edit';
    setBlankError(true);
    setErrorMessage(`Please provide a ${field} for the announcement you are about to ${action}.`);
    setLoading(false);
    setShowForm(true);
  }

  const isFormIncomplete = () => {
    if (!announcementInfo.title_en) {
      emptyFieldsErrorHandler("Title (EN)");
      return true;
    } else if (!announcementInfo.description_en) {
      emptyFieldsErrorHandler("Description (EN)");
      return true;
    } else if (!announcementInfo.title_el) {
      emptyFieldsErrorHandler("Title (EL)");
      return true;
    } else if (!announcementInfo.description_el) {
      emptyFieldsErrorHandler("Description (EL)");
      return true;
    } else if (!document.getElementById('add-announcement-lab') || document.getElementById('add-announcement-lab').value === 'None') {
      emptyFieldsErrorHandler("Lab");
      return true;
    }
    return false;
  }

  const onSubmitForm = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      setHideForm(true);
      if (isFormIncomplete()) {
        return;
      }
      const labID = document.getElementById('add-announcement-lab').value;
      if (isAdd) {
        await createAnnouncement({
          title_en: announcementInfo.title_en,
          title_el: announcementInfo.title_el,
          description_en: announcementInfo.description_en,
          description_el: announcementInfo.description_el,
          image: announcementInfo.image,
          lab: labID,
          files: announcementInfo.files,
          createdAt: startDate
        });
        setSuccess(true);
        setHideForm(false);
      } else {
        await patchAnnouncement(announcementID, {
          title_en: announcementInfo.title_en,
          title_el: announcementInfo.title_el,
          description_en: announcementInfo.description_en,
          description_el: announcementInfo.description_el,
          lab: labID,
          files: announcementInfo.files,
          createdAt: startDate
        });
        setSuccess(true);
        setHideForm(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setShowForm(true);
    }
  }

  return (
    <div>
      <CustomNavbar />
      <div className={styles['custom-form-container']}>
        {
          success && <Success action={successAction} data={'new Announcement'} />
        }
        {loading && <Loading />}
        {
          !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={onSubmitForm}>
            {
              isAdd ? <h3>Add New Announcement</h3> : <h3>Edit Announcement</h3>
            }

            <TextInput
              label={"Title | EN"}
              name={"title_en"}
              dataInfo={announcementInfo}
              setDataInfo={setAnnouncementInfo}
              dataToBeCreated={'Announcement'}
            />

            <TextInput
              label={"Title | GR"}
              name={"title_el"}
              dataInfo={announcementInfo}
              setDataInfo={setAnnouncementInfo}
              dataToBeCreated={'Announcement'}
            />

            <TextEditor
              title={'Description | EN'}
              toolbarID={'t1'}
              value={announcementInfo.description_en}
              onChange={onDescriptionEnChange}
            />

            <TextEditor
              title={'Description | EL'}
              toolbarID={'t2'}
              value={announcementInfo.description_el}
              onChange={onDescriptionElChange}
            />

            <LabDropdownSelector
              labs={labs}
              isAdd={isAdd}
              currentLabTitle={announcementLab}
            />

            <FilesUploader
              announcementInfo={announcementInfo}
              setAnnouncementInfo={setAnnouncementInfo}
              fileExtension={fileExtension}
            />

            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />


            <SubmitButton blankError={blankError} errorMessage={errorMessage} />
          </form>
        }
      </div>
    </div>
  )
}

export default AnnouncementForm