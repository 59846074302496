import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../utils/constants';
import { sendMultipleRequests } from '../../../utils/API/lifRequest';
import styles from './LabNewsAndAnnouncements.module.css';
import NewsAndAnnouncementsList from './NewsAndAnnouncementsList/NewsAndAnnouncementsList.component';
import { Loading } from '../../../Components';

function LabNewsAndAnnouncements(labID) {

    const { t } = useTranslation();
    const [news, setNews] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const { labid } = location.state;

    useEffect(() => {
        async function fetchData() {
            const labAnnouncementsRequest = {
                method: 'get',
                url: `${API_URL}/announcements?lab=${labid}`,
                data: {},
                params: {},
                headers: {},
            };
            const labNewsRequest = {
                method: 'get',
                url: `${API_URL}/news?lab=${labid}`,
                data: {},
                params: {},
                headers: {},
            };

            const responses = await sendMultipleRequests([labAnnouncementsRequest, labNewsRequest]);
            const labAnnouncementsResponse = responses[0];
            const labNewsResponse = responses[1];
            setAnnouncements(labAnnouncementsResponse.data);
            setNews(labNewsResponse.data);
        }
        setLoading(true);
        fetchData()
            .then(() => {
                setLoading(false);
            });
    }, [labid]);

    return (
        <div className={styles['lab-view-new-container']}>
            <h3>{t("news-and-announcements")}</h3>
            {
                loading && <Loading />
            }
            <div className={styles['lab-view-new-container-row']}>
                {
                    (news) && (news.length > 0) &&
                    <NewsAndAnnouncementsList
                        data={news}
                        title={t("homepage-news")}
                    />
                }
                {
                    (announcements) && (announcements.length > 0) &&
                    <NewsAndAnnouncementsList
                        data={announcements}
                        title={t("homepage-announcements")}
                    />
                }
            </div>


        </div>
    )
}

export default LabNewsAndAnnouncements