import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Footer, Loading, CustomNavbar as Navbar, Success, AdminButtons } from '../../Components';
import { Spinner } from "react-bootstrap";
import { Modal } from 'antd';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import LabNewsAndAnnouncements from './LabNewsAndAnnouncements/LabNewsAndAnnouncements.component';
import LabPublications from './LabPublications/LabPublications.component';
import { API_URL } from '../../utils/constants';
import { getRequestHeaders, sendMultipleRequests } from '../../utils/API/lifRequest';
import styles from './LabView.module.css';
import LabHeadmaster from './LabHeadmaster/LabHeadmaster.component';
import LabViewStaffMembersList from './LabViewStaffMembersList/LabViewStaffMembersList.component';
import { deleteLab, getLab, getLabByName } from '../../utils/API/labRequests';
import { getLabStaffMembers, getStaffMember } from '../../utils/API/staffRequests';


function LabView() {
    const { t } = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [staffMembersLoading, setStaffMembersLoading] = useState(false);
    const location = useLocation();
    let { labid } = "";
    let labname = useParams().labname;
    if (location.state) {
        labid = (Object.keys(location).length > 0) ? location.state : null;
    }
    const [lab, setLab] = useState({});
    const [labStaff, setLabStaff] = useState([{}]);
    const [headmaster, setHeadamster] = useState({});
    const [additionalLabMember, setAdditionalLabMember] = useState({});

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteCurrentLab();
        setOpen(false);
        setConfirmLoading(false);
    };

    async function deleteCurrentLab() {
        setLoading(true);
        const announcementsRequest = {
            method: 'get',
            url: `${API_URL}/announcements?lab=${labid.labid}`,
            data: {},
            params: {},
            headers: {}
        };
        const newsRequest = {
            method: 'get',
            url: `${API_URL}/news?lab=${labid.labid}`,
            data: {},
            params: {},
            headers: {}
        };
        const conferencesRequest = {
            method: 'get',
            url: `${API_URL}/conferences?lab=${labid.labid}`,
            data: {},
            params: {},
            headers: {}
        };
        const publicationsRequest = {
            method: 'get',
            url: `${API_URL}/publications?lab=${labid.labid}`,
            data: {},
            params: {},
            headers: {}
        };
        const projectsRequest = {
            method: 'get',
            url: `${API_URL}/projects?lab=${labid.labid}`,
            data: {},
            params: {},
            headers: {}
        };
        const responses = await sendMultipleRequests(
            [
                announcementsRequest,
                newsRequest,
                conferencesRequest,
                publicationsRequest,
                projectsRequest,
            ]
        );
        const announcementsResponse = responses[0];
        const newsResponse = responses[1];
        const conferencesResponse = responses[2];
        const publicationsResponse = responses[3];
        const projectsResponse = responses[4];
        if (announcementsResponse.data.length > 0) {
            const announcementsArray = [];
            for (const announcement in announcementsResponse.data) {
                announcementsArray.push({
                    method: 'delete',
                    url: `${API_URL}/announcements/${announcement._id}`,
                    data: {},
                    params: {},
                    headers: getRequestHeaders()
                });
                await sendMultipleRequests(announcementsArray);
            }
        }
        if (newsResponse.data.length > 0) {
            const newsArray = [];
            for (const news in newsResponse.data) {
                newsArray.push({
                    method: 'delete',
                    url: `${API_URL}/news/${news._id}`,
                    data: {},
                    params: {},
                    headers: getRequestHeaders()
                });
                await sendMultipleRequests(newsArray);
            }
        }
        if (conferencesResponse.data.length > 0) {
            const conferencesArray = [];
            for (const conference in conferencesResponse.data) {
                conferencesArray.push({
                    method: 'delete',
                    url: `${API_URL}/conferences/${conference._id}`,
                    data: {},
                    params: {},
                    headers: getRequestHeaders()
                });
                await sendMultipleRequests(conferencesArray);
            }
        }
        if (publicationsResponse.data.length > 0) {
            const publicationsArray = [];
            for (const publication in publicationsResponse.data) {
                publicationsArray.push({
                    method: 'delete',
                    url: `${API_URL}/publications/${publication._id}`,
                    data: {},
                    params: {},
                    headers: getRequestHeaders()
                });
                await sendMultipleRequests(publicationsArray);
            }
        }
        if (projectsResponse.data.length > 0) {
            const projectsArray = [];
            for (const project in projectsResponse.data) {
                projectsArray.push({
                    method: 'delete',
                    url: `${API_URL}/projects/${project._id}`,
                    data: {},
                    params: {},
                    headers: getRequestHeaders()
                });
                await sendMultipleRequests(projectsArray);
            }
        }
        if (labStaff.length > 0) {
            const staffArray = [];
            for (const staffMember in labStaff.data) {
                staffArray.push({
                    method: 'delete',
                    url: `${API_URL}/staff/${staffMember._id}`,
                    data: {},
                    params: {},
                    headers: getRequestHeaders()
                });
                await sendMultipleRequests(staffArray);
            }
        }
        await deleteLab(labid.labid);
        setSuccess(true);
        setOpen(false);
        setLoading(false);
    }

    useEffect(() => {
        async function fetchData() {
            if (location.state) {
                const labResponse = await getLab(labid.labid);
                setLab(labResponse);
                const headmasterResponse = await getStaffMember(labResponse.headmaster);
                setHeadamster(headmasterResponse);
                const staffResponse = await getLabStaffMembers(labid.labid);
                setLabStaff(staffResponse);
                if (labid.labid === '6237998dc216e87c3ab81668') {
                    const labStaffResponse = await getStaffMember('623797e0c216e87c3ab8163c');
                    setAdditionalLabMember(labStaffResponse);
                } else if (labid.labid === '6281201f8fe9428051d45d3f') {
                    const labStaffResponse = await getStaffMember('6260546961700275ffc32fa0');
                    setAdditionalLabMember(labStaffResponse);
                } else {
                    setAdditionalLabMember({});
                }
                setStaffMembersLoading(false);
            } else {
                let tempLab;
                const labResponse = await getLabByName(labname);
                for (var i = 0; i < labResponse.length; i++) {
                    if (labResponse[i].title_en === labname.replace(/-/g, " ") || labResponse[i].title_el === labname.replace(/-/g, " ")) {
                        location.state = { labid: labResponse[i]._id };
                        setLab(labResponse[i]);
                        tempLab = labResponse[i];
                    }
                }
                const headmasterResponse = await getStaffMember(tempLab.headmaster);
                setHeadamster(headmasterResponse);
                const staffResponse = await getLabStaffMembers(tempLab._id);
                setLabStaff(staffResponse);
                if (tempLab._id === '6237998dc216e87c3ab81668') {
                    const labStaffResponse = await getStaffMember('623797e0c216e87c3ab8163c');
                    setAdditionalLabMember(labStaffResponse);
                } else if (tempLab._id === '6281201f8fe9428051d45d3f') {
                    const labStaffResponse = await getStaffMember('6260546961700275ffc32fa0');
                    setAdditionalLabMember(labStaffResponse);
                } else {
                    setAdditionalLabMember({});
                }
                setStaffMembersLoading(false);
            }
        }
        if (userInfo) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
        fetchData()
            .then(() => {
                setLoading(false);
                setStaffMembersLoading(false);
            });
    }, [labid, labname, location, userInfo]);

    let title = `title_${i18n.language}`;
    let description = `description_${i18n.language}`;
    let firstname = `firstname_${i18n.language}`;

    return (
        <div className='lab-view-container'>
            {
                success && <Success action={'deleted'} data={'Lab'} />
            }
            {
                loading && <Loading />
            }
            {
                !success && <div>
                    <Navbar />
                    <div className={styles['lab-view-content-container']}>
                        {
                            !loading && <div>
                                <div className={styles['lab-view-img']}>
                                    <img src={`/labImages/${lab._id}.jpg`} alt='research-center' />
                                </div>

                                {
                                    isLoggedIn && (JSON.parse(userInfo).superadmin || JSON.parse(userInfo).labs.includes(labid)) && <AdminButtons
                                        url={"/edit-lab"}
                                        setOpen={setOpen}
                                        id={{ labid: lab._id, labname: lab[title] }}
                                    />
                                }
                                <div className={styles['lab-view-body']}>
                                    <div className={styles['lab-view-title']}>
                                        <h3>
                                            {lab[title]}
                                        </h3>
                                    </div>
                                    <div className={styles['lab-view-description']}>
                                        <p>
                                            {parse(`${lab[description]}`)}
                                        </p>
                                    </div>
                                    {
                                        (headmaster[firstname]) && <LabHeadmaster headmaster={headmaster} />
                                    }
                                    {
                                        staffMembersLoading && <div className={styles['staffMembersLoader']}>
                                            <Spinner animation="border" className='spinner' />
                                        </div>
                                    }
                                    {
                                        (!staffMembersLoading) && (labStaff) && (labStaff.length > 0) && <LabViewStaffMembersList labStaff={labStaff} headmasterID={headmaster._id} additionalLabMember={additionalLabMember} />

                                    }
                                    {(lab._id) && <LabPublications />}
                                    <hr />
                                    {(lab._id) && <LabNewsAndAnnouncements />}
                                    <hr />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            <Footer />

            <Modal
                title={`Delete Lab`}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>

                    {`Are you sure you want to delete lab "${lab[title]}"?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>
        </div>
    )
}

export default LabView