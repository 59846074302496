import HTMLReactParser from 'html-react-parser';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Footer, Loading, CustomNavbar } from '../../Components';
import styles from './ProjectView.module.css';
import { getProject } from '../../utils/API/projectsRequests';

function ProjectView() {
    const projectID = useParams().projectID;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState({});

    useEffect(() => {
        const fetchProject = async () => {
            const projectResponse = await getProject(projectID);
            setProject(projectResponse);
        };
        setLoading(true);
        fetchProject()
            .then(() => {
                setLoading(false)
            });
    }, [projectID]);

    return (
        <div>
            <CustomNavbar />
            {loading && <Loading />}
            {
                !loading &&
                <div className={styles['project-view-project']}>
                    <h3>{project[`title_${i18next.language}`]}</h3>
                    <div className={styles['project-view-project-labs']}>
                        {
                            project[`lab_names_${i18next.language}`] && project[`lab_names_${i18next.language}`].map((lab) => {
                                return <div>{lab}</div>
                            })
                        }
                    </div>
                    <h5>{`${t("supervisor")}: ${project[`supervisor_name_${i18next.language}`]}`}</h5>
                    <p className={styles['project-view-project-description']}>
                        <hr />
                        {
                            project[`description_${i18next.language}`] && HTMLReactParser(project[`description_${i18next.language}`])
                        }
                    </p>
                    <div className={styles['project-view-project-details']}>
                        {project.file && <a href={project.file} target="_blank" rel='noreferrer' download={`${project[`title_${i18next.language}`]}.txt`}>{t('read-more')}</a>}
                        {project.link && <a href={project.link} target="_blank" rel='noreferrer'>{t('link')}</a>}
                    </div>
                    <div className={styles['project-view-project-authors']}>
                        <hr />
                        <h3>{t("authors")}</h3>
                        {
                            project[`lab_authors_names_${i18next.language}`] && project[`lab_authors_names_${i18next.language}`].map((author) => {
                                return <div className={styles['project-view-project-authors-single-author']}>{author}</div>
                            })
                        }
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}

export default ProjectView