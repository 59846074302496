import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../Components';
import { API_URL } from '../../../utils/constants';
import { sendMultipleRequests } from '../../../utils/API/lifRequest';
import styles from './HomepageStats.module.css';

function HomepageStats() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [conferencesLength, setConferencesLength] = useState(0);
    const [staffLength, setStaffLength] = useState(0);
    const [publicationsLength, setPublicationsLength] = useState(0);
    const [projectsLength, setProjectsLength] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const conferencesLengthRequest = {
                method: 'get',
                url: `${API_URL}/conferences/length`,
                data: {},
                params: {},
                headers: {},
            };
            const staffLengthRequest = {
                method: 'get',
                url: `${API_URL}/staff/length`,
                data: {},
                params: {},
                headers: {},
            };
            const publicationsLengthRequest = {
                method: 'get',
                url: `${API_URL}/publications/length`,
                data: {},
                params: {},
                headers: {},
            };
            const projectsLengthRequest = {
                method: 'get',
                url: `${API_URL}/projects/length`,
                data: {},
                params: {},
                headers: {},
            };
            const responses = await sendMultipleRequests([
                conferencesLengthRequest,
                staffLengthRequest,
                publicationsLengthRequest,
                projectsLengthRequest
            ]);
            const conferencesLengthResponse = responses[0];
            const staffLengthResponse = responses[1];
            const publicationsLengthResponse = responses[2];
            const projectsLengthResponse = responses[3];
            setConferencesLength(conferencesLengthResponse.data);
            setStaffLength(staffLengthResponse.data);
            setPublicationsLength(publicationsLengthResponse.data);
            setProjectsLength(projectsLengthResponse.data);
        }
        setLoading(true);
        fetchData();
        setLoading(false);
    }, []);

    return (
        <div className={styles['homepage-stats-container']}>
            <h3>{t("research")}</h3>
            <h5>{t("research-text")}</h5>
            <hr />
            {
                loading && <Loading />
            }
            {
                !loading &&
                <div className={styles['homepage-stats-counters']}>
                    <div className={styles['homepage-stats-members-counter']}>
                        <h5>{staffLength}</h5>
                        <p>{t("members")}</p>
                    </div>
                    <div className={styles['homepage-stats-labs-counter']}>
                        <h5>{publicationsLength}</h5>
                        <p>{t("navbar-publications")}</p>
                    </div>
                    <div className={styles['homepage-stats-conferences-counter']}>
                        <h5>{conferencesLength}</h5>
                        <p>{t("navbar-conferences")}</p>
                    </div>
                    <div className={styles['homepage-stats-projects-counter']}>
                        <h5>{projectsLength}</h5>
                        <p>{t("projects")}</p>
                    </div>
                </div>
            }
            <hr />
        </div>
    )
}

export default HomepageStats