import React from 'react';
import FileBase64 from 'react-file-base64';
import styles from '../formStyles.module.css';

function ImageUploader({ newsInfo, setNewsInfo, imageTitle }) {
    return (
        <div className={styles["custom-form-row"]}>
            <label>Select Image:</label>
            <FileBase64
                multiple={false}
                value={newsInfo.image}
                onDone={({ base64 }) => setNewsInfo({
                    ...newsInfo,
                    image: base64
                })}
            />
            <div className={styles['custom-form-image-upload-container']}>
                <img src={newsInfo.image} alt={`${imageTitle}`} />
            </div>
        </div>
    )
}

export default ImageUploader