import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import { API_URL } from '../../../../utils/constants';
import { sendMultipleRequests } from '../../../../utils/API/lifRequest';
import { CustomNavbar, Loading, Success } from '../../../../Components'
import TextInput from '../TextInput.component';
import TextEditor from '../TextEditor.component';
import SubmitButton from '../SubmitButton.component';
import LabCheckboxSelector from '../LabCheckboxSelector.component';
import StaffCheckboxSelector from '../StaffCheckboxSelector.component';
import { createPublication, patchPublication } from '../../../../utils/API/publicationsRequests';
import { getStaffMember, patchStaffMember } from '../../../../utils/API/staffRequests';
import styles from '../formStyles.module.css';

function PublicationForm() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [labs, setLabs] = useState([{}]);
    const [staff, setStaff] = useState([{}]);
    const [blankError, setBlankError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showForm, setShowForm] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { publicationID } = location.state ? location.state : '';
    const pathName = location.pathname;
    const isAdd = (pathName === '/add-publication');
    const successAction = isAdd ? 'created' : 'updated';
    const toDoAction = isAdd ? 'create' : 'update';

    const [publicationInfo, setPublicationInfo] = useState({
        title_en: "",
        title_el: "",
        abstract_en: "",
        abstract_el: "",
        link: "",
        lab_authors: [],
        lab_authors_names_en: [],
        lab_authors_names_el: [],
        out_authors: [],
        labs: [],
        lab_names_en: [],
        lab_names_el: [],
        DOI: "",
        magazine: "",
        datePublished: "",
        out_authors_el: "",
        out_authors_en: "",
    });

    const onAbstractEnChange = (value) => {
        setPublicationInfo({
            ...publicationInfo,
            abstract_en: value
        });
    }

    const onAbstractElChange = (value) => {
        setPublicationInfo({
            ...publicationInfo,
            abstract_el: value
        });
    }

    const setHideForm = (hide) => {
        setLoading(hide);
        setShowForm(!hide);
    }

    const onChange = (date, dateString) => {
        setPublicationInfo({
            ...publicationInfo,
            datePublished: dateString
        });
      };

    useEffect(() => {
        const fetchData = async () => {
            const labsRequest = {
                method: 'get',
                url: `${API_URL}/lab`,
                data: {},
                params: {},
                headers: {}
            };
            const staffRequest = {
                method: 'get',
                url: `${API_URL}/staff`,
                data: {},
                params: {},
                headers: {}
            };
            const publicationRequest = {
                method: 'get',
                url: `${API_URL}/publications/${publicationID}`,
                data: {},
                params: {},
                headers: {}
            };
            if (isAdd) {
                const responses = await sendMultipleRequests([labsRequest, staffRequest]);
                const labResponse = responses[0];
                const staffResponse = responses[1];
                setLabs(labResponse.data);
                setStaff(staffResponse.data);
            } else {
                const responses = await sendMultipleRequests([labsRequest, staffRequest, publicationRequest]);
                const labsRespose = responses[0];
                const staffResponse = responses[1];
                const publicationResponse = responses[2];
                setLabs(labsRespose.data);
                setStaff(staffResponse.data);
                setPublicationInfo(publicationResponse.data);
                setPublicationInfo({
                    ...publicationInfo,
                    title_en: publicationResponse.data.title_en,
                    title_el: publicationResponse.data.title_el,
                    abstract_en: publicationResponse.data.abstract_en,
                    abstract_el: publicationResponse.data.abstract_el,
                    link: publicationResponse.data.link,
                    lab_authors: publicationResponse.data.lab_authors,
                    lab_authors_names_en: publicationResponse.data.lab_authors_names_en,
                    lab_authors_names_el: publicationResponse.data.lab_authors_names_el,
                    out_authors: publicationResponse.data.out_authors,
                    labs: publicationResponse.data.labs,
                    lab_names_en: publicationResponse.data.lab_names_en,
                    lab_names_el: publicationResponse.data.lab_names_el,
                    DOI: publicationResponse.data.DOI,
                    magazine: publicationResponse.data.magazine,
                    datePublished: publicationResponse.data.datePublished,
                    out_authors_el: publicationResponse.data.out_authors_el,
                    out_authors_en: publicationResponse.data.out_authors_en
                });
                publicationResponse.data.lab_authors.forEach(author => {
                    const authorCheckbox = document.getElementById(author);
                    if (authorCheckbox) {
                        authorCheckbox.checked = true;
                    }
                });
                publicationResponse.data.labs.forEach(lab => {
                    const labCheckbox = document.getElementById(lab);
                    if (labCheckbox) {
                        labCheckbox.checked = true;
                    }
                });
            }

        }
        setHideForm(true);
        if (!userInfo) {
            setHideForm(false);
            navigate(`/`, { replace: true });
        }
        fetchData()
            .then(() => {
                setHideForm(false);
            });
    }, [navigate, publicationID, userInfo, isAdd]);

    const emptyFieldsErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`Please provide a ${field} for the publication you are about to ${toDoAction}.`);
        setHideForm(false);
    }

    const isFormIncomplete = () => {
        if (!publicationInfo.title_en) {
            emptyFieldsErrorHandler("Title (EN)");
            return true;
        } else if (!publicationInfo.abstract_en) {
            emptyFieldsErrorHandler("Abstract (EN)");
            return true;
        } else if (!publicationInfo.title_el) {
            emptyFieldsErrorHandler("Title (EL)");
            return true;
        } else if (!publicationInfo.abstract_el) {
            emptyFieldsErrorHandler("Abstract (EL)");
            return true;
        } else if (!publicationInfo.DOI) {
            emptyFieldsErrorHandler("DOI");
            return true;
        } else if (!publicationInfo.datePublished) {
            emptyFieldsErrorHandler("Publication Date");
            return true;
        } else if (!publicationInfo.out_authors_el) {
            emptyFieldsErrorHandler("Authors (EL)");
            return true;
        } else if (!publicationInfo.out_authors_en) {
            emptyFieldsErrorHandler("Authors (EN)");
            return true;
        }
        return false;
    }

    const processPublication = async (event) => {
        try {
            event.preventDefault();
            event.persist();
            setHideForm(true);
            if (isFormIncomplete()) {
                return;
            }
            // Get all Checked Staff Members
            let staffIDs = [];
            let labAuthorsEN = [];
            let labAuthorsEL = [];
            for (const member of staff) {
                const element = document.getElementById(member._id);
                if (element.checked) {
                    staffIDs.push(member._id);
                    labAuthorsEN.push(`${member.lastname_en} ${member.firstname_en}`);
                    labAuthorsEL.push(`${member.lastname_el} ${member.firstname_el}`);
                }
            }
            // Get all Checked Labs
            let labIDs = [];
            let labNamesEN = [];
            let labNamesEL = [];
            for (const lab of labs) {
                const element = document.getElementById(lab._id);
                if (element.checked) {
                    labIDs.push(lab._id);
                    labNamesEN.push(lab.title_en);
                    labNamesEL.push(lab.title_el);
                }
            }

            if (isAdd) {
                const addPublicationResponse = createPublication({
                    title_en: publicationInfo.title_en,
                    title_el: publicationInfo.title_el,
                    abstract_en: publicationInfo.abstract_en,
                    abstract_el: publicationInfo.abstract_el,
                    link: publicationInfo.link,
                    lab_authors: staffIDs,
                    lab_authors_names_en: labAuthorsEN,
                    lab_authors_names_el: labAuthorsEL,
                    out_authors: publicationInfo.out_authors,
                    labs: labIDs,
                    lab_names_en: labNamesEN,
                    lab_names_el: labNamesEL,
                    DOI: publicationInfo.DOI,
                    magazine: publicationInfo.magazine,
                    datePublished: publicationInfo.datePublished,
                    out_authors_el: publicationInfo.out_authors_el,
                    out_authors_en: publicationInfo.out_authors_en,
                });
                staffIDs.forEach(async (staffID) => {
                    const staffResponse = await getStaffMember(staffID);
                    let publicationsArray = staffResponse.publications;
                    if (publicationsArray) {
                        publicationsArray.push(addPublicationResponse._id);
                    } else {
                        publicationsArray = [];
                        publicationsArray.push(addPublicationResponse._id);
                    }
                    await patchStaffMember(staffID, {
                        firstname_en: staffResponse.firstname_en,
                        lastname_en: staffResponse.lastname_en,
                        firstname_el: staffResponse.firstname_el,
                        lastname_el: staffResponse.lastname_el,
                        email: staffResponse.email,
                        phone: staffResponse.phone,
                        shortbio_en: staffResponse.shortbio_en,
                        shortbio_el: staffResponse.shortbio_el,
                        cv_en: staffResponse.cv_en,
                        cv_el: staffResponse.cv_el,
                        social_links: staffResponse.social_links,
                        lab: staffResponse.lab,
                        lab_name_en: staffResponse.lab_name_en,
                        lab_name_el: staffResponse.lab_name_el,
                        image: staffResponse.image,
                        staff_type: staffResponse.staff_type,
                        publications: publicationsArray
                    });
                });
            } else {
                await patchPublication(publicationID, {
                    title_en: publicationInfo.title_en,
                    title_el: publicationInfo.title_el,
                    abstract_en: publicationInfo.abstract_en,
                    abstract_el: publicationInfo.abstract_el,
                    link: publicationInfo.link,
                    lab_authors: staffIDs,
                    lab_authors_names_en: labAuthorsEN,
                    lab_authors_names_el: labAuthorsEL,
                    out_authors: publicationInfo.out_authors,
                    labs: labIDs,
                    lab_names_en: labNamesEN,
                    lab_names_el: labNamesEL,
                    DOI: publicationInfo.DOI,
                    magazine: publicationInfo.magazine,
                    datePublished: publicationInfo.datePublished,
                    out_authors_el: publicationInfo.out_authors_el,
                    out_authors_en: publicationInfo.out_authors_en,
                });
            }
            setSuccess(true);
            setHideForm(false);
        } catch (error) {
            setLoading(false);
            setShowForm(true);
            console.error(error);
            throw error;
        }
    }


    return (
        <div>
            <CustomNavbar />
            <div className={styles['custom-form-container']}>
                {
                    success && <Success action={successAction} data={'new Publication'} />
                }
                {loading && <Loading />}
                {
                    !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={processPublication}>

                        {
                            isAdd ? <h3>Add New Publication</h3> : <h3>Edit Publication</h3>
                        }
                        <TextInput
                            label={"Title | EN"}
                            name={"title_en"}
                            dataInfo={publicationInfo}
                            setDataInfo={setPublicationInfo}
                            dataToBeCreated={'Publication'}
                        />

                        <TextInput
                            label={"Title | GR"}
                            name={"title_el"}
                            dataInfo={publicationInfo}
                            setDataInfo={setPublicationInfo}
                            dataToBeCreated={'Publication'}
                        />

                        <TextInput
                            label={"Authors | EN"}
                            name={"out_authors_en"}
                            dataInfo={publicationInfo}
                            setDataInfo={setPublicationInfo}
                            dataToBeCreated={'Publication'}
                        />

                        <TextInput
                            label={"Authors | GR"}
                            name={"out_authors_el"}
                            dataInfo={publicationInfo}
                            setDataInfo={setPublicationInfo}
                            dataToBeCreated={'Publication'}
                        />

                        <TextEditor
                            title={'Abstract | EN'}
                            toolbarID={'t1'}
                            value={publicationInfo.abstract_en}
                            onChange={onAbstractEnChange}
                        />

                        <TextEditor
                            title={'Abstract | EL'}
                            toolbarID={'t2'}
                            value={publicationInfo.abstract_el}
                            onChange={onAbstractElChange}
                        />

                        <TextInput
                            label={"Link"}
                            name={"link"}
                            dataInfo={publicationInfo}
                            setDataInfo={setPublicationInfo}
                            dataToBeCreated={'Publication'}
                        />

                        <TextInput
                            label={"DOI"}
                            name={"DOI"}
                            dataInfo={publicationInfo}
                            setDataInfo={setPublicationInfo}
                            dataToBeCreated={'Publication'}
                        />

                        <TextInput
                            label={"Magazine"}
                            name={"magazine"}
                            dataInfo={publicationInfo}
                            setDataInfo={setPublicationInfo}
                            dataToBeCreated={'Publication'}
                        />

                        <label>Publication Date</label>
                        <br/>
                        <DatePicker onChange={onChange} picker="year"/>

                        <LabCheckboxSelector labs={labs} />

                        <StaffCheckboxSelector staff={staff} />

                        <SubmitButton blankError={blankError} errorMessage={errorMessage} />
                    </form>
                }
            </div>
        </div>
    )
}

export default PublicationForm