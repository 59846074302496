import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MSLLogo from '../../../assets/homepage-banner-transparent.png';
import MSLReport from '../../../assets/mslreport2000-10.pdf';
import FEK from '../../../assets/fek.pdf';
import styles from './HomepageMore.module.css';

function HomepageMore() {
    const { t } = useTranslation();
    return (
        <div className={styles['homepage-more-container']}>
            <div className={styles['homepage-more-container-col']}>
                {t("learn-more")}
                <a href={MSLReport} target="_blank" rel='noreferrer' className={styles['homepage-more-report-link']}>MSL Report</a>
                <a href={FEK} target="_blank" rel='noreferrer' className={styles['homepage-more-report-link']}>ΦΕΚ Ίδρυσης</a>
                <Link to="../../../history">
                    <button className='main-btn'>
                        {t("learn-more-btn")}
                    </button>
                </Link>

            </div>
            <div className={styles['homepage-more-container-col']}>
                <img src={MSLLogo} alt="msl-logo" />
            </div>
        </div>
    )
}

export default HomepageMore