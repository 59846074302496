import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Footer, Loading, CustomNavbar as Navbar } from '../../Components';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import HTMLReactParser from 'html-react-parser';
import { getPublication } from '../../utils/API/publicationsRequests';
import styles from './PublicationView.module.css';

function PublicationView() {
    const { t } = useTranslation();
    const publicationID = useParams().publicationID;
    const [loading, setLoading] = useState(false);
    const [publication, setPublication] = useState({});

    useEffect(() => {
        const fetchPublication = async () => {
            const publicationResponse = await getPublication(publicationID);
            setPublication(publicationResponse);
        };
        setLoading(true);
        fetchPublication()
            .then(() => {
                setLoading(false);
            });
    }, [publicationID]);
    let title = `title_${i18n.language}`;
    let abstract = `abstract_${i18n.language}`;

    return (
        <div className={styles['publication-view-container']}>
            <Navbar />
            <div className={styles['publication-view-publication']}>
                {loading && <Loading />}
                {
                    !loading &&
                    <div>
                        <h3>{publication[title]}</h3>
                        <h5>{publication.DOI}</h5>
                        <div className={styles['publication-view-publication-link']}>
                            <a href={publication.link} target="_blank" rel="noopener noreferrer">Learn More</a>
                            <hr />
                        </div>
                        <p className={styles['publication-view-publication-body']}>
                            {publication[abstract] && HTMLReactParser(publication[abstract])}
                        </p>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default PublicationView