import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../Components';
import { logInUser } from '../../utils/API/userRequests';
import TextInput from '../Admin/Forms/TextInput.component';
import SubmitButton from '../Admin/Forms/SubmitButton.component';
import styles from './Login.module.css';

function Login() {
    const [blankError, setBlankError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [userInfo, setUserInfo] = useState({
        email: "",
        password: "",
    });
    const navigate = useNavigate();

    useEffect(() => {
        const userInfo = localStorage.getItem("msl-unique-user-identifiers");
        if (userInfo) {
            navigate(`/`, { replace: true });
        }
    });

    const emptyFieldsErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`Please provide ${field} in order to log in.`);
    }

    const isFormIncomplete = () => {
        if (!userInfo.email) {
            emptyFieldsErrorHandler("an Email");
            return true;
        } else if (!userInfo.password) {
            emptyFieldsErrorHandler("a Password");
            return true;
        }
        return false;
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        e.persist();
        if (isFormIncomplete()) {
            return;
        } try {
            setLoading(true);
            await logInUser(userInfo);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    return <div>
        <div className={styles['login-form-container']}>
            <h1>Log in</h1>
            {loading && <Loading />}
            {
                !loading &&
                <form onSubmit={submitHandler}>
                    <TextInput
                        label={"Email"}
                        name={"email"}
                        dataInfo={userInfo}
                        setDataInfo={setUserInfo}
                        dataToBeCreated={'User'}
                    />
                    <TextInput
                        isPassword={true}
                        label={"Password"}
                        name={"password"}
                        dataInfo={userInfo}
                        setDataInfo={setUserInfo}
                        dataToBeCreated={'User'}
                    />
                    <SubmitButton blankError={blankError} errorMessage={errorMessage} />
                </form>
            }
        </div>
    </div>;
}

export default Login;
