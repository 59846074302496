import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function getPublications() {
    const publicationsResponse = await makeRequest(
        'get',
        `${API_URL}/publications`,
        {},
        {},
        {}
    );
    return publicationsResponse.data;
}

export async function getPublicationsByLabID(labID) {
    const publicationsResponse = await makeRequest(
        'get',
        `${API_URL}/publications/lab/${labID}`,
        {},
        {},
        {}
    );
    return publicationsResponse.data;
}

export async function getPublicationsByLab(id) {
    const publicationsResponse = await makeRequest(
        'get',
        `${API_URL}/publications?lab=${id}`,
        {},
        {},
        {},
    );
    return publicationsResponse.data;
}

export async function getPublicationsByAuthor(author) {
    const staffMemberPublicationResponse = await makeRequest(
        'get',
        `${API_URL}/publications?author=${author}`,
        {},
        {},
        {}
    );
    return staffMemberPublicationResponse.data;
}

export async function getPublication(id) {
    const publicationResponse = await makeRequest(
        'get',
        `${API_URL}/publications/${id}`,
        {},
        {},
        {},
    );
    return publicationResponse.data;
}

export async function createPublication(data) {
    const addPublicationResponse = await makeRequest(
        'post',
        `${API_URL}/publications/add`,
        data,
        {},
        getRequestHeaders(),
    );
    return addPublicationResponse.data;
}

export async function patchPublication(id, data) {
    await makeRequest(
        'patch',
        `${API_URL}/publications/${id}`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function deletePublication(id) {
    await makeRequest(
        'delete',
        `${API_URL}/publications/${id}`,
        {},
        {},
        getRequestHeaders(),
    );
}