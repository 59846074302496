import React, { useState } from 'react';
import { Footer, CustomNavbar as Navbar } from '../../Components';
import AEUB_Entrance from '../../assets/aueb_entrance.jpg';
import { useTranslation } from 'react-i18next';
import {
    Applications,
    Books,
    GeneralMaterial,
    Letter,
    ManagementProblems,
    ModelsAndTechniques,
    Software,
    UsefulLinks
} from './';
import styles from './StudentSupport.module.css';

function StudentSupport() {
    const { t } = useTranslation();
    const [letter, setLetter] = useState(true);
    const [general, setGeneral] = useState(false);
    const [models, setModels] = useState(false);
    const [management, setManagement] = useState(false);
    const [usefulLinks, setUsefulLinks] = useState(false);
    const [books, setBooks] = useState(false);
    const [applications, setApplications] = useState(false);
    const [software, setSoftware] = useState(false);

    const showPanel = (panel) => {
        setLetter(panel === 'letter'); 
        setGeneral(panel === 'general'); 
        setModels(panel === 'models'); 
        setManagement(panel === 'management'); 
        setUsefulLinks(panel === 'usefulLinks'); 
        setBooks(panel === 'books'); 
        setSoftware(panel === 'software'); 
        setApplications(panel === 'applications'); 
    }

    return (
        <div>
            <Navbar />
            <div className={styles['student-support-container']}>
                <div className={styles['student-support-header']}>
                    <img src={AEUB_Entrance} alt="AUEB-entrance" />
                    <h3>{t('navbar-students-support')}</h3>
                </div>
                <div className={styles['student-support-content']}>
                    <div className={styles['student-support-sidebar']}>
                        <ul>
                            <li
                                onClick={() => { 
                                    showPanel('general');
                                }}
                            >{t('student-support-sidebar-menu-00')}</li>
                            <li
                                onClick={() => { 
                                    showPanel('models');
                                }}
                            >{t('student-support-sidebar-menu-01')}</li>
                            <li
                                onClick={() => { 
                                    showPanel('management');
                                }}
                            >{t('student-support-sidebar-menu-02')}</li>
                            <li
                                onClick={() => { 
                                    showPanel('usefulLinks'); 
                                }}
                            >{t('student-support-sidebar-menu-03')}</li>
                            <li
                                onClick={() => { 
                                    showPanel('books');
                                }}
                            >{t('student-support-sidebar-menu-04')}</li>
                            <li
                                onClick={() => { 
                                    showPanel('software'); 
                                }}
                            >{t('student-support-sidebar-menu-05')}</li>
                            <li
                                onClick={() => { 
                                    showPanel('applications');
                                }}
                            >{t('student-support-sidebar-menu-06')}</li>
                        </ul>
                    </div>
                    <div className={styles['student-support-body']}>
                        {letter && <Letter />}
                        {general && <GeneralMaterial />}
                        {models && <ModelsAndTechniques />}
                        {management && <ManagementProblems />}
                        {usefulLinks && <UsefulLinks />}
                        {books && <Books />}
                        {software && <Software />}
                        {applications && <Applications />}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default StudentSupport