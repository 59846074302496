import React from 'react';
import { useTranslation } from 'react-i18next';
import Book01 from '../../../assets/book01.jpg'
import Book02 from '../../../assets/book02.png'
import Book03 from '../../../assets/book03.jpg'
import Book04 from '../../../assets/book04.jpg'
import Book05 from '../../../assets/book05.jpg'
import Book06 from '../../../assets/book06.jpg'
import Book07 from '../../../assets/book07.jpg'
import Book08 from '../../../assets/book08.jpg'
import styles from './Books.module.css';

function Books() {
    const { t } = useTranslation();
    return (
        <div className={styles['student-support-books-container']}>
            <h3>
                {t('student-support-sidebar-menu-04')}
            </h3>

            <div className={styles['student-support-books-content-row']}>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book01} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/files/Contents_of_book.pdf' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/files/Preface_of_book.pdf' target="_blank" rel="noreferrer">
                                    Preface
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book02} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/files/Contents.pdf' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/files/Preface.pdf' target="_blank" rel="noreferrer">
                                    Preface
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={styles['student-support-books-content-row']}>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book03} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/files/Contents_bk2.pdf' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/files/preface_bk2.pdf' target="_blank" rel="noreferrer">
                                    Preface
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/files/All%20reviews%20bk2.pdf' target="_blank" rel="noreferrer">
                                    Reviews
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book04} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/files/contents_bk1.pdf' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/files/preface_bk1.pdf' target="_blank" rel="noreferrer">
                                    Preface
                                </a>
                            </li>
                            <li>
                                <a href='https://msl.aueb.gr/files/reviews_bk1.pdf' target="_blank" rel="noreferrer">
                                    Reviews
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={styles['student-support-books-content-row']}>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book05} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/files/s.pdf' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book06} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/files/p.pdf' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={styles['student-support-books-content-row']}>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book07} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://msl.aueb.gr/files/t.pdf' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles['student-support-books-content-row-card']}>
                    <div className={styles['student-support-books-content-row-card-img']}>
                        <img src={Book08} alt="book-cover" />
                    </div>
                    <div className={styles['student-support-books-content-row-card-body']}>
                        <ul>
                            <li>
                                <a href='https://aueb-msl.gr' target="_blank" rel="noreferrer">
                                    Contents
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Books