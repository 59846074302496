import React from 'react';
import ReactQuill from "react-quill";
import { modules, formats } from "../../../Components/QuillEditorToolBars/EditorToolbar";
import BasicEditorToolbar from "../../../Components/QuillEditorToolBars/BasicEditorToolbar";
import "react-quill/dist/quill.snow.css";
import styles from './formStyles.module.css';

function TextEditor({ title, toolbarID, value, onChange }) {
    return (
        <div className={styles['custom-form-row']}>
            <label>{title}</label>
            <div>
                <BasicEditorToolbar
                    toolbarId={toolbarID}
                />
                <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    placeholder={"Write something awesome..."}
                    modules={modules(toolbarID)}
                    formats={formats}
                />
            </div>
        </div>
    )
}

export default TextEditor