import React, { useEffect, useState } from 'react';
import { CustomNavbar as Navbar, Loading } from '../../Components';
import { API_URL } from '../../utils/constants';
import { sendMultipleRequests } from '../../utils/API/lifRequest';
import styles from './Personnel.module.css';
import PersonnelSidebar from './PersonnelSidebar/PersonnelSidebar.component';
import PersonnelPanel from './PersonnelPanel/PersonnelPanel.component';

function Personnel() {
    const [loading, setLoading] = useState(false);
    const [staff, setStaff] = useState([{}]);
    const [staffToShow, setStaffToShow] = useState([{}]);
    const [labs, setLabs] = useState([{}]);
    const [headmasters, setHeadmasters] = useState([{}]);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            const staffRequest = {
                method: 'get',
                url: `${API_URL}/staff`,
                data: {},
                params: {},
                headers: {},
            };
            const labRequest = {
                method: 'get',
                url: `${API_URL}/lab`,
                data: {},
                params: {},
                headers: {},
            };
            const responses = await sendMultipleRequests([staffRequest, labRequest]);
            const staffResponse = responses[0];
            const labResponse = responses[1];
            const staff = staffResponse.data.sort((a, b) => a.lastname_en.localeCompare(b.lastname_en));
            const labs = labResponse.data;
            const headmasters = staff.filter((staffMember) => labs.some((lab) => lab.headmaster === staffMember._id))
                .sort((a, b) => a.lastname_en.localeCompare(b.lastname_en));

            setStaff(staff);
            setStaffToShow(staff);
            setLabs(labs);
            setHeadmasters(headmasters);
        }
        fetchData()
            .then(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div>
            <Navbar />
            <div className={styles['personnel-container']}>
                <PersonnelSidebar
                    staff={staff}
                    setStaffToShow={setStaffToShow}
                    headmasters={headmasters}
                    labs={labs}
                    setLoading={setLoading}
                />
                {
                    (loading) && <Loading />
                }
                {
                    (!loading) && <div className={styles['personnel-content']}>
                        <PersonnelPanel staff={staffToShow} />
                    </div>
                }
            </div>
        </div>
    )
}

export default Personnel