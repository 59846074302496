import React from 'react';
import i18n from "i18next";
import styles from './formStyles.module.css';

function StaffDropdownSelector({ title, staffMember, staff }) {
    return (
        <div className={styles['custom-form-row']}>
            <label>{title}</label>
            {
                staffMember && <span>
                    Current {title}:
                    {`${staffMember[`lastname_${i18n.language}`]} ${staffMember[`firstname_${i18n.language}`]}`}
                </span>
            }
            <select name="headmaster" id='add-lab-headmaster-container'>
                <option value="None" selected>
                    Select a {title}
                </option>
                {
                    staff.map((staffMember) => {
                        return <option key={staffMember.id} value={staffMember._id}>
                            {`${staffMember.firstname_en} ${staffMember.lastname_en}`}
                        </option>
                    })
                }
            </select>
        </div>
    )
}

export default StaffDropdownSelector