import axios from 'axios';

export async function makeRequest(method, url, data, params, headers) {
    try {
        const response = await axios.request({ method, url, data, params, headers });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function sendMultipleRequests(requests) {
    const promises = requests.map((request) => {
        const { method, url, data, params, headers } = request;
        return makeRequest(method, url, data, params, headers);
    });

    try {
        const responses = await axios.all(promises);
        return responses;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


export function getRequestHeaders() {
    const authorizationToken = JSON.parse(localStorage.getItem("msl-unique-user-identifiers")).token;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': authorizationToken
    };
    return headers;
}