import React from 'react';
import styles from '../formStyles.module.css';

function StatusRadioGroup() {
    return (
        <div className={styles['custom-form-row']}>
            <label>Active:</label>
            <div id='add-staff-lab-container'>
                <input
                    type="radio"
                    id="active"
                    value="active"
                    name="active-project"
                />
                <label>Active</label>
                &nbsp;
                <input
                    type="radio"
                    id="inactive"
                    value="inactive"
                    name="active-project"
                />
                <label>Inactive</label>
            </div>
        </div>
    )
}

export default StatusRadioGroup