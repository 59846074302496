import React from 'react';

function Applications() {
    return (
        <div>
            <p>
                1.
                <a href='https://pubsonline.informs.org/magazine/orms-today' target="_blank" rel="noreferrer">
                    Revenue management, by Anil Lahoti
                </a>
            </p>
            <p>
                Why CEOs Should Care About Revenue Management? How to minimize the implementation pains and maximize the benefits?
            </p>
            <p>
                2.
                <a href='https://pubsonline.informs.org/magazine/orms-today' target="_blank" rel="noreferrer">
                Edelman award 2003, on The Right Track, by Peter Horner
                </a>
            </p>
            <p>
            Canadian Pacific Railway earns Edelman Award for innovative operating system that saves millions of dollars while improving customer service.
            </p>
        </div>
    )
}

export default Applications