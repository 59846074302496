import React from 'react';
import styles from '../AllStaffMembers.module.css';
import StaffMemberRow from '../StaffMemberRow/StaffMemberRow.component';
import { getStaffMembersByType } from '../../../../utils/API/staffRequests';

function StaffByType({ setLoading, staffByType, setstaffByType, staffByTypeFilled, setstaffByTypeFilled, setUserToBeDeleted, setUserNameToBeDeleted, setOpen, }) {
    const typeOptions = [
        {
            value: 'default',
            text: 'Choose a Staff Type'
        },
        {
            value: 'peer-professor',
            text: 'Ομότιμος Καθηγητής'
        },
        {
            value: 'professor',
            text: 'Καθηγητής'
        },
        {
            value: 'associate-professor',
            text: 'Αναπληρωτής Καθηγητής'
        },
        {
            value: 'assistant-professor',
            text: 'Επίκουρος Καθηγητής'
        },
        {
            value: 'visiting-professor',
            text: 'Επισκέπτης Καθηγητής'
        },
        {
            value: 'phd',
            text: 'Διδάκτωρ'
        },
        {
            value: 'postdoc-researcher',
            text: 'Μεταδιδακτορικός Ερευνητής'
        },
        {
            value: 'phd-candidate',
            text: 'Υποψήφιος Διδάκτωρ'
        }, {

            value: 'freelancer',
            text: 'Εξωτερικός Συνεργάτης'
        },
        {
            value: 'bachelor-student',
            text: 'Προπτυχιακός Φοιτητής'
        },
        {
            value: 'master-student',
            text: 'Μεταπτυχιακός Φοιτητής'
        },
        {
            value: 'graduate',
            text: 'Απόφοιτος'
        },
    ];
    async function updateStaffByType(event) {
        setLoading(true);
        const staffResponse = await getStaffMembersByType(event.target.value);
        setstaffByType(staffResponse);
        setstaffByTypeFilled(true);
        setLoading(false);
    }

    return (
        <div>
            <h2>Staff Members by Type</h2>
            <div className={styles['all-staff-members-labs-container']}>
                <select name="all-staff-members-labs" id="all-staff-members-labs" value={staffByType.staff_type} onChange={updateStaffByType}>
                    {
                        typeOptions.map((option) => {
                            return <option value={option.value}>
                                {option.text}
                            </option>
                        })
                    }
                </select>
                <div className={styles['all-staff-members-by-lab-container']}>
                    {
                        staffByTypeFilled && staffByType.map((staff_member) => {
                            return <StaffMemberRow staff_member={staff_member} setUserToBeDeleted={setUserToBeDeleted} setUserNameToBeDeleted={setUserNameToBeDeleted} setOpen={setOpen} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default StaffByType