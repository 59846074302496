import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Facebook from '../../assets/facebook.png';
import Instagram from '../../assets/instagram.png';
import Twitter from '../../assets/twitter.png';
import Youtube from '../../assets/youtube.png';
import TUV from '../../assets/TUV_hellas_iso9001.jpg';
import styles from './Footer.module.css';

function Footer() {
    const { t } = useTranslation();

    return (
        <div className={styles['footer-container']}>
            <div className={styles['footer-container-row-top']}>
                <div className={styles['footer-col']}>
                    <Link to="/history">
                        {t('footer-about-us')}
                    </Link>
                    <Link to="/contact">
                        {t('footer-contact')}
                    </Link>
                    <p>
                        msl@aueb.gr
                    </p>
                </div>
                <div className={styles['footer-col']}>
                    <h5>{t('footer-address-title')}</h5>
                    <p>{t('footer-address-lab')}</p>
                </div>
                <div className={styles['footer-col']}>
                    <h5>{t('footer-aueb-caps')}</h5>
                    <p>{t('footer-lab-title')}</p>
                    <p>
                        Τ: (+30) 210.82.03.679<br />
                        F: (+30) 210.88.28.078<br />
                        www.aueb-msl.gr
                    </p>
                </div>
                <div className={styles['footer-col']}>
                    {t('footer-find-us-on-social')}
                    <div className={styles['footer-social-row']}>
                        <a href='https://www.facebook.com/MSL-AUEB-100187349348729' target="_blank" rel="noreferrer">
                            <img src={Facebook} alt="facebook-logo" />
                        </a>
                        <a href='https://www.instagram.com/msl_aueb/' target="_blank" rel="noreferrer">
                            <img src={Instagram} alt="instagram-logo" />
                        </a>
                        <a href='https://twitter.com/MSL_AUEB' target="_blank" rel="noreferrer">
                            <img src={Twitter} alt="twitter-logo" />
                        </a>
                        <a href='https://www.youtube.com/channel/UCFnXBcvB_jbzneXv5_FgPcQ' target="_blank" rel="noreferrer">
                            <img src={Youtube} alt="youtube-logo" />
                        </a>
                    </div>
                    <img src={TUV} alt="tuv-certificate" className={styles['tuv-certificate-footer']} />
                </div>
            </div>
            <div className={styles['footer-row-copyrights']}>
                <p>
                    Copyright © Website Management Science Laboratory. All rights reserved.
                    <br />
                    <a href='https://www.logicinflow.com' target="_blank" rel="noreferrer">
                        Powered by: Logic in Flow
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Footer