import React from 'react';
import { Footer, CustomNavbar, NewsAndAnnouncements } from '../../Components';
import styles from './LatestNews.module.css';

function LatestNews() {
    return (
        <div className={styles['latest-news-container']}>
            <CustomNavbar />
            <NewsAndAnnouncements />
            <Footer />
        </div>
    )
}

export default LatestNews