import React from 'react';
import i18n from "i18next";
import FileBase64 from 'react-file-base64';
import styles from '../formStyles.module.css';

function FilesUploader({ announcementInfo, setAnnouncementInfo, fileExtension }) {
    return (
        <div className={styles["custom-form-row"]}>
            <label>Select Files:</label>
            {(announcementInfo.files) && (announcementInfo.files.length > 0) && <div>
                Files: &nbsp;
                <a
                    download={`${announcementInfo[`title_${i18n.language}`]}.${fileExtension}`}
                    href={announcementInfo.files}
                    target="_blank"
                    rel='noreferrer'
                >
                    {`${announcementInfo[`title_${i18n.language}`]}.${fileExtension}`}
                </a>
            </div>}
            <FileBase64
                type="file"
                multiple={false}
                value={announcementInfo.files}
                onDone={({ base64 }) => setAnnouncementInfo({
                    ...announcementInfo,
                    files: base64
                })} />
        </div>
    )
}

export default FilesUploader