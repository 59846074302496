import React from 'react';
import { Footer, CustomNavbar as Navbar } from '../../Components';
import ContactForm from './ContactForm/ContactForm.component';
import Location from './Location/Location.component';

function Contact() {
  return (
    <div>
      <Navbar />
      <Location />
      <ContactForm />
      <Footer />
    </div>
  )
}

export default Contact