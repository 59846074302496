import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Loading, CustomNavbar } from '../../../Components';
import { API_URL } from '../../../utils/constants';
import { getRequestHeaders, makeRequest } from '../../../utils/API/lifRequest';
import styles from './ActivitiesAndConferences.module.css';

function ActivitiesAndConferences() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const pathName = location.pathname;
    const isEditConferences = (pathName === '/edit-conferences');
    const [loading, setLoading] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState("");
    const [data, setData] = useState([{}]);
    const url = isEditConferences ? 'conferences' : 'other-activities';
    const dataName = isEditConferences ? 'conference' : 'activity';
    const dataNameC = isEditConferences ? 'Conference' : 'Activity';

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteData();
        setOpen(false);
        setConfirmLoading(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const dataResponse = await makeRequest(
                'get',
                `${API_URL}/${url}`,
                {},
                {},
                {}
            );
            setData(dataResponse.data);
        };
        setLoading(true);
        if (!userInfo) {
            setLoading(false);
            navigate(`/`, { replace: true });
        }
        fetchData()
            .then(() => {
                setLoading(false);
            });
    }, [navigate, userInfo, url]);

    async function deleteData() {
        setLoading(true);
        await makeRequest(
            'delete',
            `${API_URL}/${url}/${toBeDeleted}`,
            {},
            {},
            getRequestHeaders(),
        );
        const conferencesResponse = await makeRequest(
            'get',
            `${API_URL}/${url}`,
            {},
            {},
            {}
        );
        setData(conferencesResponse.data);
        setLoading(false);
    }

    let title = `title_${i18next.language}`;

    return (
        <div>
            <CustomNavbar />
            <div className={styles['admin-conferences-container']}>
                <h3>
                    {
                        isEditConferences ? t('navbar-conferences') : t('navbar-activities')
                    }

                </h3>
                {loading && <Loading />}
                {
                    !loading &&
                    <div className={styles['admin-conferences']}>
                        {
                            data.map((conference) => {
                                return <div className={styles['admin-single-conference-container']} key={conference._id}>
                                    <div className={styles['admin-single-conference-body']}>
                                        <h5>{conference[title]}</h5>
                                    </div>
                                    <div className={styles['admin-single-conference-btns']}>
                                        <Link to={`/edit-${url}/${conference._id}`} state={{ conferenceID: conference._id }}>
                                            <button className='main-btn'>Edit</button>
                                        </Link>

                                        <button className='danger-btn' onClick={() => { setOpen(true); setToBeDeleted(conference._id) }}>
                                            Delete
                                        </button>
                                    </div>
                                </div>

                            })
                        }
                    </div>
                }
            </div>

            <Modal
                title={`Delete ${dataNameC}`}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>
                    {`Are you sure you want to delete this ${dataName}?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>
        </div>
    )
}

export default ActivitiesAndConferences
