import React from 'react'
import i18next from 'i18next';
import { Link } from 'react-router-dom'
import styles from '../LabNewsAndAnnouncements.module.css';

function NewsAndAnnouncementsListNode({data_section, date}) {
    return (
        <Link to={`../../../news/${data_section._id}`}>
            <div className={styles['lab-view-new']}>
                <div className={styles['lab-view-new-date']}>
                    {`${date[2]}-${date[1]}-${date[0]}`}
                </div>
                <div className={styles['lab-view-new-title']}>
                    {data_section[`title_${i18next.language}`]}
                </div>
            </div>
        </Link>
    )
}

export default NewsAndAnnouncementsListNode