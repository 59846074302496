import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Loading, CustomNavbar as Navbar } from '../../../Components';
import { deletePublication, getPublications } from '../../../utils/API/publicationsRequests';
import styles from './AdminPublications.module.css';

function AdminPublications() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [publicationToBeDeleted, setPublicationToBeDeleted] = useState("");
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteCurrentPublication();
        setOpen(false);
        setConfirmLoading(false);
    };

    const [publications, setPublications] = useState([{}]);
    useEffect(() => {
        const fetchPublications = async () => {
            const publicationsResponse = await getPublications();
            setPublications(publicationsResponse);
        };
        setLoading(true);
        if (!userInfo) {
            setLoading(false);
            navigate(`/`, { replace: true });
        }
        fetchPublications()
            .then(() => {
                setLoading(false);
            });
    }, [navigate, userInfo]);

    async function getAllPublications() {
        setLoading(true);
        const publicationsResponse = await getPublications();
        setPublications(publicationsResponse);
        setLoading(false);
    }

    async function deleteCurrentPublication() {
        setLoading(true);
        await deletePublication(publicationToBeDeleted);
        getAllPublications();
        setLoading(false);
    }

    let title = `title_${i18next.language}`;
    let publication_labs = `lab_names_${i18next.language}`;
    let publication_authors = `lab_authors_names_${i18next.language}`;

    return (
        <div>
            <Navbar />
            <div className={styles['admin-publications-container']}>
                <h3>{t('navbar-publications')}</h3>
                {loading && <Loading />}
                {
                    !loading &&
                    <div className='admin-publications'>
                        {
                            publications.map((publication) => {
                                return <div className={styles['admin-single-publication-container']} key={publication._id}>
                                    <div className={styles['admin-single-publication-body']}>
                                        <h5>{publication[title]} <span className={styles['admin-single-publication-doi']}>{publication.DOI}</span></h5>
                                        <div className={styles['admin-single-publication-body-labs']}>
                                            {
                                                publication[publication_labs] && publication[publication_labs].map((publication_lab) => {
                                                    return <div key={publication_lab} className={styles["admin-single-publication-body-single-lab"]}>{publication_lab}</div>
                                                })
                                            }
                                        </div>
                                        <hr />
                                        <div className={styles['admin-single-publication-body-authors']}>
                                            {t("authors")}:
                                            {
                                                publication[publication_authors] && publication[publication_authors].map((author) => {
                                                    return <span key={author} className={styles["admin-single-publication-body-single-author"]}> {author},</span>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className={styles['admin-single-publication-btns']}>
                                        <Link to={`/edit-publications/${publication._id}`} state={{ publicationID: publication._id }}>
                                            <button className='main-btn'>Edit</button>
                                        </Link>
                                        <button className='danger-btn' onClick={() => { setOpen(true); setPublicationToBeDeleted(publication._id) }}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                }
            </div>
            <Modal
                title={'Delete Publication'}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>
                    {`Are you sure you want to delete this publication?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>
        </div>
    )
}

export default AdminPublications