import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomNavbar, Loading, Success } from '../../../../Components';
import { API_URL } from '../../../../utils/constants';
import { sendMultipleRequests } from '../../../../utils/API/lifRequest';
import TextInput from '../TextInput.component';
import TextEditor from '../TextEditor.component';
import SubmitButton from '../SubmitButton.component';
import StaffDropdownSelector from '../StaffDropdownSelector.component';
import styles from '../formStyles.module.css';
import { getStaffMembers, patchStaffMember } from '../../../../utils/API/staffRequests';
import { createLab, patchLab } from '../../../../utils/API/labRequests';

function LabForm() {

    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const location = useLocation();
    const { labid } = location.state ? location.state : '';
    const pathName = location.pathname;
    const isAdd = (pathName === '/add-lab');
    const successAction = isAdd ? 'created' : 'updated';
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [staff, setStaff] = useState([{}]);
    const [blankError, setBlankError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showForm, setShowForm] = useState(true);
    const [labHeadmaster, setLabHeadmaster] = useState();

    const [labInfo, setLabInfo] = useState({
        title_en: "",
        title_el: "",
        description_en: "",
        description_el: "",
        headmaster: null,
        lab_staff: [],
        publications: []
    });

    const onDescriptionEnChange = (value) => {
        setLabInfo({
            ...labInfo,
            description_en: value
        });
    }

    const onDescriptionElChange = (value) => {
        setLabInfo({
            ...labInfo,
            description_el: value
        });
    }

    const setHideForm = (hide) => {
        setLoading(hide);
        setShowForm(!hide);
    }

    useEffect(() => {
        const fetchStaff = async () => {
            if (isAdd) {
                const staffResponse = await getStaffMembers();
                setStaff(staffResponse);
            } else {
                const labRequest = {
                    method: 'get',
                    url: `${API_URL}/lab/${labid}`,
                    data: {},
                    params: {},
                    headers: {}
                };
                const staffRequest = {
                    method: 'get',
                    url: `${API_URL}/staff`,
                    data: {},
                    params: {},
                    headers: {}
                };
                const responses = await sendMultipleRequests([labRequest, staffRequest]);
                const labResponse = responses[0];
                const staffResponse = responses[1];
                const headmaster = staffResponse.data.find(staffMember => staffMember._id === labResponse.data.headmaster);
                setLabHeadmaster(headmaster);
                setStaff(staffResponse.data);
                setLabInfo({
                    ...labInfo,
                    title_en: labResponse.data.title_en,
                    title_el: labResponse.data.title_el,
                    description_en: labResponse.data.description_en,
                    description_el: labResponse.data.description_el,
                    headmaster: labResponse.data.headmaster,
                    lab_staff: [],
                    publications: []
                });
                setLabInfo({
                    title_en: labResponse.data.title_en,
                    title_el: labResponse.data.title_el,
                    description_en: labResponse.data.description_en,
                    description_el: labResponse.data.description_el,
                    headmaster: labResponse.data.headmaster,
                    lab_staff: [],
                    publications: []
                });
            }
        }
        setHideForm(true);
        if (!userInfo) {
            setHideForm(false);
            navigate(`/`, { replace: true });
        }
        fetchStaff()
            .then(() => {
                setHideForm(false);
            });
    }, [navigate, userInfo]);

    const emptyFieldsErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`Please provide a ${field} for the lab member you are about to add.`);
        setHideForm(false);
    }

    const isFormIncomplete = () => {
        if (!labInfo.title_en) {
            emptyFieldsErrorHandler("Title (EN)");
            return true;
        } else if (!labInfo.description_en) {
            emptyFieldsErrorHandler("Description (EN)");
            return true;
        } else if (!labInfo.title_el) {
            emptyFieldsErrorHandler("Title (EL)");
            return true;
        } else if (!labInfo.description_el) {
            emptyFieldsErrorHandler("Description (EL)");
            return true;
        } else if (!document.getElementById('add-lab-headmaster-container') || document.getElementById('add-lab-headmaster-container').value === 'None') {
            emptyFieldsErrorHandler("Headmaster");
            return true;
        }
        return false;
    }

    const onSubmitForm = async (event) => {
        try {
            event.preventDefault();
            event.persist();
            setHideForm(true);
            if (isFormIncomplete()) {
                return;
            }
            const headmasterID = document.getElementById('add-lab-headmaster-container').value;

            if (isAdd) {

                // Add headmaster's id to lab's members
                let staffArray = [...labInfo.lab_staff, headmasterID];
                const labResponse = await createLab({
                    title_en: labInfo.title_en,
                    title_el: labInfo.title_el,
                    description_en: labInfo.description_en,
                    description_el: labInfo.description_el,
                    headmaster: headmasterID,
                    lab_staff: isAdd ? staffArray : labInfo.lab_staff,
                    publications: labInfo.publications
                });
                const tempStaff = staff.find(member => member._id === headmasterID);
                await patchStaffMember(tempStaff._id, {
                    firstname_en: tempStaff.firstname_en,
                    lastname_en: tempStaff.lastname_en,
                    firstname_el: tempStaff.firstname_el,
                    lastname_el: tempStaff.lastname_el,
                    email: tempStaff.email,
                    phone: tempStaff.phone,
                    shortbio_en: tempStaff.shortbio_en,
                    shortbio_el: tempStaff.shortbio_el,
                    cv_en: tempStaff.cv_en,
                    cv_el: tempStaff.cv_el,
                    social_links: tempStaff.social_links,
                    lab: labResponse._id,
                    lab_name_en: labInfo.title_en,
                    lab_name_el: labInfo.title_el,
                    staff_type: tempStaff.staff_type,
                    publications: tempStaff.publications
                });
            } else {
                // Add headmaster's id to lab's members
                const labResponse = await patchLab(labid, {
                    title_en: labInfo.title_en,
                    title_el: labInfo.title_el,
                    description_en: labInfo.description_en,
                    description_el: labInfo.description_el,
                    headmaster: headmasterID,
                    lab_staff: labInfo.lab_staff,
                    publications: labInfo.publications
                });
                const headmaster = staff.find(staffMember => staffMember._id === headmasterID);
                await patchStaffMember(headmaster._id, {
                    firstname_en: headmaster.firstname_en,
                    lastname_en: headmaster.lastname_en,
                    firstname_el: headmaster.firstname_el,
                    lastname_el: headmaster.lastname_el,
                    email: headmaster.email,
                    phone: headmaster.phone,
                    shortbio_en: headmaster.shortbio_en,
                    shortbio_el: headmaster.shortbio_el,
                    cv_en: headmaster.cv_en,
                    cv_el: headmaster.cv_el,
                    social_links: headmaster.social_links,
                    lab: labResponse._id,
                    lab_name_en: labInfo.title_en,
                    lab_name_el: labInfo.title_el,
                    staff_type: headmaster.staff_type,
                    publications: headmaster.publications
                });
            }
            setSuccess(true);
            setHideForm(false);
        } catch (error) {
            console.error(error);
            setHideForm(false);
            throw error;
        }
    }

    return (
        <div>
            <CustomNavbar />
            <div className={styles['custom-form-container']}>
                {
                    success && <Success action={successAction} data={'new Lab'} />
                }
                {loading && <Loading />}
                {
                    !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={onSubmitForm}>
                        {
                            isAdd
                                ? <h3>Add New Lab</h3>
                                : <h3>Edit Lab</h3>
                        }

                        <TextInput
                            label={"Title | EN"}
                            name={"title_en"}
                            dataInfo={labInfo}
                            setDataInfo={setLabInfo}
                            dataToBeCreated={'Research Center'}
                        />

                        <TextInput
                            label={"Title | GR"}
                            name={"title_el"}
                            dataInfo={labInfo}
                            setDataInfo={setLabInfo}
                            dataToBeCreated={'Research Center'}
                        />

                        <TextEditor
                            title={'Description | EN'}
                            toolbarID={'t1'}
                            value={labInfo.description_en}
                            onChange={onDescriptionEnChange}
                        />

                        <TextEditor
                            title={'Description | EL'}
                            toolbarID={'t2'}
                            value={labInfo.description_el}
                            onChange={onDescriptionElChange}
                        />

                        <StaffDropdownSelector
                            title={'Headmaster'}
                            staffMember={labHeadmaster}
                            staff={staff}
                        />

                        <SubmitButton blankError={blankError} errorMessage={errorMessage} />
                    </form>
                }
            </div>
        </div>
    )
}

export default LabForm