import React from 'react';
import styles from './formStyles.module.css';

function TextInput({ isPassword, label, name, dataInfo, setDataInfo, dataToBeCreated }) {
    const onFieldChange = (e, field) => {
        setDataInfo({
            ...dataInfo,
            [field]: e.target.value
        });
    }

    return (
        <div className={styles['custom-form-row']}>
            <label>{label}</label>
            <input
                type={isPassword ? "password" : "text"}
                name={name}
                value={dataInfo[name]}
                onChange={(event) => onFieldChange(event, name)}
                className={styles["form-input"]}
                placeholder={`Enter ${dataToBeCreated} ${label}...`}
            />
        </div>
    )
}

export default TextInput