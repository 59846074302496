import React from 'react';
import styles from './formStyles.module.css';

function SubmitButton({ blankError, errorMessage }) {
    return (
        <div>
            <button type='submit' className='main-btn'>Submit</button>
            {blankError && <div className={styles["blank-error"]}>{errorMessage}</div>}
        </div>
    )
}

export default SubmitButton