import React from 'react';
import styles from '../formStyles.module.css';

function LabsRadioGroup({ labs }) {
    return (
        <div className={styles['custom-form-row']}>
            <label>Lab</label>
            <div>
                <input
                    type="radio"
                    id="no-lab"
                    value="No Lab is Registered"
                    name="lab-radio"
                />
                <label>Unregistered</label>
                {
                    labs.map((lab) => {
                        return <div key={lab.id} className={styles["custom-form-lab-container-lab-row"]}>
                            <input
                                type="radio"
                                id={lab._id}
                                value={lab._id}
                                name="lab-radio"
                            />
                            <label>{`${lab.title_en}`}</label>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default LabsRadioGroup