import React from 'react'
import { ButtonGroup } from 'react-bootstrap';
import styles from '../AllStaffMembers.module.css';

function ButtonsToolbar({ setallStaffShow, setstaffByLabShow, setstaffByTypeShow }) {
    function setShowPanel(panel) {
        setallStaffShow(panel === 'allStaff');
        setstaffByLabShow(panel === 'staffByLab');
        setstaffByTypeShow(panel === 'staffByType')
    }
    return (
        <ButtonGroup className={styles['all-staff-members-container-content-btns']}>
            <button className='main-btn' onClick={() => { setShowPanel('allStaff') }}>
                All Staff Members
            </button>
            <button className='main-btn' onClick={() => { setShowPanel('staffByLab') }}>
                Members by Lab
            </button>
            <button className='main-btn' onClick={() => { setShowPanel('staffByType') }}>
                Members by Type
            </button>
        </ButtonGroup>
    )
}

export default ButtonsToolbar