export const activitiesSubMenu = [
  {
    to: "/conferences",
    text: 'navbar-conferences'
  },
  {
    to: "/publications",
    text: 'navbar-publications'
  },
  {
    to: "/other-activities",
    text: 'other-activities'
  },
];
export const portfolioSubMenu = [
  {
    to: "/active-projects",
    text: 'navbar-active-projects'
  },
  {
    to: "/previous-projects",
    text: 'navbar-previous-projects'
  },
];
export const aboutUsSubMenu = [
  {
    to: "/welcome-message",
    text: 'navbar-welcome-message'
  },
  {
    to: "/vision-and-perspective",
    text: 'navbar-vision'
  },
  {
    to: "/history",
    text: 'navbar-history'
  },
  {
    to: "/personnel",
    text: 'navbar-personnel'
  },
  {
    to: "/achievements",
    text: 'navbar-achievements'
  },
  {
    to: "/education",
    text: 'navbar-education'
  },
  {
    to: "/student-support",
    text: 'navbar-students-support'
  },
];
export const adminAddSubMenu = [
  {
    to: "/add-staff",
    text: 'Add New Staff Member'
  },
  {
    to: "/add-lab",
    text: 'Add New Lab'
  },
  {
    to: "/add-announcement",
    text: 'Add New Announcement'
  },
  {
    to: "/add-news",
    text: 'Add New News Section'
  },
  {
    to: "/add-conference",
    text: 'Add New Conference'
  },
  {
    to: "/add-activity",
    text: 'Add New Activity'
  },
  {
    to: "/add-publication",
    text: 'Add New Publication'
  },
  {
    to: "/add-project",
    text: 'Add New Project'
  },
];
export const adminViewSubMenu = [
  {
    to: "/staff-members",
    text: 'View/Edit All Staff Members'
  },
  {
    to: "/edit-conferences",
    text: 'View/Edit All Conferences'
  },
  {
    to: "/edit-other-activities",
    text: 'View/Edit All Activities'
  },
  {
    to: "/edit-publications",
    text: 'View/Edit All Publications'
  },
  {
    to: "/projects",
    text: 'View/Edit All Projects'
  },
];