import React from 'react';
import i18n from "i18next";
import GetStaffType from '../../../Components/GetStaffType';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './PersonnelMember.module.css';

function PersonnelMember({ staff_member }) {
    const { t } = useTranslation();
    return (
        <div key={staff_member._id} className={styles["personnel-staff-member-row"]}>
            <div className={styles['personnel-staff-member-img']}>
                <img src={`/staffMembersImages/${staff_member._id}.jpg`} alt={staff_member[`lastname_${i18n.language}`]} />
            </div>
            <div className={styles['personnel-staff-member-body']}>
                <div className={styles['personnel-staff-member-body-name']}>
                    <p>{staff_member[`lastname_${i18n.language}`]} {staff_member[`firstname_${i18n.language}`]}</p>
                    <p>{staff_member[`lab_name_${i18n.language}`]}</p>
                    <span>
                        {t(GetStaffType(staff_member.staff_type))}
                    </span>
                    <span>
                        {`${staff_member.email} | ${staff_member.phone}`}
                    </span>
                    <Link to={`../../../staff-members/${staff_member.lastname_en}-${staff_member.firstname_en}`} state={{ staffID: staff_member._id }}>
                        <button className='main-btn'>View All Details</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PersonnelMember