import React from 'react';
import { Footer, CustomNavbar as Navbar } from '../../Components';
import EducationBG from '../../assets/education.jpg';
import { useTranslation } from 'react-i18next';
import styles from './Education.module.css';

function Education() {
    const { t } = useTranslation();
    return (
        <div className={styles['education-container']}>
            <Navbar />
            <div className={styles['education-container-content']}>
                <div className={styles['education-container-content-header']}>
                    <img src={EducationBG} alt="education" />
                    <div className={styles['education-container-content-body']}>
                        <h3>{t("navbar-education")}</h3>
                        <div className={styles['education-container-body']}>
                            <p>
                                {t("education-00")}
                            </p>
                            <p>
                                {t("education-01")}
                            </p>
                            <ul>
                                <li>{t("education-bullet-00")}</li>
                                <li>{t("education-bullet-01")}</li>
                                <li>{t("education-bullet-02")}</li>
                                <li>{t("education-bullet-03")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Education