import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import styles from './HomepageLabs.module.css';
import { getAllLabs } from '../../../utils/API/labRequests';

function HomepageLabs() {
    const { t } = useTranslation();
    const [labs, setLabs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchLabs = async () => {
            const labsResponse = await getAllLabs();
            setLabs(labsResponse);
        }
        setLoading(true);
        fetchLabs()
            .then(() => {
                setLoading(false);
            });
    }, []);

    let title = `title_${i18n.language}`;
    let description = `description_${i18n.language}`;

    return (
        <div className={styles['homepage-labs-container']}>
            <div className={styles['homepage-labs-container-title']}>
                <h3>{t('homepage-labs-under-msl')}</h3>
            </div>
            {
                (labs) && (labs.length > 0) && !loading &&
                <div className={styles['homepage-labs-col']}>
                    {
                        (labs) && labs.map((lab) => {
                            const labDesc = HTMLReactParser(lab[description])[0].props.children;
                            return <div className={styles['homepage-labs-single-lab']}>
                                <img src={`/labImages/${lab._id}.jpg`} alt={lab.title_en} />
                                <h5>{lab[title]}</h5>
                                <div className={styles['homepage-labs-signle-lab-body']}>
                                    <p>{labDesc}</p>
                                    <div className={styles['homepage-labs-signle-lab-body-btn']}>
                                        <Link to={(`lab/${lab[title]}`).replace(" ", "-")} state={{ labid: lab._id, labname: lab[title] }}>
                                            <button className='main-btn'>{t('read-more')}</button>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        })
                    }
                </div>
            }
        </div>
    )
}

export default HomepageLabs