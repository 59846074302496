import React from 'react'
import { Link } from 'react-router-dom';
import styles from './StaffMemberRow.module.css';

function StaffMemberRow({ staff_member, setUserToBeDeleted, setUserNameToBeDeleted, setOpen }) {
    return (
        <div className={styles['staff-member-row']} key={staff_member._id}>
            <div className={styles['staff-member-row-img-col']}>
                <img src={`/staffMembersImages/${staff_member._id}.jpg`} alt={staff_member.lastname_en} />
            </div>
            <div className={styles['staff-member-row-details-col']}>
                <p className={styles['staff-member-row-details-col-name']}>
                    {`${staff_member.lastname_en} ${staff_member.firstname_en}`}
                </p>
                <p className={styles['staff-member-row-details-col-email']}>
                    {staff_member.email}
                </p>
                <p className={styles['staff-member-row-details-col-email']}>
                    {staff_member.phone}
                </p>
                <Link to={`${staff_member.lastname_en}-${staff_member.firstname_en}`} state={{ staffID: staff_member._id }}>
                    <button className='main-btn'>View All Details</button>
                </Link>
            </div>
            <div className={styles['staff-member-row-admin-btns-col']}>
                <Link to="/edit-staff" state={{ staffID: staff_member._id }}>
                    <button className='primary-btn'>Edit Details</button>
                </Link>
                <button className='danger-btn' onClick={() => {
                    setUserToBeDeleted(staff_member._id);
                    setUserNameToBeDeleted(`${staff_member.lastname_en} ${staff_member.firstname_en}`);
                    setOpen(true);
                }}>
                    Delete
                </button>
            </div>
        </div>
    )
}

export default StaffMemberRow