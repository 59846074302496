import React from 'react';
import styles from './formStyles.module.css';

function LabDropdownSelector({ labs, isAdd, currentLabTitle }) {
    return (
        <div className={styles['custom-form-row']}>
            <label>Labs</label>
            {
                isAdd && <h5>Current lab: {currentLabTitle}</h5>
            }
            <select name="headmaster" id='add-announcement-lab'>
                <option value="None" selected>
                    Select a Lab
                </option>
                <option value="General">
                    General
                </option>
                {
                    labs.map((lab) => {
                        return <option key={lab._id} value={lab._id}>
                            {`${lab.title_en}`}
                        </option>

                    })
                }
            </select>
        </div>
    )
}

export default LabDropdownSelector