import React, { useEffect, useState } from 'react';
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomNavbar } from '../../../../Components';
import { Loading, Success } from '../../../../Components';
import { API_URL } from '../../../../utils/constants';
import { getRequestHeaders, makeRequest, sendMultipleRequests } from '../../../../utils/API/lifRequest';
import LabDropdownSelector from '../LabDropdownSelector.component';
import TextInput from '../TextInput.component';
import SubmitButton from '../SubmitButton.component';
import styles from '../formStyles.module.css';
import { getLabByName } from '../../../../utils/API/labRequests';

function EditActivityOrConference() {
  const userInfo = localStorage.getItem("msl-unique-user-identifiers");
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const isEditConferences = (pathName.includes('/edit-conferences'));
  const url = isEditConferences ? 'conferences' : 'other-activities';
  const dataID = useParams().conferenceID;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [labs, setLabs] = useState([{}]);
  const [dataLab, setDataLab] = useState({});
  const [blankError, setBlankError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const dataToBeCreated = isEditConferences ? 'Conference' : 'Activity';

  const [dataInfo, setDataInfo] = useState({
    title_en: "",
    title_el: "",
    lab: "",
    year: "",
    url: ""
  });

  const setHideForm = (hide) => {
    setLoading(hide);
    setShowForm(!hide);
  }

  useEffect(() => {
    const fetchData = async () => {
      const dataRequest = {
        method: 'get',
        url: `${API_URL}/${url}/${dataID}`,
        data: {},
        params: {},
        headers: {}
      };
      const labsRequest = {
        method: 'get',
        url: `${API_URL}/lab`,
        data: {},
        params: {},
        headers: {}
      };
      const responses = await sendMultipleRequests([dataRequest, labsRequest])
      const dataResponse = responses[0];
      const labsResponse = responses[1];
      setDataInfo({
        ...dataInfo,
        title_en: dataResponse.data.title_en,
        title_el: dataResponse.data.title_el,
        lab: dataResponse.data.lab,
        lab_title_en: dataResponse.data.lab_title_en,
        lab_title_el: dataResponse.data.lab_title_el,
        year: isEditConferences ? dataResponse.data.year : '',
        url: dataResponse.data.url
      });
      setLabs(labsResponse.data);
      const labResponse = dataResponse.data.lab !== 'General'
        ? await makeRequest(
          'get',
          `${API_URL}/lab/${dataResponse.data.lab}`,
          {},
          {},
          getRequestHeaders(),
        )
        : await getLabByName(dataResponse.data.lab);
      dataResponse.data.lab !== 'General' ? setDataLab(labResponse.data) : setDataLab({
        ...dataLab,
        title_en: "General",
        title_el: "General"
      });
    }
    setHideForm(true);
    if (!userInfo) {
      setHideForm(false);
      navigate(`/`, { replace: true });
    }
    fetchData()
      .then(() => {
        setHideForm(false);
      });
  }, [navigate, userInfo]);

  const emptyFieldsErrorHandler = (field) => {
    setBlankError(true);
    setErrorMessage(`Please provide a ${field} for the ${isEditConferences ? 'conference' : 'activity'} you are about to edit.`);
    setHideForm(false);
  }

  const isFormIncomplete = () => {
    if (!dataInfo.title_en) {
      emptyFieldsErrorHandler("Title (EN)");
      return true;
    }
    if (!dataInfo.title_el) {
      emptyFieldsErrorHandler("Title (EL)");
      return true;
    }
    if (!document.getElementById('add-announcement-lab') || document.getElementById('add-announcement-lab').value === 'None') {
      if (dataInfo.lab === '') {
        emptyFieldsErrorHandler("Lab");
        return true;
      }
    }
    if (isEditConferences && !dataInfo.year) {
      emptyFieldsErrorHandler("Year");
      return true;
    }
    return false;
  }

  const editData = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      setHideForm(true);
      if (isFormIncomplete()) {
        return;
      }
      const labID = document.getElementById('add-announcement-lab').value;
      let lab_name_en = "";
      let lab_name_el = "";
      if (labID === "General") {
        lab_name_en = "MSL";
        lab_name_el = "MSL";
      } else {
        const tempLab = labs.find(lab => lab._id === labID);
        if (tempLab) {
          lab_name_en = tempLab.title_en;
          lab_name_el = tempLab.title_el;
        }
      }
      const data = isEditConferences ? {
        title_en: dataInfo.title_en,
        title_el: dataInfo.title_el,
        lab: labID,
        lab_title_en: lab_name_en,
        lab_title_el: lab_name_el,
        year: dataInfo.year,
        url: dataInfo.url
      } : {
        title_en: dataInfo.title_en,
        title_el: dataInfo.title_el,
        lab: labID,
        lab_title_en: lab_name_en,
        lab_title_el: lab_name_el,
        url: dataInfo.url
      }
      await makeRequest(
        'patch',
        `${API_URL}/${url}/${dataID}`,
        data,
        {},
        getRequestHeaders(),
      );
      setSuccess(true);
      setHideForm(false);
    } catch (error) {
      setHideForm(false);
      console.error(error);
      throw error;
    }
  }
  return (
    <div>
      <CustomNavbar />
      <div className={styles['custom-form-container']}>
        {
          success && <Success action={'updated'} data={dataToBeCreated} />
        }
        {loading && <Loading />}
        {
          !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={editData}>
            {
              isEditConferences ? <h3>Edit Conference</h3> : <h3>Edit Activity</h3>
            }

            <TextInput
              label={"Title | EN"}
              name={"title_en"}
              dataInfo={dataInfo}
              setDataInfo={setDataInfo}
              dataToBeCreated={dataToBeCreated}
            />

            <TextInput
              label={"Title | GR"}
              name={"title_el"}
              dataInfo={dataInfo}
              setDataInfo={setDataInfo}
              dataToBeCreated={dataToBeCreated}
            />

            {
              isEditConferences && <TextInput
                label={"Year"}
                name={"year"}
                dataInfo={dataInfo}
                setDataInfo={setDataInfo}
                dataToBeCreated={dataToBeCreated}
              />
            }

            <TextInput
              label={"Link"}
              name={"url"}
              dataInfo={dataInfo}
              setDataInfo={setDataInfo}
              dataToBeCreated={dataToBeCreated}
            />

            <LabDropdownSelector
              labs={labs}
              isAdd={true}
              currentLabTitle={dataLab.title_en}
            />

            <SubmitButton blankError={blankError} errorMessage={errorMessage} />
          </form>
        }
      </div>
    </div>
  )
}

export default EditActivityOrConference