import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomNavbar as Navbar, Footer } from '../../Components'
import parse from 'html-react-parser';
import styles from './WelcomeMessage.module.css';

function WelcomeMessage() {
    const { t } = useTranslation();
    return (
        <div className={styles['welcome-message-container']}>
            <Navbar />
            <div className={styles['welcome-message-content']}>
                <div className={styles['welcome-message-body']}>
                    <h3>{t("navbar-welcome-message")}</h3>
                    <p>
                        {parse(t("welcome-message-01"))}
                    </p>
                    <p>
                        {parse(t("welcome-message-02"))}
                    </p>
                    <p>
                        {t("welcome-message-03")}
                    </p>
                    <p>
                        {t("welcome-message-04")}
                    </p>
                    <p>
                        {parse(t("welcome-message-05"))}
                    </p>
                    <p>
                        {t("welcome-message-06")}
                    </p>
                    <p>
                        {parse(t("welcome-message-07"))}
                    </p>
                    <p>
                        {parse(t("welcome-message-08"))}
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default WelcomeMessage