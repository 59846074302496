import React from 'react';
import { useTranslation } from 'react-i18next';

function Letter() {
    const { t } = useTranslation();
    return (
        <div>
            <p>
                {t('student-support-p0')}
            </p>
            <p>
                {t('student-support-p1')}
            </p>
            <p>
                {t('student-support-p2')}
            </p>
            <p>
                {t('student-support-p3')}
            </p>
            <p>
                {t('student-support-p4')}
            </p>
            <p className='student-support-body-signature'>
                {t('student-support-p5')}<br />
                {t('student-support-p6')}<br />
                {t('student-support-p7')}
            </p>
        </div>
    )
}

export default Letter