import React from 'react';
import { Footer, CustomNavbar as Navbar, NewsAndAnnouncements } from '../../Components';
import HomepageBanner from './HomepageBanner/HomepageBanner.component';
import HomepageLabs from './HomepageLabs/HomepageLabs.component';
import HomepageStats from './HomepageStats/HomepageStats.component';
import HomepageMore from './HomepageMore/HomepageMore.component';

function Homepage() {
    return (
        <div>
            <Navbar />
            <HomepageBanner />
            <HomepageLabs />
            <HomepageStats />
            <NewsAndAnnouncements />
            <HomepageMore />
            <Footer />
        </div>
    )
}

export default Homepage