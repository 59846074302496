import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Success } from '../../../../Components'
import TextInput from '../../Forms/TextInput.component';
import SubmitButton from '../../Forms/SubmitButton.component';
import { createUser, getUser, getUsers, patchUser } from '../../../../utils/API/userRequests';
import styles from '../../Forms/formStyles.module.css';
import formStyles from './SignUpUser.module.css';
import { Checkbox } from 'antd';

function SignUpUser({ setUsers, setAddUser, userTobeEdited }) {
    const [blankError, setBlankError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showForm, setShowForm] = useState(true);
    const [success, setSuccess] = useState(false);
    const isLoggedIn = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const [checked, setChecked] = useState(true);
    const [isAdd, setIsAdd] = useState(true);

    const onChange = (e) => {
        console.log('checked = ', e.target.checked);
        setChecked(e.target.checked);
        setUserInfo({
            ...userInfo,
            superadmin: e.target.checked
        })
    };
    const [userInfo, setUserInfo] = useState({
        username: "",
        email: "",
        password: "",
        superadmin: true,
    });

    useEffect(() => {
        const fetchData = async () => {
            const foundUser = await getUser(userTobeEdited._id);
            setUserInfo(foundUser);
        }
        if (!isLoggedIn) {
            navigate(`/`, { replace: true });
        }
        if (userTobeEdited !== "") {
            fetchData();
        }
    }, [navigate, isLoggedIn, userTobeEdited])

    const setHideForm = (hide) => {
        setShowForm(!hide);
    }

    const emptyFieldsErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`Please provide a ${field} for the user you are about to register.`);
        setHideForm(false);
    }

    const isFormIncomplete = () => {
        if (!userInfo.username) {
            emptyFieldsErrorHandler("Username");
            return true;
        } else if (!userInfo.email) {
            emptyFieldsErrorHandler("Email");
            return true;
        } else if (!userInfo.password) {
            emptyFieldsErrorHandler("Password");
            return true;
        }
        return false;
    }

    const getNewUsers = async () => {
        const newUsers = await getUsers();
        setUsers(newUsers);
        setAddUser(false);
    }

    const processNewUser = async (event) => {
        try {
            event.preventDefault();
            event.persist();
            setHideForm(true);
            if (isFormIncomplete()) {
                return;
            }
            userTobeEdited !== ""
                ? await patchUser(userTobeEdited._id, {
                    email: userInfo.email,
                    username: userInfo.username,
                    superadmin: userInfo.superadmin,
                    labs: userInfo.labs
                })
                : await createUser(userInfo);
            setHideForm(false);
            setSuccess(true);
            getNewUsers();
        } catch (error) {
            setHideForm(false);
            console.error(error);
            throw error;
        }
    }

    return (
        <div className={formStyles['sign-up-user-form-container']}>
            {
                success && <Success action={'created'} data={'new User'} />
            }
            {
                !success && <form
                    className={
                        showForm
                            ? `${styles["custom-form"]}`
                            : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`}
                    onSubmit={processNewUser}
                >
                    <TextInput
                        label={"Username"}
                        name={"username"}
                        dataInfo={userInfo}
                        setDataInfo={setUserInfo}
                        dataToBeCreated={'User'}
                    />
                    <TextInput
                        label={"Email"}
                        name={"email"}
                        dataInfo={userInfo}
                        setDataInfo={setUserInfo}
                        dataToBeCreated={'User'}
                    />
                    {(userTobeEdited === "") &&
                        <TextInput
                            isPassword={true}
                            label={"Password"}
                            name={"password"}
                            dataInfo={userInfo}
                            setDataInfo={setUserInfo}
                            dataToBeCreated={'User'}
                        />
                    }
                    <Checkbox checked={checked} onChange={onChange}>
                        Superadmin
                    </Checkbox>
                    <SubmitButton blankError={blankError} errorMessage={errorMessage} />
                </form>
            }
        </div>
    )
}

export default SignUpUser