import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function getNews(id) {
    const newsRespose = await makeRequest(
        'get',
        `${API_URL}/news/${id}`,
        {},
        {},
        {}
    );
    return newsRespose.data;
}

export async function getNewsWithLimit(limit) {
    const newsResponse = await makeRequest(
        'get',
        `${API_URL}/news?limit=${limit}`,
        {},
        {},
        {}
    );
    return newsResponse.data;
}

export async function createNews(data) {
    await makeRequest(
        'post',
        `${API_URL}/news/add`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function patchNews(id, data) {
    await makeRequest(
        'patch',
        `${API_URL}/news/${id}`,
        data,
        {},
        getRequestHeaders(),
    );
}

export async function deleteNewsSection(id) {
    await makeRequest(
        'delete',
        `${API_URL}/news/${id}`,
        {},
        {},
        getRequestHeaders(),
    );
}