import React from 'react'
import styles from '../LabNewsAndAnnouncements.module.css';
import NewsAndAnnouncementsListNode from '../NewsAndAnnouncementsListNode/NewsAndAnnouncementsListNode.component';

function NewsAndAnnouncementsList({ data, title }) {
    return (
        <div className={styles['lab-view-new-container-news']}>
            <h5>{title}</h5>
            {
                data.map((data_section) => {
                    let date = data_section.createdAt.substring(0, 10);
                    date = date.split("-");
                    return <NewsAndAnnouncementsListNode data_section={data_section} date={date} />
                })
            }
        </div>
    )
}

export default NewsAndAnnouncementsList