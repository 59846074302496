import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { CustomNavbar, Loading } from '../../Components';
import { API_URL } from '../../utils/constants';
import { makeRequest, sendMultipleRequests } from '../../utils/API/lifRequest';
import styles from './ActivitiesAndConferences.module.css';

function ActivitiesAndConferences() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{}]);
    const [labs, setLabs] = useState([{}]);
    const location = useLocation();
    const pathName = location.pathname;
    const isConferences = (pathName === '/conferences');
    const url = isConferences ? 'conferences' : 'other-activities';

    useEffect(() => {
        const fetchData = async () => {
            const dataRequest = {
                method: 'get',
                url: `${API_URL}/${url}`,
                data: {},
                params: {},
                headers: {}
            }
            const labsRequest = {
                method: 'get',
                url: `${API_URL}/lab`,
                data: {},
                params: {},
                headers: {}
            }
            const responses = await sendMultipleRequests([dataRequest, labsRequest]);
            const dataResponse = responses[0];
            const labsResponse = responses[1];
            setData(dataResponse.data);
            setLabs(labsResponse.data);
        }
        setLoading(true);
        fetchData()
            .then(() => {
                setLoading(false);
            })
    }, [url]);

    async function updateData(labID) {
        setLoading(true)
        if (labID === "all") {
            const dataResponse = await makeRequest(
                'get',
                `${API_URL}/${url}`,
                {},
                {},
                {}
            );
            setData(dataResponse.data);
            setLoading(false);
        } else {
            const dataResponse = await makeRequest(
                'get',
                `${API_URL}/${url}?lab=${labID}`,
                {},
                {},
                {}
            );
            setData(dataResponse.data);
            setLoading(false);
        }
    }

    return (
        <div>
            <CustomNavbar />
            {loading && <Loading />}
            {
                !loading &&
                <div className={styles['data-container']}>
                    <div className={styles['data-header']}>
                        <h3>
                            {
                                isConferences ? t("all-conferences") : t('other-activities')
                            }
                        </h3>
                    </div>
                    <div className={styles['data']}>
                        <Menu mode="vertical" data-cy={'custom-slider-menu'} >
                            <Menu.Item key={"all-data"} onClick={() => { updateData("all") }}>
                                {
                                    isConferences ? t("all-conferences") : t('all-activities')
                                }
                            </Menu.Item>
                            {
                                labs.map((lab) => {
                                    return <Menu.Item key={lab._id} onClick={() => { updateData(lab._id) }}>
                                        {lab[`title_${i18next.language}`]}
                                    </Menu.Item>
                                })
                            }
                        </Menu>
                        <div className={styles['data-view']}>
                            {
                                (!data || data.length <= 0) &&
                                <p>
                                    {isConferences ? t("no-conferences") : t('no-other-activities')}
                                </p>
                            }
                            {
                                (data || data.length > 0) &&
                                data.map((node) => {
                                    return <div className={styles['single-data-container']} key={node._id}>
                                        <h5>{node[`title_${i18next.language}`]}</h5>
                                        <p>{node[`lab_title_${i18next.language}`]}</p>
                                        {
                                            node.url !== ""
                                                ? <a href={node.url} key={node._id} target="_blank" rel="noopener noreferrer">Learn More</a>
                                                : <span />
                                        }
                                        <hr />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ActivitiesAndConferences