import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNavbar as Navbar } from '../../../Components';
import { API_URL } from '../../../utils/constants';
import { sendMultipleRequests } from '../../../utils/API/lifRequest';
import styles from './AddPublicationToStaff.module.css';
import { getPublication, patchPublication } from '../../../utils/API/publicationsRequests';

function AddPublicationToStaff() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const staffID = useParams().staffID;
    const [staff, setStaff] = useState({});
    const [publications, setPublications] = useState([{}]);
    let title = `title_${i18next.language}`;

    async function updateData() {
        const staffRequest = {
            method: 'get',
            url: `${API_URL}/staff/${staffID}`,
            data: {},
            params: {},
            headers: {}
        };
        const publicationsRequest = {
            method: 'get',
            url: `${API_URL}/publications`,
            data: {},
            params: {},
            headers: {}
        };
        const responses = await sendMultipleRequests([staffRequest, publicationsRequest]);
        const staffResponse = responses[0];
        const publicationsResponse = responses[1];
        setStaff(staffResponse.data);
        setPublications(publicationsResponse.data);
    }

    async function updatePublication(id, add) {
        const publicationResponse = await getPublication(id);

        let tempAuthorsArr;
        let tempAuthorsArrNamesEN;
        let tempAuthorsArrNamesEL;
        if (add) {
            tempAuthorsArr = publicationResponse.lab_authors || [];
            tempAuthorsArr.push(staffID);
            const tempStringEN = `${staff.lastname_en} ${staff.firstname_en}`;
            tempAuthorsArrNamesEN = publicationResponse.lab_authors_names_en ? [...publicationResponse.lab_authors_names_en, tempStringEN] : [tempStringEN];
            const tempStringEL = `${staff.lastname_el} ${staff.firstname_el}`;
            tempAuthorsArrNamesEL = publicationResponse.lab_authors_names_el ? [...publicationResponse.lab_authors_names_el, tempStringEL] : [tempStringEL];

        } else {
            tempAuthorsArr = publicationResponse.lab_authors || [];
            tempAuthorsArr = tempAuthorsArr.filter(e => e !== staffID);
            if (tempAuthorsArr.length === 1) {
                tempAuthorsArr = [];
            }
            const tempStringEN = `${staff.lastname_en} ${staff.firstname_en}`
            tempAuthorsArrNamesEN = publicationResponse.lab_authors_names_en || [];
            tempAuthorsArrNamesEN = tempAuthorsArrNamesEN.filter(e => e !== tempStringEN);
            if (tempAuthorsArrNamesEN.length === 1) {
                tempAuthorsArrNamesEN = [];
            }
            const tempStringEL = `${staff.lastname_el} ${staff.firstname_el}`
            tempAuthorsArrNamesEL = publicationResponse.lab_authors_names_el || [];
            tempAuthorsArrNamesEL = tempAuthorsArrNamesEL.filter(e => e !== tempStringEL);
            if (tempAuthorsArrNamesEL.length === 1) {
                tempAuthorsArrNamesEL = [];
            }
        }
        await patchPublication(id, {
            title_en: publicationResponse.title_en,
            title_el: publicationResponse.title_el,
            abstract_en: publicationResponse.abstract_en,
            abstract_el: publicationResponse.abstract_el,
            link: publicationResponse.link,
            lab_authors: tempAuthorsArr,
            lab_authors_names_en: tempAuthorsArrNamesEN,
            lab_authors_names_el: tempAuthorsArrNamesEL,
            out_authors: publicationResponse.out_authors,
            labs: publicationResponse.labs,
            lab_names_en: publicationResponse.lab_names_en,
            lab_names_el: publicationResponse.lab_names_el,
            DOI: publicationResponse.DOI,
            magazine: publicationResponse.magazine,
        });
    }

    async function addPublication(id) {
        let tempArr = staff.publications;
        if (tempArr) {
            tempArr.push(id);
        } else {
            tempArr = [];
            tempArr.push(id);
        }
        await patchPublication(staffID,
            {
                firstname_en: staff.firstname_en,
                lastname_en: staff.lastname_en,
                firstname_el: staff.firstname_el,
                lastname_el: staff.lastname_el,
                email: staff.email,
                phone: staff.phone,
                shortbio_en: staff.shortbio_en,
                shortbio_el: staff.shortbio_el,
                cv_en: staff.cv_en,
                cv_el: staff.cv_el,
                social_links: staff.social_links,
                lab: staff.lab,
                lab_name_en: staff.lab_name_en,
                lab_name_el: staff.lab_name_el,
                image: staff.image,
                staff_type: staff.staff_type,
                publications: tempArr
            });
        await updatePublication(id, true);
        await updateData();
    }

    async function removePublication(id) {
        let tempArr = staff.publications;
        if (tempArr) {
            tempArr = tempArr.filter(e => e !== id);
        }
        if (tempArr.length === 1) {
            tempArr.pop();
        }
        await patchPublication(staffID,
            {
                firstname_en: staff.firstname_en,
                lastname_en: staff.lastname_en,
                firstname_el: staff.firstname_el,
                lastname_el: staff.lastname_el,
                email: staff.email,
                phone: staff.phone,
                shortbio_en: staff.shortbio_en,
                shortbio_el: staff.shortbio_el,
                cv_en: staff.cv_en,
                cv_el: staff.cv_el,
                social_links: staff.social_links,
                lab: staff.lab,
                lab_name_en: staff.lab_name_en,
                lab_name_el: staff.lab_name_el,
                image: staff.image,
                staff_type: staff.staff_type,
                publications: tempArr
            });
        await updatePublication(id, false);
        await updateData();
    }

    useEffect(() => {
        const fetchData = async () => {
            const staffRequest = {
                method: 'get',
                url: `${API_URL}/staff/${staffID}`,
                data: {},
                params: {},
                headers: {}
            };
            const publicationsRequest = {
                method: 'get',
                url: `${API_URL}/publications`,
                data: {},
                params: {},
                headers: {}
            };
            const responses = await sendMultipleRequests([staffRequest, publicationsRequest]);
            const staffResponse = responses[0];
            const publicationsResponse = responses[1];
            setStaff(staffResponse.data);
            setPublications(publicationsResponse.data);
        }
        if (!userInfo) {
            navigate(`/`, { replace: true });
        }
        fetchData();
    }, [navigate, staffID, userInfo]);

    return (
        <div>
            <Navbar />
            {
                (publications) && (publications.length > 0) &&
                <div className={styles['add-publication-publications-container']}>
                    {
                        publications.map((publication) => {
                            return <div>
                                <div>
                                    <h5>{publication[title]}</h5>
                                    <span>{publication.DOI}</span>
                                </div>
                                <div>
                                    {
                                        (staff.publications) && (publication.lab_authors) && (publication.lab_authors.includes(staffID) || staff.publications.includes(publication._id)) &&
                                        <button className='danger-btn'
                                            onClick={() => { removePublication(publication._id) }}
                                        >
                                            Remove
                                        </button>
                                    }
                                    {
                                        (staff.publications) && (publication.lab_authors) && (!publication.lab_authors.includes(staffID) && !staff.publications.includes(publication._id)) &&
                                        <button className='main-btn'
                                            onClick={() => { addPublication(publication._id) }}
                                        >
                                            Add
                                        </button>
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
            }
        </div>
    )
}

export default AddPublicationToStaff