import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { Footer, CustomNavbar, Success, AdminButtons } from '../../Components';
import NewsLoading from '../../Components/NewsLoading/NewsLoading.component';
import { deleteNewsSection, getNews } from '../../utils/API/newsRequests';
import { getLab } from '../../utils/API/labRequests';
import styles from './NewsView.module.css';

function NewsView() {
    const { t } = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [news, setNews] = useState({});
    const [lab, setLab] = useState({});
    const [newsDate, setNewsDate] = useState("");
    const newsID = useParams().newsID;
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteNews();
        setOpen(false);
        setConfirmLoading(false);
    };

    async function deleteNews() {
        setLoading(true);
        await deleteNewsSection(newsID);
        setLoading(false);
        setSuccess(true);
    }

    useEffect(() => {
        const fetchData = async () => {
            const newsResponse = await getNews(newsID);
            setNews(newsResponse);
            let date = newsResponse.createdAt.substring(0, 10);
            date = date.split("-");
            setNewsDate(`${date[2]}-${date[1]}-${date[0]}`)
            if (newsResponse.lab === "General") {
                setLab("General");
                setLoading(false);
            } else {
                const labResponse = await getLab(newsResponse.lab);
                setLab(labResponse);;
            }
        }
        setLoading(true);
        // Check if user is logged in
        if (userInfo) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
        fetchData()
            .then(() => {
                setLoading(false);
            })
    }, [newsID, userInfo]);

    let title = `title_${i18n.language}`;
    let description = `description_${i18n.language}`;

    return (
        <div>
            <CustomNavbar />
            {success && <Success action={'deleted'} data={'News Section'} />}
            {loading && <NewsLoading />}
            {
                (!success) && (!loading) && <div className={styles['news-section-container']}>
                    <h4>{news[title]}</h4>
                    <div className={styles['news-section-row']}>
                        <div className={styles['news-section-img']}>
                            <img src={news.image} alt={news[title]} />
                        </div>
                        <div className={styles['news-section-body']}>
                            <p>{newsDate}</p>
                            {
                                (lab === "General") && <Link to="/news">{t("general-news")}</Link>
                            }
                            {
                                (lab !== "General") && <Link to={(`../../../${lab[title]}`).replace(" ", "-")} state={{ labid: lab._id, labname: lab[title] }} >
                                    <div className={styles["news-section-body-lab"]}>
                                        {lab[title]}
                                    </div>
                                </Link>
                            }
                            <p>{parse(`${news[description]}`)}</p>
                        </div>
                    </div>
                    {(isLoggedIn) && <AdminButtons url={'/edit-news'} setOpen={setOpen} id={{ newsID: news._id }} />}
                </div>
            }
            <Footer />
            <Modal
                title={news[title]}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>
                    {`Are you sure you want to delete news section "${news[title]}"?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>
        </div>
    )
}

export default NewsView