import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { Divider, Menu } from 'antd';
import 'antd/dist/antd.css';
import Logo from '../../assets/logo.png';
import { laboratories } from '../../utils/constants';
import { aboutUsSubMenu, activitiesSubMenu, adminAddSubMenu, adminViewSubMenu, portfolioSubMenu } from './sumbenus';
import styles from './CustomNavbar.module.css'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'el'],
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    fallbackLng: "en",
    detection: {
      // order and from where user language should be detected
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    }
  });

function CustomNavbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userInfo = localStorage.getItem("msl-unique-user-identifiers");
  const navigate = useNavigate();

  // Check if user is logged in
  useEffect(() => {
    if (userInfo) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userInfo])

  return <Menu mode="horizontal" selectedKeys={[pathname]} className={styles["custom-navbar"]}>
    <Menu.Item key="logo" className={styles['custom-navbar-logo-container']}>
      <img src={Logo} alt="msl-logo" />
    </Menu.Item>
    <Menu.Item key="/">
      <Link to="/">
        {t('navbar-home')}
      </Link>
    </Menu.Item>
    <Menu.SubMenu key="aboutTheLab" title={t('navbar-about-the-lab')}>
      <Menu.Item key="/latest-news-and-updates">
        <Link to="/latest-news-and-updates">
          {t('navbar-news-and-updates')}
        </Link>
      </Menu.Item>
      <Menu.SubMenu key="homepage-labs-under-msl" title={t('homepage-labs-under-msl')}>
        {
          laboratories.map((lab) => {
            return (
              <Menu.Item key={(`../../../${t(lab.labname)}`).replace(/ /g, "-")}>
                <Link to={(`../../../lab/${t(lab.labname)}`).replace(/ /g, "-")} state={{ labid: lab.labid, labname: t(lab.labname) }}>
                  {t(lab.labname)}
                </Link>
              </Menu.Item>
            )
          })
        }
      </Menu.SubMenu>
      <Menu.SubMenu key="navbar-activities" title={t('navbar-activities')}>
        {
          activitiesSubMenu.map((option) => {
            return <Menu.Item key={option.to}>
              <Link to={option.to}>
                {t(`${option.text}`)}
              </Link>
            </Menu.Item>
          })
        }
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu key="navbar-briefcase" title={t('navbar-briefcase')}>
      {
        portfolioSubMenu.map((option) => {
          return <Menu.Item key={option.to}>
            <Link to={option.to}>
              {t(`${option.text}`)}
            </Link>
          </Menu.Item>
        })
      }
    </Menu.SubMenu>
    <Menu.SubMenu key="navbar-about-us" title={t('navbar-about-us')}>
      {
        aboutUsSubMenu.map((option) => {
          return <Menu.Item key={option.to}>
            <Link to={option.to}>
              {t(`${option.text}`)}
            </Link>
          </Menu.Item>
        })
      }
    </Menu.SubMenu>
    <Menu.Item key="/contact">
      <Link to="/contact">
        {t('navbar-contact')}
      </Link>
    </Menu.Item>
    <Menu.Item key="navbar-translation-">
      <div className={styles['navbar-translation-menu']}>
        <button className={styles['navbar-translation-menu-btn']} id={styles['navbar-translation-menu-btn-en']} onClick={() => i18n.changeLanguage("en")}>EN</button>
        <button className={styles['navbar-translation-menu-btn']} id={styles['navbar-translation-menu-btn-el']} onClick={() => i18n.changeLanguage("el")}>GR</button>
      </div>
    </Menu.Item>
    {
      isLoggedIn &&
      <Menu.SubMenu key="admin" title="Admin">
        <Menu.Item key="navbar-welcome-message">
          Logged in as: {JSON.parse(userInfo).username}
        </Menu.Item>
        <Divider />
        {
          adminAddSubMenu.map((option) => {
            return <Menu.Item key={option.to}>
              <Link to={option.to}>
                {option.text}
              </Link>
            </Menu.Item>
          })
        }
        <Divider />
        {
          adminViewSubMenu.map((option) => {
            return <Menu.Item key={option.to}>
              <Link to={option.to}>
                {option.text}
              </Link>
            </Menu.Item>
          })
        }
        <Divider />
        <Menu.Item key="user-rights">
          <Link to="/user-rights">
            Add/Delete System Users
          </Link>
        </Menu.Item>
        <Divider />
        <Menu.Item key="log-out" onClick={() => {
          localStorage.removeItem("msl-unique-user-identifiers");
          setIsLoggedIn(false);
          navigate(`/`, { replace: true });
        }}>
          Log Out
        </Menu.Item>
      </Menu.SubMenu>
    }
  </Menu>;
}

export default CustomNavbar;