import React from 'react'
import StaffMemberRow from '../StaffMemberRow/StaffMemberRow.component';
import styles from '../AllStaffMembers.module.css';
import { getLabStaffMembers } from '../../../../utils/API/staffRequests';

function StaffByLab({ setLoading, staffByLab, setstaffByLab, staffByLabFilled, setStaffByLabFilled, labs, setUserToBeDeleted, setUserNameToBeDeleted, setOpen }) {

    async function updateStaffByLab(event) {
        setLoading(true);
        const staffResponse = await getLabStaffMembers(event.target.value);
        setstaffByLab(staffResponse);
        setStaffByLabFilled(true);
        setLoading(false);
    }

    return (
        <div>
            <h2>Staff Members by Lab</h2>
            <div className={styles['all-staff-members-labs-container']}>
                <select name={styles["all-staff-members-labs"]} id="all-staff-members-labs" onChange={updateStaffByLab}>
                    <option value="default">
                        Choose a Lab
                    </option>
                    {
                        labs.map((lab) => {
                            return <option value={lab._id} key={lab._id}>
                                {lab.title_en}
                            </option>
                        })
                    }
                </select>
                <div className={styles['all-staff-members-by-lab-container']}>
                    {
                        staffByLabFilled && staffByLab.map((staff_member) => {
                            return <StaffMemberRow staff_member={staff_member} setUserToBeDeleted={setUserToBeDeleted} setUserNameToBeDeleted={setUserNameToBeDeleted} setOpen={setOpen} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default StaffByLab