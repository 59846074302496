import React, { Component } from 'react'
import { Routes, Route, HashRouter } from 'react-router-dom'
import {
    Achievements,
    ActivitiesAndConferences,
    AddActivityOrConferenceForm,
    AddPublicationToStaff,
    AdminActivitiesAndConferences,
    AdminPublications,
    AllAnnouncementsAndNews,
    AllProjects,
    AllStaffMembers,
    AnnouncementForm,
    AnnouncementView,
    Contact,
    EditActivityOrConferenceForm,
    Education,
    History,
    Homepage,
    LabForm,
    LabView,
    LatestNews,
    Login,
    NewsForm,
    NewsView,
    NotFound,
    Personnel,
    ProjectForm,
    Projects,
    ProjectView,
    PublicationForm,
    Publications,
    PublicationView,
    StaffForm,
    StaffView,
    StudentSupport,
    UserRights,
    VisionAndPerspective,
    WelcomeMessage
} from '../Pages';
import ScrollToTop from '../Components/ScrollToTop';


class Routing extends Component {
    state = {}
    render() {
        return (
            <HashRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/achievements" element={<Achievements />} />
                    <Route path="/active-projects" element={<Projects />} />
                    <Route path="/announcements" element={<AllAnnouncementsAndNews />} />
                    <Route path="/announcements/:announcementID" element={<AnnouncementView />} />
                    <Route path="/conferences" element={<ActivitiesAndConferences />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/edit-conferences" element={<AdminActivitiesAndConferences />} />
                    <Route path="/edit-other-activities" element={<AdminActivitiesAndConferences />} />
                    <Route path="/edit-publications/" element={<AdminPublications />} />
                    <Route path="/education" element={<Education />} />
                    <Route path="/history" element={<History />} />
                    <Route path="lab/:labname" element={<LabView labid=":labid" />} />
                    <Route path="/latest-news-and-updates" element={<LatestNews />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/news" element={<AllAnnouncementsAndNews />} />
                    <Route path="/news/:newsID" element={<NewsView />} />
                    <Route path="/other-activities" element={<ActivitiesAndConferences />} />
                    <Route path="/personnel" element={<Personnel />} />
                    <Route path="/previous-projects" element={<Projects />} />
                    <Route path="/projects" element={<AllProjects />} />
                    <Route path="/projects/:projectID" element={<ProjectView />} />
                    <Route path="/publications" element={<Publications />} />
                    <Route path="/publications/:publicationID" element={<PublicationView />} />
                    <Route path="/staff-members" element={<AllStaffMembers />} />
                    <Route path="staff-members/:staffname" element={<StaffView labid=":labid" />} />
                    <Route path="/student-support" element={<StudentSupport />} />
                    <Route path="/vision-and-perspective" element={<VisionAndPerspective />} />
                    <Route path="/welcome-message" element={<WelcomeMessage />} />
                    <Route path="/*" element={<NotFound />} />

                    <Route path="/add-activity" element={<AddActivityOrConferenceForm />} />
                    <Route path="/add-announcement" element={<AnnouncementForm />} />
                    <Route path="/add-conference" element={<AddActivityOrConferenceForm />} />
                    <Route path="/add-lab" element={<LabForm />} />
                    <Route path="/add-news" element={<NewsForm />} />
                    <Route path="/add-project" element={<ProjectForm />} />
                    <Route path="/add-publication" element={<PublicationForm />} />
                    <Route path="/add-staff" element={<StaffForm />} />

                    <Route path="/edit-announcement" element={<AnnouncementForm />} />
                    <Route path="/edit-conferences/:conferenceID" element={<EditActivityOrConferenceForm />} />
                    <Route path="/edit-lab" element={<LabForm />} />
                    <Route path="/edit-news" element={<NewsForm />} />
                    <Route path="/edit-other-activities/:conferenceID" element={<EditActivityOrConferenceForm />} />
                    <Route path="/edit-projects/:projectID" element={<ProjectForm />} />
                    <Route path="/edit-publications/:publicationID" element={<PublicationForm />} />
                    <Route path="/edit-staff" element={<StaffForm />} />

                    <Route path="/user-rights" element={<UserRights />} />

                    <Route path="/add-publication-to-member/:staffID" element={<AddPublicationToStaff />} />
                </Routes>
            </HashRouter>
        );
    }
}

export default Routing
