import React from 'react';
import styles from './formStyles.module.css';

function LabCheckboxSelector({ labs }) {
  return (
    <div className={styles['custom-form-row']}>
      <label>Labs</label>
      {
        labs.map((lab) => {
          return <div key={lab._id} >
            <input type="checkbox"
              name={lab._id}
              value={lab._id}
              id={lab._id}
            />
            &nbsp;
            {`${lab.title_en}`}
          </div>
        })
      }
    </div>
  )
}

export default LabCheckboxSelector