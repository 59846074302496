import React from 'react';
import styles from './Software.module.css';

function Software() {
    return (
        <div className={styles['student-support-software-container']}>

            <h5>A. Linear and Integer Programming</h5>
            <ol>
                <li>
                    <a href='https://www.solver.com/' target="_blank" rel="noreferrer">
                        SOLVER Software by FRONTLINE SYSTEMS
                    </a>
                </li>
                <li>
                    <a href='https://www.lindo.com/' target="_blank" rel="noreferrer">
                        LINDO Software, by LINDO Corp
                    </a>
                </li>
                <li>
                    <a href='https://www.sas.com/el_gr/home.html' target="_blank" rel="noreferrer">
                        SAS Software, by SAS Institute
                    </a>
                </li>
                <li>
                    <a href='https://www.uni.com/products/imsl/index.html' target="_blank" rel="noreferrer">
                        IMSL Libraries, by IMSL
                    </a>
                </li>
                <li>
                    <a href='https://www.nag.com/' target="_blank" rel="noreferrer">
                        NAG Library, by NAG
                    </a>
                </li>
                <li>
                    <a href='https://www.mathworks.com/products/optimization.html' target="_blank" rel="noreferrer">
                        MATLAB Library, by Mathworks
                    </a>
                </li>
            </ol>

            <h5>B. DECISION TREES</h5>
            <ol>
                <li>
                    <a href='https://www.palisade.com/' target="_blank" rel="noreferrer">
                        PRECISION TREE Software, by Palisade Corp
                    </a>
                </li>
                <li>
                    <a href='https://treeplan.com' target="_blank" rel="noreferrer">
                        TREEPLAN Software, Decision Support Services
                    </a>
                </li>
                <li>
                    <a href='https://www.syncopation.com/' target="_blank" rel="noreferrer">
                        DPL Software, by Syncopation
                    </a>
                </li>
                <li>
                    <a href='https://connect.informs.org/das/home' target="_blank" rel="noreferrer">
                        Overview of Decision Analysis Software by The Decision Analysis society of INFORMS
                    </a>
                </li>
            </ol>

            <h5>C. RISK MANAGEMENT</h5>
            <ol>
                <li>
                    <a href='https://www.palisade.com/' target="_blank" rel="noreferrer">
                        @RISK Software, by Palisade Corp
                    </a>
                </li>
            </ol>

            <h5>D. MULTICRITERIA ANALYSIS</h5>
            <ol>
                <li>
                    <a href='https://www.expertchoice.com/2021' target="_blank" rel="noreferrer">
                        Expert Choice Software, by Expert Choice Inc
                    </a>
                </li>
                <li>
                    <a href='https://www.aueb-msl.gr' target="_blank" rel="noreferrer">
                        Finclass (Financial Classification Problems with Multicriteria Decision making)
                    </a>
                </li>
                <li>
                    <a href='http://www.mcdmsociety.org/soft.html' target="_blank" rel="noreferrer">
                        Overview of available software, by the International Society on Multiple Criteria Decision Making
                    </a>
                </li>
            </ol>

            <h5>E. VEHICLE ROUTING SOFTWARE</h5>
            <ol>
                <li>
                    <a href='https://www.informs.org/ORMS-Today/OR-MS-Today-Software-Surveys/Vehicle-Routing-Software-Survey' target="_blank" rel="noreferrer">
                        Overview of VRP software, by OR/MS Today
                    </a>
                </li>
                <li>
                    <a href='https://pubsonline.informs.org/magazine/orms-today' target="_blank" rel="noreferrer">
                        Overview of VRP software
                    </a>
                </li>
            </ol>

        </div>
    )
}

export default Software