import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ContactForm.module.css';

function ContactForm() {
    const { t } = useTranslation();

    return (
        <div className={styles['contact-form-container']}>
            <h3>{t('contact-title')}</h3>
            <h2>{t('contact-title2')}</h2>
            <div className={styles['contact-form-inputs-container']}>
                <div className={styles['contact-form-inputs-left-col']}>
                    <input placeholder={t('contact-full-name')} />
                    <input placeholder='Email*' />
                    <input placeholder={t('contact-telephone')} />
                </div>
                <div className={styles['contact-form-inputs-right-col']}>
                    <textarea placeholder={t('contact-message')} />
                    <button className='main-btn'>{t('send')}</button>
                </div>
            </div>

        </div>
    )
}

export default ContactForm