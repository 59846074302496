import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Loading, CustomNavbar as Navbar } from '../../Components';
import { getPublications } from '../../utils/API/publicationsRequests';
import styles from './Publications.module.css';

function Publications() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [publications, setPublications] = useState([{}]);

    const sortPublications = (publications) => {
        const sortedPublications = publications.slice(); // Create a shallow copy to avoid modifying the original array
        sortedPublications.sort((a, b) => {
            // First, compare by datePublished
            if (a.datePublished === "N/A" && b.datePublished !== "N/A") {
                return 1; // "N/A" comes after any actual date
            } else if (a.datePublished !== "N/A" && b.datePublished === "N/A") {
                return -1; // Any actual date comes before "N/A"
            } else if (a.datePublished < b.datePublished) {
                return -1; // Swap for ascending order, change to 1 for descending order
            } else if (a.datePublished > b.datePublished) {
                return 1; // Swap for ascending order, change to -1 for descending order
            }
    
            // If datePublished is the same, compare by lab_authors_names_en
            const authorA = a.lab_authors_names_en[0].toUpperCase();
            const authorB = b.lab_authors_names_en[0].toUpperCase();
    
            if (authorA < authorB) {
                return -1;
            } else if (authorA > authorB) {
                return 1;
            } else {
                return 0;
            }
        });
    
        return sortedPublications;
      };

    useEffect(() => {
        const fetchPublications = async () => {
            const publicationsResponse = await getPublications();
            console.log(publicationsResponse)

            const publications = sortPublications(publicationsResponse)
            console.log(publications)
            setPublications(publications);
        };
        setLoading(true);
        fetchPublications()
            .then(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div>
            <Navbar />
            {loading && <Loading />}
            {
                (!loading) &&
                <div className={styles['publications-container']}>
                    <div className={styles['publications-container-header']}>
                        <div className={styles['publications-container-header-img']}></div>
                        <h1>{t("navbar-publications")}</h1>
                    </div>
                    <div className={styles['publications-container-publications']}>
                        {
                            publications.map((publication) => {
                                return <Link to={`../../publications/${publication._id}`} key={publication._id}>
                                    <div className={styles['publications-container-single-publication-container']}>
                                        <div className={styles['publications-container-single-publication-body']}>
                                            <h5>{publication[`title_${i18next.language}`]} <span className={styles['publications-container-single-publication-doi']}>{publication.DOI}</span></h5>
                                            <div className={styles['publications-container-single-publication-body-labs']}>
                                                {
                                                    publication[`lab_names_${i18next.language}`] && publication[`lab_names_${i18next.language}`].map((publication_lab) => {
                                                        return <div key={publication_lab} className={styles["publications-container-single-publication-body-single-lab"]}>{publication_lab}</div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className={styles['publications-container-single-publication-body-authors']}>
                                            {t("authors")}:
                                            {
                                                publication[`lab_authors_names_${i18next.language}`] && publication[`lab_authors_names_${i18next.language}`].map((author) => {
                                                    return <div key={author} className={styles["publications-container-single-publication-body-single-author"]}>
                                                        {author}
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className={styles['publications-container-single-publication-body-authors']}>
                                            {t("out_authors")}:
                                            {
                                                publication[`out_authors_${i18next.language}`] ? <div className={styles["publications-container-single-publication-body-single-author"]}>
                                                        {publication[`out_authors_${i18next.language}`]}
                                                    </div> : <div>N/A</div>
                                                
                                            }
                                        </div>
                                        <div className={styles['publications-container-single-publication-body-authors']}>
                                            {t("date_published")}:
                                            {
                                                publication[`datePublished`] ? <div className={styles["publications-container-single-publication-body-single-author"]}>
                                                        {publication[`datePublished`]}
                                                    </div> : <div>N/A</div>
                                                
                                            }
                                        </div>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Publications