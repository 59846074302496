import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function getAllLabs() {
    const labsResponse = await makeRequest(
        'get',
        `${API_URL}/lab`,
        {},
        {},
        {}
    );
    return labsResponse.data;
}

export async function getLab(id) {
    const labResponse = await makeRequest(
        'get',
        `${API_URL}/lab/${id}`,
        {},
        {},
        {}
    );
    return labResponse.data;
}

export async function getLabByName(name) {
    const labResponse = await makeRequest(
        'get',
        `${API_URL}/lab?labname=${name}`,
        {},
        {},
        {},
    );
    return labResponse.data;
}

export async function createLab(data) {
    const labResponse = await makeRequest(
        'post',
        `${API_URL}/lab/add`,
        data,
        {},
        getRequestHeaders(),
    );
    return labResponse.data;
}

export async function patchLab(id, data) {
    const labResponse = await makeRequest(
        'patch',
        `${API_URL}/lab/${id}`,
        data,
        {},
        getRequestHeaders(),
    );
    return labResponse.data;
}

export async function deleteLab(id) {
    await makeRequest(
        'delete',
        `${API_URL}/lab/${id}`,
        {},
        {},
        getRequestHeaders()
    );
}