import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { Footer, Loading, CustomNavbar as Navbar } from '../../../Components';
import { API_URL } from '../../../utils/constants';
import { sendMultipleRequests } from '../../../utils/API/lifRequest';
import { deleteStaffMember } from '../../../utils/API/staffRequests';
import StaffByType from './StaffByType/StaffByType.component';
import StaffByLab from './StaffByLab/StaffByLab.component';
import AllStaffMembersComponent from './AllStaffMembers/AllStaffMembers.component';
import ButtonsToolbar from './ButtonsToolbar/ButtonsToolbar.component';
import styles from './AllStaffMembers.module.css';

function AllStafMembers() {
    const [userToBeDeleted, setUserToBeDeleted] = useState("");
    const [userNameToBeDeleted, setUserNameToBeDeleted] = useState("");
    const [loading, setLoading] = useState(false);
    const [allStaffShow, setallStaffShow] = useState(true);
    const [staffByLabShow, setstaffByLabShow] = useState(false);
    const [staffByTypeShow, setstaffByTypeShow] = useState(false);
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const [staff, setStaff] = useState([{}]);
    const [labs, setLabs] = useState([{}]);
    const [staffByLab, setstaffByLab] = useState([{}]);
    const [staffByLabFilled, setStaffByLabFilled] = useState(false);
    const [staffByType, setstaffByType] = useState([{}]);
    const [staffByTypeFilled, setstaffByTypeFilled] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteStaff(userToBeDeleted);
        setOpen(false);
        setConfirmLoading(false);
    };

    async function deleteStaff(userToBeDeleted) {
        setLoading(true);
        await deleteStaffMember(userToBeDeleted);
        let tempArr = staff.filter(function (obj) {
            return obj._id !== userToBeDeleted;
        });
        setStaff(tempArr);
        setLoading(false);

    }

    useEffect(() => {
        const fetchData = async () => {
            const staffRequest = {
                method: 'get',
                url: `${API_URL}/staff`,
                data: {},
                params: {},
                headers: {}
            };
            const labsRequest = {
                method: 'get',
                url: `${API_URL}/lab`,
                data: {},
                params: {},
                headers: {}
            };
            const responses = await sendMultipleRequests([staffRequest, labsRequest]);
            const staffResponse = responses[0];
            const labsRespose = responses[1];
            let sortArray = staffResponse.data;
            sortArray.sort((a, b) => (a.lastname_en > b.lastname_en ? 1 : -1));
            setStaff(sortArray);
            setLabs(labsRespose.data);
        }
        setLoading(true);
        if (!userInfo) {
            setLoading(false);
            navigate(`/`, { replace: true });
        }
        fetchData().then(() => {
            setLoading(false);
        });
    }, [navigate, userInfo]);

    return (
        <div className={styles['all-staff-members-container']}>
            <Navbar />
            {loading && <Loading />}
            <div className={styles['all-staff-container-loading-height']}>
                {
                    !loading && <div className={styles['staff-members']}>
                        <ButtonsToolbar
                            setallStaffShow={setallStaffShow}
                            setstaffByLabShow={setstaffByLabShow}
                            setstaffByTypeShow={setstaffByTypeShow}
                        />
                        {
                            allStaffShow && <AllStaffMembersComponent
                                staff={staff}
                                setUserToBeDeleted={setUserToBeDeleted}
                                setUserNameToBeDeleted={setUserNameToBeDeleted}
                                setOpen={setOpen}
                            />
                        }
                        {
                            staffByLabShow && <StaffByLab
                                setLoading={setLoading}
                                staffByLab={staffByLab}
                                setstaffByLab={setstaffByLab}
                                staffByLabFilled={staffByLabFilled}
                                setStaffByLabFilled={setStaffByLabFilled}
                                labs={labs}
                                setUserToBeDeleted={setUserToBeDeleted}
                                setUserNameToBeDeleted={setUserNameToBeDeleted}
                                setOpen={setOpen}
                            />
                        }
                        {
                            staffByTypeShow && <StaffByType
                                setLoading={setLoading}
                                staffByType={staffByType}
                                setstaffByType={setstaffByType}
                                staffByTypeFilled={staffByTypeFilled}
                                setstaffByTypeFilled={setstaffByTypeFilled}
                                setUserToBeDeleted={setUserToBeDeleted}
                                setUserNameToBeDeleted={setUserNameToBeDeleted}
                                setOpen={setOpen}
                            />
                        }
                    </div>
                }
            </div>
            <Footer />
            <Modal
                title={'Delete Lab Member'}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>
                    {`Are you sure you want to delete lab member "${userNameToBeDeleted}"?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>
        </div>
    )
}

export default AllStafMembers