import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ManagementProblems.module.css';

function ManagementProblems() {
    const { t } = useTranslation();
    const [data, setData] = useState(true);
    const [performance, setPerformance] = useState(false);
    const [knowledge, setKnowledge] = useState(false);
    return (
        <div className={styles['student-support-management-problems-container']}>
            <h3>
                {t('student-support-sidebar-menu-02')}
            </h3>
            <div className={styles['management-problems-menu']}>
                <p
                    onClick={() => { setData(true); setPerformance(false); setKnowledge(false); }}
                >Data Envelopment Analysis</p>
                <p
                    onClick={() => { setData(false); setPerformance(true); setKnowledge(false); }}
                >Performance Measurement &amp; Balanced Scorecard</p>
                <p
                    onClick={() => { setData(false); setPerformance(false); setKnowledge(true); }}
                >Knowledge Management</p>
            </div>
            <hr />
            <div className={styles['management-problems-content']}>

                {
                    data &&
                    <div className={styles['management-problems-data']}>
                        <h5>Introduction</h5>
                        <a href='http://www.emp.pdx.edu/dea/homedea.html' target="_blank" rel="noreferrer">
                            Data Envelopment Analysis
                        </a>
                        <p>
                            This is a thorough presentation of DEA, by T. Anderson.
                        </p>

                        <h5>Course Notes</h5>

                        <a href='https://mat.gsia.cmu.edu/classes/QUANT/NOTES/chap12/chap12.html' target="_blank" rel="noreferrer">
                            Data Envelopment Analysis
                        </a>
                        <p>
                            The mathematical formulation of data envelopment analysis, developed by Michael Trick from the Graduate School of Industrial Administration of the Carnegie Mellon University.
                        </p>

                        <a href='http://people.brunel.ac.uk/~mastjjb/jeb/or/dea.html' target="_blank" rel="noreferrer">
                            Data Envelopment Analysis: Introductory notes
                        </a>
                        <p>
                            These notes are a series of introductory notes on topics that fall under the broad heading of the field of operations research (OR). They are written by J E Beasley and used as course notes in an introductory OR course at Imperial College
                        </p>


                    </div>
                }

                {
                    performance &&
                    <div className={styles['management-problems-performance']}>

                        <p>
                            The Balances Scorecard is a performance management approach that focuses on four indicators, including customer perspective, internal-business processes, learning and growth and financials, to monitor progress toward organization's strategic goals.
                        </p>

                        <h5>
                            Introduction
                        </h5>

                        <a href='www.balancedscorecard.org' target="_blank" rel="noreferrer">
                            The Balanced Scorecard Institute
                        </a>
                        <p>
                            This site contains a relevant introduction to the BSC, information about vendors and useful material for downloading as well as interesting links.
                        </p>
                        <a href='http://www.bscol.com/redirect.html' target="_blank" rel="noreferrer">
                            Balanced Scorecard Collaborative
                        </a>
                        <p>
                            It is a professional service firm led by R.S. Kaplan and D.P. Norton (the "inventors" of the BSC). Rich content to explore. Provides possibility to subscribe to the periodical newsletter "Balanced Scorecard Report" and download a sample issue.
                        </p>
                        <a href='https://managementhelp.org/organizationalperformance/balanced-scorecard.htm' target="_blank" rel="noreferrer">
                            Performance Measurement &amp; Balanced Scorecard Issues
                        </a>
                        <p>
                            It is a resource with links concerning various perspectives in business performance management, library links and links to organizational improvement programs.
                        </p>

                        <h5>
                            Articles and Papers
                        </h5>

                        <a href='hbsp.harvard.edu/b01/en/search/searchResults.jhtml?sid=W1IHG24WBSPZAAKRGWDSELQ&Ntx=mode%252Bmatchallpartial&Ntt=BALANCED+SCORECARD&N=0&Ntk=main_search&x=14&y=11' target="_blank" rel="noreferrer">
                            Articles and papers through HBS
                        </a>
                        <p>
                            It is a gateway to numerous articles and case studies on the BSC from Harvard Business School, including the "classics" by Norton and Kaplan. Accessible through topica indexa "Management"a "Balanced Scorecard"
                        </p>
                        <a href='https://www.organizedchange.com/balancedscorecard.htm' target="_blank" rel="noreferrer">
                            Balanced Scorecard, BSC and Performance Improvement
                        </a>
                        <p>
                            It is a general article about Balanced Scorecard and Performance improvement by "Organized Change Consultancy". <br />"The problem is that not everything that counts can be counted, and not everything that can be counted counts."
                        </p>
                        <a href='https://www.best-in-class.com/bestp/domrep.nsf/insights/CEB41DB2BF0828D085256E24005A03A6!OpenDocument' target="_blank" rel="noreferrer">
                            Best Practices LLC
                        </a>
                        <p>
                            This site contains under the headline "General Management" over 50 papers on BSC, including some targeted to specific companies/sectors. Most papers cost between $7 and $ 12. Pay attention to the word count, some papers are hardly more than an abstract!
                        </p>

                        <h5>
                            Software
                        </h5>

                        <a href='http://www.pbviews.com/' target="_blank" rel="noreferrer">
                            Panorama Business Views
                        </a>
                        <p>
                            Software demos and the on-line journal "Perform Magazine".
                        </p>
                        <a href='http://www.dialogsoftware.com/' target="_blank" rel="noreferrer">
                            Dialog Software
                        </a>
                        <p>
                            Software demo downloadable, positively rated by Gartner Group.
                        </p>
                        <a href='https://ignitetech.com/softwarelibrary/acorn' target="_blank" rel="noreferrer">
                            Acorn Systems
                        </a>
                        <p>
                            Software solutions for a variety of performance related management methods.However it is mainly a descriptive site.  The following websites contain a description of the BSC solutions offered by the biggest ERP manufacturers namely Oracle and SAP.
                        </p>
                        <a href='https://www.oracle.com/applications/' target="_blank" rel="noreferrer">
                            BSC solutions by Oracle
                        </a>
                        <br />
                        <a href='https://www.sap.com/solutions/business-suite/erp/sapsem.epx' target="_blank" rel="noreferrer">
                            BSC solutions by SAP
                        </a>
                    </div>
                }

                {
                    knowledge &&
                    <div className={styles['management-problems-knowledge']}>
                        <h5>Introduction and Associations</h5>
                        <a href='http://www.kmresource.com/' target="_blank" rel="noreferrer">
                            The Knowledge Management Resource Center
                        </a>
                        <p>
                            On this site you'll find a comprehensive collection of KM resources, each of them reviewed and briefly described to help you quickly locate what you're looking for.
                        </p>

                        <a href='https://ec.europa.eu/info/index_en' target="_blank" rel="noreferrer">
                            The eLearning initiative of the European Commission
                        </a>
                        <p>
                            The eLearning initiative of the European Commission seeks to mobilise the educational and cultural communities, as well as the economic and social players in Europe, in order to speed up changes in the education and training systems for Europe's move to a knowledge-based society.
                        </p>
                        <a href='http://www.knowledgeboard.com/' target="_blank" rel="noreferrer">
                            Knowledge Board, The Portal for the European Knowledge Management Community
                        </a>
                        <p>
                            This is an interesting web site providing articles and news on subjects around Knowledge Management and Innovation.
                        </p>

                        <a href='https://www.apqc.org/resource-library' target="_blank" rel="noreferrer">
                            APQC
                        </a>
                        <p>
                            APQC, an internationally recognized non-profit organization, provides expertise in benchmarking and best practices research. APQC helped launch KM as a key business practice in 1996.
                        </p>
                        <a href='http://www.knowmap.com/' target="_blank" rel="noreferrer">
                            KnowMap
                        </a>
                        <p>
                            This is the link to the Knowledge management, Auditing &amp; Mapping magazine.
                        </p>

                        <a href='http://www.kmnews.com/' target="_blank" rel="noreferrer">
                            Knowledge Management News
                        </a>
                        <p>
                            This site is a useful resource from the Hout Consulting aiming to provide news and insights for and about the Knowledge/Information Management practitioner.
                        </p>
                        <a href='https://dan.com/buy-domain/kwork.org?redirected=true&tld=org' target="_blank" rel="noreferrer">
                            Association of Knowledgework
                        </a>
                        <p>
                            AOK is an association for individuals engaged in knowledge work throughout the world
                        </p>

                        <h5>Companies </h5>
                        <a href='http://www.kmresource.com/' target="_blank" rel="noreferrer">
                            CoWorking
                        </a>
                        <p>
                            CoWorking is dedicated to the exploration of collaboration. It is concerned about technologies, tools, techniques, social processes, best practices and whatever it is that brings people together to work effectively and productively as equals.
                        </p>

                        <a href='https://ec.europa.eu/info/index_en' target="_blank" rel="noreferrer">
                            eKnowledgeCenter
                        </a>
                        <p>
                            This is a company offers online KM training program for obtaining Knowledge Management Certification.
                        </p>
                        <a href='http://www.knowledgeboard.com/' target="_blank" rel="noreferrer">
                            KMTool
                        </a>
                        <p>
                            Even though it is characterized "A global community for knowledge management professionals", this site provides interesting links, reading materials, and vendor information
                        </p>

                    </div>
                }


            </div>
        </div>
    )
}

export default ManagementProblems