import React from 'react';
import PersonnelMember from '../PersonnelMember/PersonnelMember.component';

function PersonnelPanel({ staff }) {
    return (
        <div>
            {
                staff.map((staff_member) => {
                    return <PersonnelMember staff_member={staff_member} />
                })
            }
        </div>
    )
}

export default PersonnelPanel