import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomNavbar, Loading, Success } from '../../../../Components'
import { API_URL } from '../../../../utils/constants';
import { getRequestHeaders, sendMultipleRequests } from '../../../../utils/API/lifRequest';
import TextInput from '../TextInput.component';
import TextEditor from '../TextEditor.component';
import SubmitButton from '../SubmitButton.component';
import SocialMediaEditor from './SocialMediaEditor.component';
import LabsRadioGroup from './LabsRadioGroup.component';
import StaffMemberTypeSelector from './StaffMemberTypeSelector.component';
import styles from '../formStyles.module.css';
import { getAllLabs } from '../../../../utils/API/labRequests';
import { createStaffMember, getStaffMember, patchStaffMember } from '../../../../utils/API/staffRequests';

function StaffForm() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [blankError, setBlankError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [labs, setLabs] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [socialMediaRows, setSocialMediaRows] = useState(['0']);
    const [numberOfSocialRows, setNumberOfSocialRows] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const { staffID } = location.state ? location.state : '';
    const pathName = location.pathname;
    const isAdd = (pathName === '/add-staff');
    const toDoAction = isAdd ? 'create' : 'update';
    const [currentLabID, setCurrentLabID] = useState("");

    const AddSocialRow = () => {
        const tempRowsArray = socialMediaRows.concat(numberOfSocialRows.toString());
        setNumberOfSocialRows(numberOfSocialRows + 1);
        setSocialMediaRows(tempRowsArray);
    }

    const setHideForm = (hide) => {
        setLoading(hide);
        setShowForm(!hide);
    }

    const [staffInfo, setStaffInfo] = useState({
        firstname_en: "",
        lastname_en: "",
        firstname_el: "",
        lastname_el: "",
        email: "",
        phone: "",
        shortbio_en: "",
        shortbio_el: "",
        social_links: [],
        lab: "",
        staff_type: "",
        publications: null
    });

    const onShortBioEnChange = (value) => {
        setStaffInfo({
            ...staffInfo,
            shortbio_en: value
        });
    }

    const onShortBioElChange = (value) => {
        setStaffInfo({
            ...staffInfo,
            shortbio_el: value
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            const labsResponse = await getAllLabs();
            setLabs(labsResponse);
            if (!isAdd) {
                const staffResponse = await getStaffMember(staffID);
                setStaffInfo(staffResponse);
                setStaffInfo({
                    ...staffInfo,
                    firstname_en: staffResponse.firstname_en,
                    lastname_en: staffResponse.lastname_en,
                    firstname_el: staffResponse.firstname_el,
                    lastname_el: staffResponse.lastname_el,
                    email: staffResponse.email,
                    phone: staffResponse.phone,
                    shortbio_en: staffResponse.shortbio_en,
                    shortbio_el: staffResponse.shortbio_el,
                    social_links: staffResponse.social_links,
                    lab: staffResponse.lab,
                    staff_type: staffResponse.staff_type,
                    publications: staffResponse.publications
                });
                for (let i = 1; i < staffResponse.social_links.length + 1; i++) {
                    AddSocialRow();
                }
                setCurrentLabID(staffResponse.lab);
                document.getElementById(`${staffResponse.staff_type}`).checked = true;
                const labCheckbox = document.getElementById(`${staffResponse.lab}`);
                const noLabCheckbox = document.getElementById(`no-lab`);
                labCheckbox ? labCheckbox.checked = true : noLabCheckbox.checked = true;
                staffResponse.social_links.forEach((socialLink, i) => {
                    const nameInput = document.getElementById(`social-name-${i}`);
                    const urlInput = document.getElementById(`social-url-${i}`);
                    if (nameInput && urlInput) {
                        nameInput.value = socialLink.name;
                        urlInput.value = socialLink.url;
                    }
                });
            }
        }
        setHideForm(true);
        if (!userInfo) {
            setHideForm(false);
            navigate(`/`, { replace: true });
        }
        fetchData()
            .then(() => {
                setHideForm(false);
            });
    }, [navigate, userInfo]);

    const emptyFieldsErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`Please provide a ${field} for the staff member you are about to ${toDoAction}.`);
        setHideForm(false);
    }

    const fewCharactersErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`The ${field} must be at least 20 characters.`);
        setHideForm(false);
    }

    const isFormIncomplete = () => {
        if (!staffInfo.firstname_en) {
            emptyFieldsErrorHandler("First Name (EN)");
            return true;
        } else if (!staffInfo.lastname_en) {
            emptyFieldsErrorHandler("Last Name (EN)");
            return true;
        } else if (!staffInfo.firstname_el) {
            emptyFieldsErrorHandler("First Name (EL)");
            return true;
        } else if (!staffInfo.lastname_el) {
            emptyFieldsErrorHandler("Last Name (EL)");
            return true;
        } else if (!staffInfo.email) {
            emptyFieldsErrorHandler("Email");
            return true;
        } else if (!staffInfo.shortbio_en) {
            emptyFieldsErrorHandler("Short Bio (EN)");
            return true;
        } else if (!staffInfo.shortbio_el) {
            emptyFieldsErrorHandler("Short Bio (EL)");
            return true;
        } else if (staffInfo.shortbio_en.length < 20) {
            fewCharactersErrorHandler("short bio (EN)");
            return true;
        } else if (staffInfo.shortbio_el.length < 20) {
            fewCharactersErrorHandler("short bio (EL)");
            return true;
        } else if (!document.querySelector('input[name="staff-type-radio"]:checked')) {
            emptyFieldsErrorHandler("Staff Member Type");
            return true;
        }
        return false;
    }

    const processStaff = async (event) => {
        try {
            event.preventDefault();
            event.persist();
            setHideForm(true);
            if (isFormIncomplete()) {
                return;
            }
            // Get all social media entries
            const socialMedia = [];
            for (let i = 0; i < numberOfSocialRows; i++) {
                const nameID = `social-name-${i}`;
                const urlID = `social-url-${i}`;
                const nameElem = document.getElementById(nameID);
                const urlElem = document.getElementById(urlID);
                if (nameElem.value && urlElem.value) {
                    const social = {
                        name: nameElem.value,
                        url: urlElem.value,
                    };
                    socialMedia.push(social);
                }
            }
            // Get the Staff Member's Type
            const staffType = document.querySelector('input[name="staff-type-radio"]:checked').value;
            // Get the Staff Member's Lab
            const labInput = document.querySelector('input[name="lab-radio"]:checked');
            const labID = labInput?.value || "No Lab is Registered";
            // Get the lab's names
            let lab_name_en = "No Lab is Registered";
            let lab_name_el = "Μη Καταχωρημένο Εργαστήριο";
            if (labID !== "No Lab is Registered") {
                const tempLab = labs.find((lab) => lab._id === labID);
                if (tempLab) {
                    lab_name_en = tempLab.title_en;
                    lab_name_el = tempLab.title_el;
                }
            }
            if (isAdd) {
                await createStaffMember({
                    firstname_en: staffInfo.firstname_en,
                    lastname_en: staffInfo.lastname_en,
                    firstname_el: staffInfo.firstname_el,
                    lastname_el: staffInfo.lastname_el,
                    email: staffInfo.email,
                    phone: staffInfo.phone,
                    shortbio_en: staffInfo.shortbio_en,
                    shortbio_el: staffInfo.shortbio_el,
                    social_links: socialMedia,
                    lab: labID,
                    lab_name_en: lab_name_en,
                    lab_name_el: lab_name_el,
                    staff_type: staffType,
                    publications: staffInfo.publications
                });
            } else {
                const tempLab = labs.find((lab) => lab._id === labID);
                await patchStaffMember(staffID, {
                    firstname_en: staffInfo.firstname_en,
                    lastname_en: staffInfo.lastname_en,
                    firstname_el: staffInfo.firstname_el,
                    lastname_el: staffInfo.lastname_el,
                    email: staffInfo.email,
                    phone: staffInfo.phone,
                    shortbio_en: staffInfo.shortbio_en,
                    shortbio_el: staffInfo.shortbio_el,
                    social_links: socialMedia,
                    lab: labID,
                    lab_name_en: tempLab.title_en,
                    lab_name_el: tempLab.title_el,
                    staff_type: staffType,
                    publications: staffInfo.publications
                });
                if (!tempLab._id) {
                    emptyFieldsErrorHandler("Lab");
                    return;
                }
                const isSameLab = tempLab.lab_staff.find((member) => member === staffID) | [];
                if (isSameLab.length === 0) {
                    const previousLab = labs.find((lab) => lab._id === currentLabID);
                    tempLab.lab_staff.push(staffID);
                    const previousLabStaff = previousLab.lab_staff.filter(staff => staff._id !== staffID);
                    const newLabRequest = {
                        method: 'patch',
                        url: `${API_URL}/lab/${tempLab._id}`,
                        data: {
                            title_en: tempLab.title_en,
                            title_el: tempLab.title_el,
                            description_en: tempLab.description_en,
                            description_el: tempLab.description_el,
                            headmaster: tempLab.headmaster,
                            lab_staff: tempLab.lab_staff,
                            publications: tempLab.publications
                        },
                        params: {},
                        headers: getRequestHeaders(),
                    };
                    const previousLabRequest = {
                        method: 'patch',
                        url: `${API_URL}/lab/${previousLab._id}`,
                        data: {
                            title_en: previousLab.title_en,
                            title_el: previousLab.title_el,
                            description_en: previousLab.description_en,
                            description_el: previousLab.description_el,
                            headmaster: previousLab.headmaster,
                            lab_staff: previousLabStaff,
                            publications: previousLab.publications
                        },
                        params: {},
                        headers: getRequestHeaders(),
                    };
                    await sendMultipleRequests([newLabRequest, previousLabRequest]);
                }
            }
            setSuccess(true);
            setHideForm(false);
        } catch (error) {
            setHideForm(false);
            console.error(error);
            throw error;
        }
    }

    return (
        <div>
            <CustomNavbar />
            <div className={styles['custom-form-container']}>
                {
                    success && <Success action={'updated'} data={'Staff Member'} />
                }
                {loading && <Loading />}
                {
                    !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={processStaff}>
                        {
                            isAdd ? <h3>Add New  Staff Member</h3> : <h3>Edit Staff Member</h3>
                        }
                        <TextInput
                            label={"First Name | EN"}
                            name={"firstname_en"}
                            dataInfo={staffInfo}
                            setDataInfo={setStaffInfo}
                            dataToBeCreated={'Member'}
                        />
                        <TextInput
                            label={"First Name | EL"}
                            name={"firstname_el"}
                            dataInfo={staffInfo}
                            setDataInfo={setStaffInfo}
                            dataToBeCreated={'Member'}
                        />

                        <TextInput
                            label={"Last Name | EN"}
                            name={"lastname_en"}
                            dataInfo={staffInfo}
                            setDataInfo={setStaffInfo}
                            dataToBeCreated={'Member'}
                        />

                        <TextInput
                            label={"Last Name | EL"}
                            name={"lastname_el"}
                            dataInfo={staffInfo}
                            setDataInfo={setStaffInfo}
                            dataToBeCreated={'Member'}
                        />

                        <TextInput
                            label={"Email"}
                            name={"email"}
                            dataInfo={staffInfo}
                            setDataInfo={setStaffInfo}
                            dataToBeCreated={'Member'}
                        />

                        <TextInput
                            label={"Phone"}
                            name={"phone"}
                            dataInfo={staffInfo}
                            setDataInfo={setStaffInfo}
                            dataToBeCreated={'Member'}
                        />

                        <TextEditor
                            title={'Short Bio | EN'}
                            toolbarID={'t1'}
                            value={staffInfo.shortbio_en}
                            onChange={onShortBioEnChange}
                        />

                        <TextEditor
                            title={'Description | EL'}
                            toolbarID={'t2'}
                            value={staffInfo.shortbio_el}
                            onChange={onShortBioElChange}
                        />

                        <SocialMediaEditor
                            socialMediaRows={socialMediaRows}
                            AddSocialRow={AddSocialRow}
                        />

                        <LabsRadioGroup labs={labs} />

                        <label>Type:</label>
                        <StaffMemberTypeSelector />

                        <SubmitButton blankError={blankError} errorMessage={errorMessage} />
                    </form>
                }
            </div>
        </div>
    )
}

export default StaffForm