import React from 'react'
import StaffMemberRow from '../StaffMemberRow/StaffMemberRow.component';

function AllStaffMembersComponent({ staff, setUserToBeDeleted, setUserNameToBeDeleted, setOpen}) {
    return (
        <div>
            <h2>All Staff Members</h2>
            {
                staff.map((staff_member) => {
                    return <StaffMemberRow staff_member={staff_member} setUserToBeDeleted={setUserToBeDeleted} setUserNameToBeDeleted={setUserNameToBeDeleted} setOpen={setOpen} />
                })
            }
        </div>
    )
}

export default AllStaffMembersComponent