import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomNavbar, Loading, Success } from '../../../../Components'
import LabPlaceholder from '../../../../assets/aueb_entrance.jpg';
import TextInput from '../TextInput.component';
import TextEditor from '../TextEditor.component';
import LabDropdownSelector from '../LabDropdownSelector.component';
import SubmitButton from '../SubmitButton.component';
import ImageUploader from './ImageUploader.component';
import styles from '../formStyles.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllLabs, getLab, getLabByName } from '../../../../utils/API/labRequests';
import { createNews, getNews, patchNews } from '../../../../utils/API/newsRequests';

function NewsForm() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [labs, setLabs] = useState([{}]);
    const [newsLab, setNewsLab] = useState({});
    const [blankError, setBlankError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showForm, setShowForm] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname;
    const isAdd = (pathName === '/add-news');
    const successAction = isAdd ? 'created' : 'updated';
    const { newsID } = location.state ? location.state : '';
    const [newsInfo, setNewsInfo] = useState({
        title_en: "",
        title_el: "",
        description_en: "",
        description_el: "",
        image: LabPlaceholder,
        lab: ""
    });

    const onDescriptionEnChange = (value) => {
        setNewsInfo({
            ...newsInfo,
            description_en: value
        });
    }

    const onDescriptionElChange = (value) => {
        setNewsInfo({
            ...newsInfo,
            description_el: value
        });
    }

    const setHideForm = (hide) => {
        setLoading(hide);
        setShowForm(!hide);
    }

    useEffect(() => {
        const fetchData = async () => {
            const labsResponse = await getAllLabs();
            setLabs(labsResponse);
            if (!isAdd) {
                const newsRespose = await getNews(newsID);
                setNewsInfo(newsRespose);
                setNewsInfo({
                    ...newsInfo,
                    title_en: newsRespose.title_en,
                    title_el: newsRespose.title_el,
                    description_en: newsRespose.description_en,
                    description_el: newsRespose.description_el,
                    image: newsRespose.image,
                    lab: newsRespose.lab
                });
                setStartDate(Date.parse(newsRespose.createdAt));
                const labResponse = newsRespose.lab !== 'General'
                    ? await getLab(newsRespose.lab)
                    : await getLabByName(newsRespose.lab);
                setNewsLab(labResponse);
            }
        }
        setHideForm(true);
        if (!userInfo) {
            setHideForm(false);
            navigate(`/`, { replace: true });
        }
        fetchData()
            .then(() => {
                setHideForm(false);
            });
    }, [navigate, userInfo, isAdd, newsID]);

    const emptyFieldsErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`Please provide a ${field} for the news you are about to add.`);
        setHideForm(false);
    }

    const isFormIncomplete = () => {
        if (!newsInfo.title_en) {
            emptyFieldsErrorHandler("Title (EN)");
            return true;
        } else if (!newsInfo.description_en) {
            emptyFieldsErrorHandler("Description (EN)");
            return true;
        } else if (!newsInfo.title_el) {
            emptyFieldsErrorHandler("Title (EL)");
            return true;
        } else if (!newsInfo.description_el) {
            emptyFieldsErrorHandler("Description (EL)");
            return true;
        } else if (!newsInfo.image) {
            emptyFieldsErrorHandler("Image");
            return true;
        } else if (!document.getElementById('add-announcement-lab') || document.getElementById('add-announcement-lab').value === 'None') {
            if (isAdd) {
                emptyFieldsErrorHandler("Lab");
                return true;
            }
        }
        return false;
    }

    const onSubmitForm = async (event) => {
        try {

            event.preventDefault();
            event.persist();
            setHideForm(true);
            if (isFormIncomplete()) {
                return;
            }
            const labID = isAdd ? document.getElementById('add-announcement-lab').value : newsInfo.lab;
            if (isAdd) {
                await createNews({
                    title_en: newsInfo.title_en,
                    title_el: newsInfo.title_el,
                    description_en: newsInfo.description_en,
                    description_el: newsInfo.description_el,
                    image: newsInfo.image,
                    lab: labID,
                    createdAt: startDate
                });
            } else {
                await patchNews(newsID, {
                    title_en: newsInfo.title_en,
                    title_el: newsInfo.title_el,
                    description_en: newsInfo.description_en,
                    description_el: newsInfo.description_el,
                    image: newsInfo.image,
                    lab: labID,
                    createdAt: startDate
                });
            }
            setSuccess(true);
            setHideForm(false);
        } catch (error) {
            console.error(error);
            setHideForm(false);
            throw error;
        }
    }

    return (
        <div>
            <CustomNavbar />
            <div className={styles['custom-form-container']}>
                {
                    success && <Success action={successAction} data={'News Section'} />
                }
                {loading && <Loading />}
                {
                    !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={onSubmitForm}>
                        {
                            isAdd
                                ? <h3>Add New News Section</h3>
                                : <h3>Edit News Section</h3>
                        }

                        <TextInput
                            label={"Title | EN"}
                            name={"title_en"}
                            dataInfo={newsInfo}
                            setDataInfo={setNewsInfo}
                            dataToBeCreated={'News'}
                        />

                        <TextInput
                            label={"Title | GR"}
                            name={"title_el"}
                            dataInfo={newsInfo}
                            setDataInfo={setNewsInfo}
                            dataToBeCreated={'News'}
                        />

                        <TextEditor
                            title={'Description | EN'}
                            toolbarID={'t1'}
                            value={newsInfo.description_en}
                            onChange={onDescriptionEnChange}
                        />

                        <TextEditor
                            title={'Description | EL'}
                            toolbarID={'t2'}
                            value={newsInfo.description_el}
                            onChange={onDescriptionElChange}
                        />

                        <LabDropdownSelector
                            labs={labs}
                            isAdd={isAdd}
                            currentLabTitle={newsLab.title_en}
                        />

                        <ImageUploader
                            newsInfo={newsInfo}
                            setNewsInfo={setNewsInfo}
                            imageTitle={newsInfo.title_en}
                        />

                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />

                        <SubmitButton blankError={blankError} errorMessage={errorMessage} />
                    </form>
                }
            </div>
        </div>
    )
}

export default NewsForm