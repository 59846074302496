import React from 'react'
import { useTranslation } from 'react-i18next';
import LabStaffMember from '../LabStaffMember/LabStaffMember.component';

function LabHeadmaster({ headmaster }) {
    const { t } = useTranslation();

    return (
        <div>
            <span>{t('lab-view-headmaster')} </span>
            <LabStaffMember staff={headmaster} />
        </div>
    )
}

export default LabHeadmaster