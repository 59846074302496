import React from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessBanner from '../../assets/success.png';
import styles from './Success.module.css';

function Success({ action, data }) {
    const navigate = useNavigate();
    return (
        <div className={styles["success-component-container"]}>
            <img src={SuccessBanner} alt="success" />
            <h3>{`You have ${action} a new ${data} Successfully!`}</h3>
            <button className='main-btn'
                onClick={() => {
                    navigate(`/`, { replace: true });
                }}
            >
                Go to Homepage
            </button>
        </div>
    )
}

export default Success