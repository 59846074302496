// export const API_URL = 'http://localhost:8000'
export const API_URL = 'https://api.aueb-msl.gr'

export const laboratories = [
    {
        labid: "62379415c216e87c3ab8160a",
        labname: "InnKnow"
    },
    {
        labid: "62379581c216e87c3ab8161e",
        labname: "TRANSLOG"
    },
    {
        labid: "623798fac216e87c3ab81648",
        labname: "ORDeS"
    },
    {
        labid: "6237998dc216e87c3ab81668",
        labname: "OPeR"
    },
    {
        labid: "6246ada7d5a18013060f784c",
        labname: "FRC"
    },
    {
        labid: "6281201f8fe9428051d45d3f",
        labname: "RISE"
    },
    {
        labid: "62b867f0e91a45f6ce64ca43",
        labname: "FRFashtech"
    },
    {
        labid: "634d25ba6e576327e4c84f99",
        labname: "HealthSec"
    }
]

export const staffTypesSubMenu = [
    {
        value: "peer-professor",
        text: "staff-type-peer-professor"
    },
    {
        value: "professor",
        text: "staff-type-professor"
    },
    {
        value: "associate-professor",
        text: "staff-type-associate-professor"
    },
    {
        value: "assistant-professor",
        text: "staff-type-assistant-professor"
    },
    {
        value: "visiting-professor",
        text: "staff-type-visiting-professor"
    },
    {
        value: "phd",
        text: "staff-type-phd"
    },
    {
        value: "postdoc-researcher",
        text: "staff-type-postdoc-researcher"
    },
    {
        value: "phd-candidate",
        text: "staff-type-phd-candidate"
    },
    {
        value: "freelancer",
        text: "staff-type-freelancer"
    },
    {
        value: "bachelor-student",
        text: "staff-type-bachelor-student"
    },
    {
        value: "master-student",
        text: "staff-type-master-student"
    },
    {
        value: "graduate",
        text: "staff-type-graduate"
    }
]