import React from 'react'
import styles from '../LabPublications.module.css';
import { useTranslation } from 'react-i18next';
import LabPublicationsListNode from '../LabPublicationsListNode/LabPublicationsListNode.component';

function LabPublicationsList({ publications }) {
    const { t } = useTranslation();
    return (
        <div className={styles['lab-view-publications-container']}>
            <h3>{t("navbar-publications")}</h3>
            {
                publications.map((publication) => {
                    return <LabPublicationsListNode publication={publication} />
                })
            }
        </div>
    )
}

export default LabPublicationsList