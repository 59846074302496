import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { getNewsWithLimit } from '../../../utils/API/newsRequests';
import styles from './News.module.css';

function News({ setIsNewsLoading }) {
    const { t } = useTranslation();
    const [newsRows, setNewsRows] = useState([]);
    useEffect(() => {
        const fetchNews = async () => {
            const newsResponse = await getNewsWithLimit(4);
            setNewsRows([newsResponse.slice(0,2), newsResponse.slice(-2)]);
        };
        if( setIsNewsLoading !== undefined) {
            setIsNewsLoading(true);
            fetchNews()
            .then(() => {
                setIsNewsLoading(false);
            });
        } else {
            fetchNews();
        }
    }, [setIsNewsLoading]);

    return (
        (newsRows) && (newsRows.length > 1) &&
        <div className={styles['news-container']}>
            <h3>{t('homepage-news')}</h3>
            <div className={styles['all-news']}>
                {
                    newsRows.map((news_row) => {
                        return <div className={styles['news-row']}>
                            {
                                news_row.map((new_section) => {
                                    const date = new_section.createdAt.substring(0, 10).split("-");
                                    return <div className={styles['news']} key={new_section._id}>
                                        <Link to={`/news/${new_section._id}`}>
                                            <div className={styles['news-card']}>
                                                <div className={styles['news-card-img']}>
                                                    <img src={new_section.image} alt={new_section[`title_${i18n.language}`]} />
                                                </div>
                                                <div className={styles['news-card-body']}>
                                                    <span className={styles['news-card-body-date']}>
                                                        {`${date[2]}-${date[1]}-${date[0]}`}
                                                    </span>
                                                    <span className={styles['news-card-body-title']}>
                                                        {new_section[`title_${i18n.language}`]}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    })                    
                }
            </div>
            <div className={styles['news-container-btn']}>
                <Link to="/news">
                    <button className='main-btn'>{t("homepage-all-news-btn")} </button>
                </Link>
            </div>
        </div>
    )
}

export default News