export default function GetStaffType(type) {
    switch (type) {
        case "peer-professor":
            return 'staff-type-peer-professor';
        case "professor":
            return 'staff-type-professor';
        case "associate-professor":
            return 'staff-type-associate-professor';
        case "assistant-professor":
            return 'staff-type-assistant-professor';
        case "visiting-professor":
            return 'staff-type-visiting-professor';
        case "phd":
            return 'staff-type-phd';
        case "postdoc-researcher":
            return 'staff-type-postdoc-researcher';
        case "phd-candidate":
            return 'staff-type-phd-candidate';
        case "freelancer":
            return 'staff-type-freelancer';
        case "bachelor-student":
            return 'staff-type-bachelor-student';
        case "master-student":
            return 'staff-type-master-student';
        case "graduate":
            return 'staff-type-graduate';
        default:
            return 'staff-type-unregistered';
    }
}