import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Astrolavos from '../../../assets/astrolavos.jpg';
import styles from './UsefulLinks.module.css';

function UsefulLinks() {
    const { t } = useTranslation();
    const [professional, setProfessional] = useState(true);
    const [journalsOR, setJournalsOR] = useState(false);
    const [journalsPOM, setJournalsPOM] = useState(false);
    const [journalsOfManagement, setJournalsOfManagement] = useState(false);
    const [internationalPress, setInternationalPress] = useState(false);
    const [businessSchools, setBusinessSchools] = useState(false);
    const [astrolavos, setAstrolavos] = useState(false);


    return (
        <div className={styles['student-support-useful-links-container']}>
            <h3>
                {t('student-support-sidebar-menu-03')}
            </h3>

            <div className={styles['useful-links-menu']}>
                <div className={styles['useful-links-menu-row']}>
                    <p
                        onClick={() => { setProfessional(true); setJournalsOR(false); setJournalsPOM(false); setJournalsOfManagement(false); setInternationalPress(false); setBusinessSchools(false); setAstrolavos(false); }}
                    >Professional Associations</p>
                    <p
                        onClick={() => { setProfessional(false); setJournalsOR(true); setJournalsPOM(false); setJournalsOfManagement(false); setInternationalPress(false); setBusinessSchools(false); setAstrolavos(false); }}
                    >Journals of OR/MS</p>
                    <p
                        onClick={() => { setProfessional(false); setJournalsOR(false); setJournalsPOM(true); setJournalsOfManagement(false); setInternationalPress(false); setBusinessSchools(false); setAstrolavos(false); }}
                    >Journals of POM</p>
                </div>
                <div className={styles['useful-links-menu-row']}>
                    <p
                        onClick={() => { setProfessional(false); setJournalsOR(false); setJournalsPOM(false); setJournalsOfManagement(true); setInternationalPress(false); setBusinessSchools(false); setAstrolavos(false); }}
                    >Journals of Management</p>
                    <p
                        onClick={() => { setProfessional(false); setJournalsOR(false); setJournalsPOM(false); setJournalsOfManagement(false); setInternationalPress(true); setBusinessSchools(false); setAstrolavos(false); }}
                    > International &amp; Greek Press </p>
                    <p
                        onClick={() => { setProfessional(false); setJournalsOR(false); setJournalsPOM(false); setJournalsOfManagement(false); setInternationalPress(false); setBusinessSchools(true); setAstrolavos(false); }}
                    >Business Schools (a selection) </p>
                </div>
                <div className={styles['useful-links-menu-row']}>
                    <p
                        onClick={() => { setProfessional(false); setJournalsOR(false); setJournalsPOM(false); setJournalsOfManagement(false); setInternationalPress(false); setBusinessSchools(false); setAstrolavos(true); }}
                    >Astrolavos</p>

                </div>
            </div>

            <hr />

            <div className={styles['useful-links-content']}>

                {
                    professional &&
                    <div className={styles['useful-links-content-professional']}>
                        <ul>
                            <li>
                                <a href='https://www.informs.org/' target="_blank" rel="noreferrer">
                                    INFORMS - The Institute for Operations Research &amp; Management Science
                                </a>
                            </li>
                            <li>
                                <a href='https://www.euro-online.org/web/pages/1/home' target="_blank" rel="noreferrer">
                                    EURO - Association of European Operations Research Societies
                                </a>
                            </li>
                            <li>
                                <a href='http://www.ifors.org/web/' target="_blank" rel="noreferrer">
                                    IFORS - International Federation of Operations Research Societies
                                </a>
                            </li>
                            <li>
                                <a href='https://www.eeee.org.gr/PublicPages/a_0_el.aspx' target="_blank" rel="noreferrer">
                                    HELORS - Hellenic Operational Research Society
                                </a>
                            </li>
                            <li>
                                <a href='http://www.asor.org.au/index.php' target="_blank" rel="noreferrer">
                                    Australian Operational Research Society
                                </a>
                            </li>
                            <li>
                                <a href='http://www.airo.org/index.php/it/' target="_blank" rel="noreferrer">
                                    Italian Operational Research Society
                                </a>
                            </li>
                            <li>
                                <a href='http://www.corscalgary.org/' target="_blank" rel="noreferrer">
                                    CORS - Canadian Operational Research Society
                                </a>
                            </li>
                            <li>
                                <a href='https://www.theorsociety.com/' target="_blank" rel="noreferrer">
                                    ORS - English Operational Research Society
                                </a>
                            </li>
                            <li>
                                <a href='https://decisionsciences.org/' target="_blank" rel="noreferrer">
                                    DSI - Decision Sciences Institute
                                </a>
                            </li>
                            <li>
                                <a href='http://www.mcdmsociety.org/' target="_blank" rel="noreferrer">
                                    International Society on Multiple Criteria Decision Making
                                </a>
                            </li>
                            <li>
                                <a href='http://www.mathopt.org/' target="_blank" rel="noreferrer">
                                    MOS - Mathematical Optimization Society
                                </a>
                            </li>
                            <li>
                                <a href='https://connect.informs.org/das/home' target="_blank" rel="noreferrer">
                                    DAS - Decision Analysis Society of INFORMS
                                </a>
                            </li>
                            <li>
                                <a href='https://www.ieee.org/' target="_blank" rel="noreferrer">
                                    IEEE - Institute for Electrical &amp; Electronics Engineers
                                </a>
                            </li>
                            <li>
                                <a href='https://www.ifip.org/' target="_blank" rel="noreferrer">
                                    IFIP - International Federation for information Processing
                                </a>
                            </li>
                            <li>
                                <a href='https://www.nsf.gov/' target="_blank" rel="noreferrer">
                                    NSF - National Science Foundation
                                </a>
                            </li>
                            <li>
                                <a href='https://www.acm.org/' target="_blank" rel="noreferrer">
                                    ACM - Association of Computing Machinery
                                </a>
                            </li>
                            <li>
                                <a href='https://www.ams-web.org/default.aspx' target="_blank" rel="noreferrer">
                                    AMS - Academy of Marketing Science
                                </a>
                            </li>
                            <li>
                                <a href='https://aom.org/' target="_blank" rel="noreferrer">
                                    Academy of Management
                                </a>
                            </li>
                            <li>
                                <a href='https://www.amstat.org/' target="_blank" rel="noreferrer">
                                    ASA - American Statistical Association
                                </a>
                            </li>
                            <li>
                                <a href='https://www.poms.org/' target="_blank" rel="noreferrer">
                                    POMS - Production &amp; Operations Management Society
                                </a>
                            </li>
                            <li>
                                <a href='https://www.siam.org/' target="_blank" rel="noreferrer">
                                    SIAM - Society for Industrial and Applied Mathematics
                                </a>
                            </li>
                            <li>
                                <a href='https://www.nationalacademies.org/trb/transportation-research-board' target="_blank" rel="noreferrer">
                                    TRB - Transportation Research Board
                                </a>
                            </li>
                            <li>
                                <a href='http://www.icisnet.org/' target="_blank" rel="noreferrer">
                                    ICIS - International Conference on Information Systems
                                </a>
                            </li>
                            <li>
                                <a href='https://www.aacsb.edu/' target="_blank" rel="noreferrer">
                                    AACSB - American Association of Collegiate Schools of Business
                                </a>
                            </li>
                        </ul>
                    </div>
                }

                {
                    journalsOR &&
                    <div className={styles['useful-links-content-journals-OR']}>
                        <ul>
                            <li>
                                <a href='https://pubsonline.informs.org/journal/mnsc/' target="_blank" rel="noreferrer">
                                    Management Science
                                </a>
                            </li>
                            <li>
                                <a href='https://pubsonline.informs.org/journal/opre' target="_blank" rel="noreferrer">
                                    Operations Research
                                </a>
                            </li>
                            <li>
                                <a href='https://www.jstor.org/journal/joperresesoci' target="_blank" rel="noreferrer">
                                    Journal of the Operational Research Society
                                </a>
                            </li>
                            <li>
                                <a href='https://www.sciencedirect.com/journal/european-journal-of-operational-research' target="_blank" rel="noreferrer">
                                    European Journal of Operational Research
                                </a>
                            </li>
                            <li>
                                <a href='https://www.springer.com/journal/12351' target="_blank" rel="noreferrer">
                                    Operational Research - An International Journal
                                </a>
                            </li>
                            <li>
                                <a href='https://onlinelibrary.wiley.com/journal/15405915' target="_blank" rel="noreferrer">
                                    Decision Sciences
                                </a>
                            </li>
                            <li>
                                <a href='https://pubsonline.informs.org/journal/inte' target="_blank" rel="noreferrer">
                                    Interfaces
                                </a>
                            </li>
                            <li>
                                <a href='https://onlinelibrary.wiley.com/journal/14753995' target="_blank" rel="noreferrer">
                                    International Transactions in Operational Research
                                </a>
                            </li>
                            <li>
                                <a href='https://www.springer.com/journal/10732' target="_blank" rel="noreferrer">
                                    Journal of Heuristics
                                </a>
                            </li>
                            <li>
                                <a href='https://www.sciencedirect.com/journal/computers-and-operations-research' target="_blank" rel="noreferrer">
                                    Computers and Operations Research
                                </a>
                            </li>
                            <li>
                                <a href='https://www.sciencedirect.com/journal/omega' target="_blank" rel="noreferrer">
                                    OMEGA
                                </a>
                            </li>
                            <li>
                                <a href='https://www.cambridge.org/core/journals/rairo-operations-research' target="_blank" rel="noreferrer">
                                    RAIRO-Operations Research
                                </a>
                            </li>
                            <li>
                                <a href='https://www.springer.com/journal/10479' target="_blank" rel="noreferrer">
                                    Annals of Operations Research
                                </a>
                            </li>
                        </ul>
                    </div>
                }

                {
                    journalsPOM &&
                    <div className={styles['useful-links-content-journals-POM']}>
                        <ul>
                            <li>
                                <a href='https://pubsonline.informs.org/journal/msom' target="_blank" rel="noreferrer">
                                    Manufacturing and Service Operations Management
                                </a>
                            </li>
                            <li>
                                <a href='https://pubsonline.informs.org/journal/trsc' target="_blank" rel="noreferrer">
                                    Transportation Science
                                </a>
                            </li>
                            <li>
                                <a href='https://www.emerald.com/products/journals/journals.htm' target="_blank" rel="noreferrer">
                                    Supply Chain Management - An International Journal
                                </a>
                            </li>
                            <li>
                                <a href='https://www.tandfonline.com/toc/tprs20/current' target="_blank" rel="noreferrer">
                                    International Journal of Production Research
                                </a>
                            </li>
                            <li>
                                <a href='https://www.journals.elsevier.com/international-journal-of-production-economics' target="_blank" rel="noreferrer">
                                    International Journal of Production Economics
                                </a>
                            </li>
                            <li>
                                <a href='https://www.inderscience.com/jhome.php?jcode=ijism' target="_blank" rel="noreferrer">
                                    International Journal of Integrated Supply Management
                                </a>
                            </li>
                            <li>
                                <a href='https://www.inderscience.com/jhome.php?jcode=ijpm' target="_blank" rel="noreferrer">
                                    International Journal of Procurement Management
                                </a>
                            </li>
                        </ul>
                    </div>
                }

                {
                    journalsOfManagement &&
                    <div className={styles['useful-links-content-journals-of-management']}>
                        <ul>
                            <li>
                                <a href='https://www.sciencedirect.com/journal/journal-of-food-engineering' target="_blank" rel="noreferrer">
                                    Academy of Management Journal
                                </a>
                            </li>
                            <li>
                                <a href='https://journals.sagepub.com/home/jom' target="_blank" rel="noreferrer">
                                    Journal of Management
                                </a>
                            </li>
                            <li>
                                <a href='https://onlinelibrary.wiley.com/journal/10970266' target="_blank" rel="noreferrer">
                                    Strategic Management Journal
                                </a>
                            </li>
                        </ul>
                    </div>
                }

                {
                    internationalPress &&
                    <div className={styles['useful-links-content-international-and-greek-press']}>
                        <h5>International Press</h5>
                        <ul>
                            <li>
                                <a href='https://www.ft.com/' target="_blank" rel="noreferrer">
                                    Financial Times
                                </a>
                            </li>
                            <li>
                                <a href='http://global.nytimes.com/?iht' target="_blank" rel="noreferrer">
                                    New York Times/ International Herald Tribune
                                </a>
                            </li>
                            <li>
                                <a href='https://www.washingtonpost.com/' target="_blank" rel="noreferrer">
                                    Washington Post
                                </a>
                            </li>
                            <li>
                                <a href='https://www.reuters.com/' target="_blank" rel="noreferrer">
                                    Reuters
                                </a>
                            </li>
                            <li>
                                <a href='http://global.chinadaily.com.cn/' target="_blank" rel="noreferrer">
                                    China Daily
                                </a>
                            </li>
                            <li>
                                <a href='https://economictimes.indiatimes.com/' target="_blank" rel="noreferrer">
                                    Economic Times
                                </a>
                            </li>
                            <li>
                                <a href='https://timesofindia.indiatimes.com/' target="_blank" rel="noreferrer">
                                    Times of India
                                </a>
                            </li>
                            <li>
                                <a href='https://www.infoworld.com/d/the-industry-standard' target="_blank" rel="noreferrer">
                                    The Industry Standard
                                </a>
                            </li>
                        </ul>
                        <h5>Greek Press</h5>
                        <ul>
                            <li>
                                <a href='https://www.kathimerini.gr/' target="_blank" rel="noreferrer">
                                    Kathimerini
                                </a>
                            </li>
                            <li>
                                <a href='https://www.tovima.gr/' target="_blank" rel="noreferrer">
                                    Vima
                                </a>
                            </li>
                            <li>
                                <a href='https://www.enet.gr/' target="_blank" rel="noreferrer">
                                    Eleftherotypia
                                </a>
                            </li>
                            <li>
                                <a href='https://www.naftemporiki.gr/' target="_blank" rel="noreferrer">
                                    Naftemporiki
                                </a>
                            </li>
                        </ul>
                    </div>
                }

                {
                    businessSchools &&
                    <div className={styles['useful-links-content-business-schools']}>
                        <ul>
                            <li>
                                <a href='http://rankings.ft.com/businessschoolrankings/global-mba-rankings-2011' target="_blank" rel="noreferrer">
                                    Financial Times rankings of MBA Programs
                                </a>
                            </li>
                            <li>
                                <a href='https://www.wharton.upenn.edu/' target="_blank" rel="noreferrer">
                                    Wharton School
                                </a>
                            </li>
                            <li>
                                <a href='https://home.gsb.columbia.edu/' target="_blank" rel="noreferrer">
                                    Columbia Business School
                                </a>
                            </li>
                            <li>
                                <a href='https://www.insead.edu/' target="_blank" rel="noreferrer">
                                    INSEAD - The Business School for the World
                                </a>
                            </li>
                            <li>
                                <a href='https://www.london.edu/' target="_blank" rel="noreferrer">
                                    London Business School
                                </a>
                            </li>
                            <li>
                                <a href='https://www.kellogg.northwestern.edu/' target="_blank" rel="noreferrer">
                                    Kellogg School of Management, Northwestern University
                                </a>
                            </li>
                            <li>
                                <a href='https://www.fuqua.duke.edu/' target="_blank" rel="noreferrer">
                                    Duke - The Fuqua School of Business
                                </a>
                            </li>
                            <li>
                                <a href='https://mitsloan.mit.edu/' target="_blank" rel="noreferrer">
                                    MIT Sloan School of Management
                                </a>
                            </li>
                            <li>
                                <a href='https://www.stern.nyu.edu/' target="_blank" rel="noreferrer">
                                    NYUSTERN - New York University, Leonard N. Stern School of Business
                                </a>
                            </li>
                            <li>
                                <a href='https://www.chicagobooth.edu/' target="_blank" rel="noreferrer">
                                    CHICAGOBOOTH - The University of Chicago Booth School of Business
                                </a>
                            </li>
                            <li>
                                <a href='http://www.gsb.stanford.edu/' target="_blank" rel="noreferrer">
                                    Stanford Graduate School of Business
                                </a>
                            </li>
                            <li>
                                <a href='https://www.anderson.ucla.edu/' target="_blank" rel="noreferrer">
                                    UCLA Anderson School of Management
                                </a>
                            </li>
                            <li>
                                <a href='https://michiganross.umich.edu/' target="_blank" rel="noreferrer">
                                    Michigan Ross School of Business
                                </a>
                            </li>
                            <li>
                                <a href='https://www.kenan-flagler.unc.edu/' target="_blank" rel="noreferrer">
                                    UNC Kenan-Flagler Business School
                                </a>
                            </li>
                            <li>
                                <a href='https://www.gsm.pku.edu.cn/' target="_blank" rel="noreferrer">
                                    Guanghua School of Management, Peking University
                                </a>
                            </li>
                            <li>
                                <a href='https://www.fdsm.fudan.edu.cn/en/' target="_blank" rel="noreferrer">
                                    School of Management, Fudan University
                                </a>
                            </li>
                            <li>
                                <a href='https://www.acem.sjtu.edu.cn/en/' target="_blank" rel="noreferrer">
                                    Antai College of Economics &amp; Management, Shanghai Jiao Tong University
                                </a>
                            </li>
                            <li>
                                <a href='https://www.mdi.ac.in/' target="_blank" rel="noreferrer">
                                    MDI (India) - Management Development Institute
                                </a>
                            </li>
                            <li>
                                <a href='https://nmims.edu/' target="_blank" rel="noreferrer">
                                    NMIMS - Narsee Monjee Institute of Management Studies
                                </a>
                            </li>
                        </ul>
                    </div>
                }

                {
                    astrolavos &&
                    <div className={styles['useful-links-content-astrolavos']}>
                        <div className={styles['useful-links-content-astrolavos-row']}>
                            <div className={styles['useful-links-content-astrolavos-row-img']}>
                                <img src={Astrolavos} alt="astrolavos-cover" />
                            </div>
                            <div className={styles['useful-links-content-astrolavos-row-body']}>
                                <p>
                                    Astrolavos is a periodical publication of the Mathematical and Educational Software Center, published by the Hellenic Mathematical Society.
                                </p>
                                <p>
                                    Chairman: Assistant Professor Emmanouil Kritikos, a Senior Research Fellow of the MSL
                                </p>
                            </div>
                        </div>
                        <p>
                            Astrolavos is aimed at educators, researchers and anyone who is interested in new technologies of information and communications. It includes scientific papers related to didactics of science and the application of new technologies in education, e-learning, artificial intelligence, programming, data structures, data bases, information retrieval, networks and web technologies. It also explores topics ranging from e-commerce, security based on cryptography, image editing, mobile phone technology, distributed programming, operating systems, numerical analysis, computer architecture, computational mathematics and applications, algorithms, information theory, theory of computation, optimization, graphics and computational geometry.
                            <br />
                            The journal offers a platform for experts, researchers and scholars to communicate, explore, and discuss knowledge in the field of mathematics.
                        </p>
                        <p>Downloads:</p>
                        <a href='https://msl.aueb.gr/files/issue09-2008.pdf' target="_blank" rel="noreferrer">
                            Issue 9, 2008
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/issue08-2007.pdf' target="_blank" rel="noreferrer">
                            Issue 8, 2007
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/issue07-2007.pdf' target="_blank" rel="noreferrer">
                            Issue 7, 2007
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/issue06-2006.pdf' target="_blank" rel="noreferrer">
                            Issue 6, 2006
                        </a>
                        <br />
                        <a href='https://msl.aueb.gr/files/issue05-2006.pdf' target="_blank" rel="noreferrer">
                            Issue 5, 2006
                        </a>
                        <br />
                    </div>
                }

            </div>


        </div>
    )
}

export default UsefulLinks