import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { deleteProject, getProjects } from '../../../utils/API/projectsRequests';
import { Footer, Loading, CustomNavbar as Navbar } from '../../../Components';
import styles from './AllProjects.module.css';

function AllProjects() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [projectToBeDeleted, setProjectToBeDeleted] = useState("");
    const [projects, setProjects] = useState([{}]);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteCurrentProject();
        setOpen(false);
        setConfirmLoading(false);
    };

    useEffect(() => {
        const fetchProjects = async () => {
            const projectsResponse = await getProjects();
            setProjects(projectsResponse);
        };
        if (!userInfo) {
            navigate(`/`, { replace: true });
        }
        setLoading(true);
        fetchProjects()
            .then(() => {
                setLoading(false);
            });
    }, [navigate, userInfo]);

    async function updateData() {
        setLoading(true);
        const projectsResponse = await getProjects();
        setProjects(projectsResponse);
        setLoading(false);
    }

    async function deleteCurrentProject() {
        setLoading(true);
        await deleteProject(projectToBeDeleted);
        await updateData();
        setLoading(false);
    }

    let title = `title_${i18next.language}`;
    let project_labs = `lab_names_${i18next.language}`;
    let project_authors = `lab_authors_names_${i18next.language}`;

    return (
        <div className={styles['all-projects-container']}>
            <Navbar />
            {loading && <Loading />}
            {
                !loading &&
                <div className={styles['all-projects']}>
                    {
                        projects && projects.map((project) => {
                            return <div key={project._id} className={styles["all-projects-single-project"]}>
                                <div className={styles['all-projects-single-project-title']}>
                                    {project[title]}
                                </div>
                                <div className={styles['all-projects-single-project-body']}>
                                    <div className={styles['all-projects-single-project-labs']}>
                                        <div className={styles['all-projects-single-project-labs-title']}>
                                            {`${t("labs")}:`}
                                        </div>
                                        {
                                            (project[project_labs]) && project[project_labs].map((lab) => {
                                                return <div className={styles['all-projects-single-project-single-lab']}>
                                                    {lab}
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className={styles['all-projects-single-project-authors']}>
                                        <div className={styles['all-projects-single-project-authors-title']}>
                                            {`${t("authors")}:`}
                                        </div>
                                        {
                                            (project[project_authors]) && project[project_authors].map((author) => {
                                                return <div className={styles['all-projects-single-project-single-author']}>
                                                    {author}
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className={styles['all-projects-single-project-btn']}>
                                    <Link to={`../../../projects/${project._id}`}>
                                        <button className='main-btn'>
                                            {t("read-more")}
                                        </button>
                                    </Link>
                                    <button className='danger-btn' onClick={() => { setOpen(true); setProjectToBeDeleted(project._id) }}>
                                        {t("delete")}
                                    </button>
                                    <Link to={`../../../edit-projects/${project._id}`}>
                                        <button className='main-btn'>
                                            Edit
                                        </button>
                                    </Link>
                                </div>

                            </div>
                        })
                    }
                </div>
            }
            <Footer />
            <Modal
                title={'Delete Project'}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>
                    {`Are you sure you want to delete this project?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>
        </div>
    )
}

export default AllProjects
