import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { CustomNavbar, Loading, Success } from '../../../../Components'
import { API_URL } from '../../../../utils/constants';
import { sendMultipleRequests } from '../../../../utils/API/lifRequest';
import { createProject, patchProject } from '../../../../utils/API/projectsRequests';
import TextInput from '../TextInput.component';
import TextEditor from '../TextEditor.component';
import SubmitButton from '../SubmitButton.component';
import StaffDropdownSelector from '../StaffDropdownSelector.component';
import FileUploader from './FileUploader.component';
import LabCheckboxSelector from '../LabCheckboxSelector.component';
import StaffCheckboxSelector from '../StaffCheckboxSelector.component';
import StatusRadioGroup from './StatusRadioGroup.component';
import styles from '../formStyles.module.css';

function ProjectForm() {
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const navigate = useNavigate();
    const projectID = useParams().projectID;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [labs, setLabs] = useState([{}]);
    const [staff, setStaff] = useState([{}]);
    const [projectSupervisor, setProjectSupervisor] = useState();
    const [blankError, setBlankError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showForm, setShowForm] = useState(true);
    const location = useLocation();
    const pathName = location.pathname;
    const isAdd = (pathName === '/add-project');
    const successAction = isAdd ? 'created' : 'updated';
    const actionToDo = isAdd ? 'create' : 'update';
    const [open, setOpen] = useState(false);

    const [projectInfo, setProjectInfo] = useState({
        title_en: "",
        title_el: "",
        description_en: "",
        description_el: "",
        link: "",
        file: "",
        lab_authors: [],
        lab_authors_names_en: [],
        lab_authors_names_el: [],
        out_authors: [],
        supervisor: "",
        supervisor_name_en: "",
        supervisor_name_el: "",
        labs: [],
        lab_names_en: [],
        lab_names_el: [],
        active: false
    });

    const onDescriptionEnChange = (value) => {
        setProjectInfo({
            ...projectInfo,
            description_en: value
        });
    }

    const onDescriptionElChange = (value) => {
        setProjectInfo({
            ...projectInfo,
            description_el: value
        });
    }

    const setHideForm = (hide) => {
        setLoading(hide);
        setShowForm(!hide);
    }

    useEffect(() => {
        const fetchData = async () => {
            const labsRequest = {
                method: 'get',
                url: `${API_URL}/lab`,
                data: {},
                params: {},
                headers: {}
            };
            const staffRequest = {
                method: 'get',
                url: `${API_URL}/staff`,
                data: {},
                params: {},
                headers: {}
            };
            const projectRequest = {
                method: 'get',
                url: `${API_URL}/projects/${projectID}`,
                data: {},
                params: {},
                headers: {}
            };
            if (isAdd) {
                const responses = await sendMultipleRequests([labsRequest, staffRequest]);
                const labResponse = responses[0];
                const staffResponse = responses[1];
                setLabs(labResponse.data);
                setStaff(staffResponse.data);
            } else {
                const responses = await sendMultipleRequests([labsRequest, staffRequest, projectRequest]);
                const labsRespose = responses[0];
                const staffResponse = responses[1];
                const projectResponse = responses[2];
                setLabs(labsRespose.data);
                setStaff(staffResponse.data);
                setProjectInfo(projectResponse.data);
                setProjectInfo({
                    ...projectInfo,
                    title_en: projectResponse.data.title_en,
                    title_el: projectResponse.data.title_en,
                    description_en: projectResponse.data.description_en,
                    description_el: projectResponse.data.description_el,
                    link: projectResponse.data.link,
                    file: projectResponse.data.file,
                    lab_authors: projectResponse.data.lab_authors,
                    lab_authors_names_en: projectResponse.data.lab_authors_names_en,
                    lab_authors_names_el: projectResponse.data.lab_authors_names_el,
                    out_authors: projectResponse.data.out_authors,
                    supervisor: projectResponse.data.supervisor,
                    supervisor_name_en: projectResponse.data.supervisor_name_en,
                    supervisor_name_el: projectResponse.data.supervisor_name_el,
                    labs: projectResponse.data.labs,
                    lab_names_en: projectResponse.data.lab_names_en,
                    lab_names_el: projectResponse.data.lab_names_el,
                    active: projectResponse.data.active
                });
                projectResponse.data.lab_authors.forEach(author => {
                    document.getElementById(author).checked = true;
                });
                projectResponse.data.labs.forEach(lab => {
                    document.getElementById(lab).checked = true;
                });
                const activeCheckbox = document.getElementById("active");
                const inactiveCheckbox = document.getElementById("inactive");
                projectResponse.data.active === true ? activeCheckbox.checked = true : inactiveCheckbox.checked = true;
                setProjectSupervisor(staffResponse.data.find(member => member._id === projectResponse.data.supervisor));
            }
        }
        setHideForm(true);
        if (!userInfo) {
            setHideForm(false);
            navigate(`/`, { replace: true });
        }
        fetchData()
            .then(() => {
                setHideForm(false);
            });
    }, [navigate, userInfo, isAdd]);

    const emptyFieldsErrorHandler = (field) => {
        setBlankError(true);
        setErrorMessage(`Please provide a ${field} for the project you are about to ${actionToDo}.`);
        setHideForm(false);
    }

    const isFormIncomplete = () => {
        if (!projectInfo.title_en) {
            emptyFieldsErrorHandler("Title (EN)");
            return true;
        } else if (!projectInfo.description_en) {
            emptyFieldsErrorHandler("Description (EN)");
            return true;
        } else if (!projectInfo.title_el) {
            emptyFieldsErrorHandler("Title (EL)");
            return true;
        } else if (!projectInfo.description_el) {
            emptyFieldsErrorHandler("Description (EL)");
            return true;
        } else if (!document.getElementById('add-lab-headmaster-container') || document.getElementById('add-lab-headmaster-container').value === 'None') {
            if (!projectSupervisor.firstname_el) {
                emptyFieldsErrorHandler("Supervisor");
                return true;
            }
        } else if (!document.querySelector('input[name="active-project"]:checked')) {
            emptyFieldsErrorHandler("active status");
            return true;
        }
        return false;
    }

    const processProject = async (event) => {
        try {
            event.preventDefault();
            event.persist();
            setHideForm(true);
            if (isFormIncomplete()) {
                return;
            }
            //Get Supervisor
            const supervisorID = document.getElementById('add-lab-headmaster-container').value;
            const supervisor = staff.find(member => member._id === supervisorID);
            let supervisor_name_en = "";
            let supervisor_name_el = "";
            if (supervisor) {
                supervisor_name_en = `${supervisor.lastname_en} ${supervisor.firstname_en}`;
                supervisor_name_el = `${supervisor.lastname_el} ${supervisor.firstname_el}`;
            }
            // Get all Checked Staff Members
            let staffIDs = [];
            let labAuthorsEN = [];
            let labAuthorsEL = [];
            for (const member of staff) {
                const element = document.getElementById(member._id);
                if (element.checked) {
                    staffIDs.push(member._id);
                    labAuthorsEN.push(`${member.lastname_en} ${member.firstname_en}`);
                    labAuthorsEL.push(`${member.lastname_el} ${member.firstname_el}`);
                }
            }
            // Get all Checked Labs
            let labIDs = [];
            let labNamesEN = [];
            let labNamesEL = [];
            for (const lab of labs) {
                const element = document.getElementById(lab._id);
                if (element.checked) {
                    labIDs.push(lab._id);
                    labNamesEN.push(lab.title_en);
                    labNamesEL.push(lab.title_el);
                }
            }
            // Get the Project's activity
            let active = false;
            if (document.querySelector('input[name="active-project"]:checked').value === "active") {
                active = true;
            } else {
                active = false;
            }
            if (isAdd) {
                await createProject({
                    title_en: projectInfo.title_en,
                    title_el: projectInfo.title_el,
                    description_en: projectInfo.description_en,
                    description_el: projectInfo.description_el,
                    link: projectInfo.link,
                    file: projectInfo.file,
                    lab_authors: staffIDs,
                    lab_authors_names_en: labAuthorsEN,
                    lab_authors_names_el: labAuthorsEL,
                    out_authors: projectInfo.out_authors,
                    supervisor: supervisorID,
                    supervisor_name_en: supervisor_name_en,
                    supervisor_name_el: supervisor_name_el,
                    labs: labIDs,
                    lab_names_en: labNamesEN,
                    lab_names_el: labNamesEL,
                    active: active,
                });
            } else {
                await patchProject(projectID, {
                    title_en: projectInfo.title_en,
                    title_el: projectInfo.title_el,
                    description_en: projectInfo.description_en,
                    description_el: projectInfo.description_el,
                    link: projectInfo.link,
                    file: projectInfo.file,
                    lab_authors: staffIDs,
                    lab_authors_names_en: labAuthorsEN,
                    lab_authors_names_el: labAuthorsEL,
                    out_authors: projectInfo.out_authors,
                    supervisor: supervisorID,
                    supervisor_name_en: supervisor_name_en,
                    supervisor_name_el: supervisor_name_el,
                    labs: labIDs,
                    lab_names_en: labNamesEN,
                    lab_names_el: labNamesEL,
                    active: active,
                })
            }
            setSuccess(true);
            setHideForm(false);
        } catch (error) {
            setLoading(false);
            setShowForm(true);
            console.error(error);
            throw error;
        }
    }

    return (
        <div>
            <CustomNavbar />
            <div className={styles['custom-form-container']}>
                {
                    success && <Success action={successAction} data={'new Project'} />
                }
                {loading && <Loading />}
                {
                    !success && <form className={showForm ? `${styles["custom-form"]}` : `${styles["custom-form"]} ${styles["custom-form-container-low-opacity"]}`} onSubmit={processProject}>
                        {
                            isAdd ? <h3>Add New Project</h3> : <h3>Edit Project</h3>
                        }

                        <TextInput
                            label={"Title | EN"}
                            name={"title_en"}
                            dataInfo={projectInfo}
                            setDataInfo={setProjectInfo}
                            dataToBeCreated={'Project'}
                        />

                        <TextInput
                            label={"Title | GR"}
                            name={"title_el"}
                            dataInfo={projectInfo}
                            setDataInfo={setProjectInfo}
                            dataToBeCreated={'Project'}
                        />

                        <TextEditor
                            title={'Description | EN'}
                            toolbarID={'t1'}
                            value={projectInfo.description_en}
                            onChange={onDescriptionEnChange}
                        />

                        <TextEditor
                            title={'Description | EL'}
                            toolbarID={'t2'}
                            value={projectInfo.description_el}
                            onChange={onDescriptionElChange}
                        />

                        <TextInput
                            label={"Link"}
                            name={"link"}
                            dataInfo={projectInfo}
                            setDataInfo={setProjectInfo}
                            dataToBeCreated={'Project'}
                        />

                        <FileUploader
                            projectInfo={projectInfo}
                            setProjectInfo={setProjectInfo}
                            setOpen={setOpen}
                        />

                        <StaffDropdownSelector
                            title={'Supervisor'}
                            staffMember={projectSupervisor}
                            staff={staff}
                        />

                        <LabCheckboxSelector labs={labs} />

                        <StaffCheckboxSelector staff={staff} />

                        <StatusRadioGroup />

                        <SubmitButton blankError={blankError} errorMessage={errorMessage} />
                    </form>
                }

                <Modal
                    title={'File Preview'}
                    open={open}
                    onOk={() => {
                        setOpen(false);
                    }}
                    onCancel={() => {
                        setOpen(false);
                    }}
                >
                    <embed src={projectInfo.file} width="100%" height="800px" />
                </Modal>

            </div>
        </div>
    )
}

export default ProjectForm