import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LabPublicationsList from './LabPublicationsList/LabPublicationsList.component';
import { getPublicationsByLabID } from '../../../utils/API/publicationsRequests';

function LabPublications() {
    const location = useLocation();
    const { labid } = location.state;
    const [publications, setPublications] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const publicationsResponse = await getPublicationsByLabID(labid);
            setPublications(publicationsResponse);
        }
        fetchData();
    }, [labid]);

    return (
        (publications) && (publications.length > 0) && <LabPublicationsList publications={publications} />
    )
}

export default LabPublications