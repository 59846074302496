import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import { Footer, CustomNavbar as Navbar } from '../../Components';
import GetStaffType from '../../Components/GetStaffType';
import { Loading } from '../../Components';
import i18next from 'i18next';
import { API_URL } from '../../utils/constants';
import { sendMultipleRequests } from '../../utils/API/lifRequest';
import { getStaffMemberByName } from '../../utils/API/staffRequests';
import { getPublicationsByAuthor } from '../../utils/API/publicationsRequests';
import styles from './StaffView.module.css';

function StaffView() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    let { staffID } = "";
    let staffname = useParams().staffname;
    if (location.state) {
        staffID = (Object.keys(location).length > 0) ? location.state : null;
    }
    const [staffMember, setStaffMember] = useState({});
    const [publications, setPublications] = useState({});

    useEffect(() => {
        async function fetchData() {
            if (location.state) {
                const staffMemberRequest = {
                    method: 'get',
                    url: `${API_URL}/staff/${staffID.staffID}`,
                    data: {},
                    params: {},
                    headers: {},
                };
                const staffMemberPublicationsRequest = {
                    method: 'get',
                    url: `${API_URL}/publications?author=${staffID.staffID}`,
                    data: {},
                    params: {},
                    headers: {},
                };
                const responses = await sendMultipleRequests([staffMemberRequest, staffMemberPublicationsRequest]);
                const staffMemberResponse = responses[0];
                const staffMemberPublicationsResponse = responses[1];

                setStaffMember(staffMemberResponse.data);
                setPublications(staffMemberPublicationsResponse.data);
                setLoading(false);
            } else {
                const staffMemberResponse = await getStaffMemberByName(staffname);
                location.state = { staffID: staffMemberResponse[0]._id };
                setStaffMember(staffMemberResponse[0]);
                const staffMemberPublicationResponse = await getPublicationsByAuthor(staffMemberResponse[0]._id);
                setPublications(staffMemberPublicationResponse);
            }
        }
        setLoading(true);
        fetchData()
            .then(() => {
                setLoading(false);
            });
    }, [location, staffID.staffID, staffname]);


    return (
        <div className={styles['staff-member-view-container']}>
            <Navbar />
            <div className={styles['staff-member-view']}>
                {loading && <Loading />}
                {
                    !loading &&
                    <div>
                        <div className={styles['staff-member-view-row']}>
                            <div className={styles['staff-member-view-row-img-col']}>
                                <img src={`/staffMembersImages/${staffMember._id}.jpg`} alt={staffMember.firstname_en} />
                                <a href={`/CVs/${staffMember._id}${`_${i18next.language}`}.pdf`} target="_blank" rel='noreferrer' className={styles['staff-member-view-cv']}>{t('curriculum-vitae')}</a>
                            </div>
                            <div className={styles['staff-member-view-row-staff-info-col']}>
                                <h4>{`${staffMember[`lastname_${i18next.language}`]} ${staffMember[`firstname_${i18next.language}`]}`}</h4>
                                <h5>
                                    {
                                        (staffMember.lab) && (staffMember.lab !== "No Lab is Registered") && <span>{`${staffMember[`lab_name_${i18next.language}`]} | `}</span>
                                    }
                                    {t(GetStaffType(staffMember.staff_type))}
                                </h5>
                                <hr />
                                <p>{staffMember.email}</p>
                                <p>{staffMember.phone}</p>
                                <div className={styles['staff-member-staff-info-social']}>
                                    {
                                        (staffMember.social_links) && staffMember.social_links.map((social_link) => {
                                            return <span>&nbsp;<a href={social_link.url}>{social_link.name}</a>&nbsp;|</span>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles['staff-member-view-row']}>
                            <p className={styles['staff-member-short-bio']}>
                                {parse(`${staffMember[`shortbio_${i18next.language}`]}`)}
                            </p>
                        </div>
                        {
                            (publications) && (publications.length > 0) && <div className={styles['staff-member-view-row-publications']}>
                                <h5>{`${t('staff-publications')}:`}</h5>
                                {
                                    publications.map((publication) => {
                                        return <div className={styles['staff-member-single-publication']} key={publication._id}>
                                            <Link to={`/publications/${publication._id}`}>
                                                {publication.title_en}
                                            </Link>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default StaffView