import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function createActivity(data) {
    await makeRequest(
        'post',
        `${API_URL}/other-activities/add`,
        data,
        {},
        getRequestHeaders(),
    );
}