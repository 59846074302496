import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import NewsLoading from '../NewsLoading/NewsLoading.component';
import Announcements from './Announcements/Announcements.component';
import News from './News/News.component';
import styles from './NewsAndAnnouncements.module.css';

function NewsAndAnnouncements() {
    const [isNewsLoading, setIsNewsLoading] = useState(false);
    const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(false);
    const location = useLocation();
    const pathName = location.pathname;
    return (
        <div className={styles['news-and-announcements-container']}>
            {
                (isNewsLoading || isAnnouncementsLoading) && pathName !== '/' && <NewsLoading />
            }
            <div className={styles['news-and-announcements-content']}>
                <News setIsNewsLoading={setIsNewsLoading} />
                <Announcements setIsAnnouncementsLoading={setIsAnnouncementsLoading} />
            </div>
        </div>
    )
}

export default NewsAndAnnouncements