import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, Loading, CustomNavbar } from '../../Components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getProjectsByStatus } from '../../utils/API/projectsRequests';
import styles from './Projects.module.css';

function Projects() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([{}]);
    const location = useLocation();
    const pathName = location.pathname;
    const isActiveProjects = (pathName === '/active-projects');

    useEffect(() => {
        const fetchProjects = async () => {
            const projectsResponse = await getProjectsByStatus(isActiveProjects);
            setProjects(projectsResponse);
        };
        setLoading(true);
        fetchProjects()
            .then(() => {
                setLoading(false);
            });
    }, [isActiveProjects]);

    return (
        <div className={styles['active-projects-container']}>
            <CustomNavbar />
            <div className={styles['active-projects-header']} />
            <h3>
                {
                    isActiveProjects ? t('navbar-active-projects') : t('navbar-previous-projects')
                }
            </h3>
            {loading && <Loading />}
            {
                !loading &&
                <div className={styles['active-projects']}>
                    {projects.length === 0 && <h5>No active projects have been registered for this time period.</h5>}
                    {
                        projects && projects.map((project) => {
                            return <div key={project._id} className={styles["active-projects-single-project"]}>
                                <div className={styles['active-projects-single-project-title']}>
                                    {project[`title_${i18next.language}`]}
                                </div>
                                <div className={styles['active-projects-single-project-body']}>
                                    <div>
                                        <div className={styles['active-projects-single-project-labs-title']}>
                                            {`${t("labs")}:`}
                                        </div>
                                        {
                                            (project[`lab_names_${i18next.language}`]) && project[`lab_names_${i18next.language}`].map((lab) => {
                                                return <div className={styles['active-projects-single-project-single-lab']}>
                                                    {lab}
                                                </div>
                                            })
                                        }
                                        <div className={styles['active-projects-single-project-authors-supervisor']}>
                                            <div className={styles['active-projects-single-project-authors-supervisor-title']}>
                                                {`${t("supervisor")}:`}
                                            </div>
                                            <div className={styles['active-projects-single-project-authors-supervisor-name']}>
                                                {project[`supervisor_name_${i18next.language}`]}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (project[`lab_authors_names_${i18next.language}`]) && (project[`lab_authors_names_${i18next.language}`].length > 0) && <div>
                                            <div className={styles['active-projects-single-project-authors-title']}>
                                                {`${t("authors")}:`}
                                            </div>
                                            {
                                                project[`lab_authors_names_${i18next.language}`].map((author) => {
                                                    return <div className={styles['active-projects-single-project-single-author']}>
                                                        {author}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                                <div>
                                    <Link to={`../../../projects/${project._id}`}>
                                        <button className='main-btn'>
                                            {t("read-more")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        })
                    }
                </div>
            }
            <Footer />
        </div>
    )
}

export default Projects