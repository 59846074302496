import React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, CustomNavbar } from '../../Components';
import TUV from '../../assets/TUV_hellas_iso9001.jpg';
import styles from './Achievements.module.css';

function Achievements() {
    const { t } = useTranslation();
    return (
        <div>
            <CustomNavbar />
            <div className={styles['achievements-content']}>
                <h3>{t("navbar-achievements")}</h3>
                <div className={styles['achievements-content-row-img']}>
                    <img src={TUV} alt="TUV_iso9001_Certification" />
                </div>
                <div className={styles['achievements-content-row-text']}>
                    <p>{t("achievements-par-00")}</p>
                    <p>{t("achievements-par-01")}</p>
                    <ul>
                        <li>{t("achievements-ul-00-li-00")}</li>
                        <li>{t("achievements-ul-00-li-01")}</li>
                        <li>{t("achievements-ul-00-li-02")}</li>
                        <li>{t("achievements-ul-00-li-03")}</li>
                    </ul>
                    <p>{t("achievements-par-02")}</p>
                    <ul>
                        <li>{t("achievements-ul-01-li-00")}</li>
                        <li>{t("achievements-ul-01-li-01")}</li>
                        <li>{t("achievements-ul-01-li-02")}</li>
                        <li>{t("achievements-ul-01-li-03")}</li>
                    </ul>
                    <p>{t("achievements-par-03")}</p>
                    <p>{t("achievements-par-04")}</p>
                    <p>{t("achievements-par-05")}</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Achievements