import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './Loading.module.css';

// Spinner for loading
function Loading() {
    return <div className={styles['spinner-container']}>
        <Spinner animation="border" className={styles['spinner']} />
    </div>;
}

export default Loading;

