import { API_URL } from "../constants";
import { getRequestHeaders, makeRequest } from "./lifRequest";

export async function createConference(data) {
    await makeRequest(
        'post',
        `${API_URL}/conferences/add`,
        data,
        {},
        getRequestHeaders(),
    );
}