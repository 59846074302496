import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Footer, CustomNavbar, AdminButtons } from '../../Components';
import styles from './AnnouncementView.module.css';
import { deleteAnnouncement, getAnnouncement } from '../../utils/API/announcementsRequests';
import { getLab } from '../../utils/API/labRequests';
import { Modal } from 'antd';

function AnnouncementView() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const userInfo = localStorage.getItem("msl-unique-user-identifiers");
    const [loading, setLoading] = useState(false);
    const [announcement, setAnnouncement] = useState({});
    const [lab, setLab] = useState({});
    const [announcementDate, setAnnouncementDate] = useState("");
    const [fileExtension, setFilesExtension] = useState('pdf');
    const announcementID = useParams().announcementID;

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleDelete = async () => {
        setConfirmLoading(true);
        await deleteCurrentAnnouncement();
        setOpen(false);
        setConfirmLoading(false);
    };

    async function deleteCurrentAnnouncement() {
        setLoading(true);
        await deleteAnnouncement(announcementID);
        setOpen(false);
        setLoading(false);
        navigate(`/`, { replace: true });
    }

    useEffect(() => {
        const fetchData = async () => {
            const announcementResponse = await getAnnouncement(announcementID);
            setAnnouncement(announcementResponse);
            let date = announcementResponse.createdAt.substring(0, 10);
            date = date.split("-");
            setAnnouncementDate(`${date[2]}-${date[1]}-${date[0]}`)
            setFilesExtension(announcementResponse.files.substring(
                announcementResponse.files.indexOf("/") + 1,
                announcementResponse.files.lastIndexOf(";")
            ));
            if (announcementResponse.lab === "General") {
                setLab("General");
                setLoading(false);
            } else {
                const labResponse = await getLab(announcementResponse.lab);
                setLab(labResponse);
                setLoading(false);
            }
        }
        setLoading(true);
        // Check if user is logged in
        if (userInfo) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
        fetchData();
    }, [announcementID, userInfo]);

    let title = `title_${i18n.language}`;
    let description = `description_${i18n.language}`;

    return (
        <div>
            <CustomNavbar />
            {
                (!loading) && <div className={styles['announcement-section-container']}>
                    <h4>{announcement[title]}</h4>
                    <div className={styles['announcement-section-row']}>
                        <div className={styles['announcement-section-body']}>
                            <p>{announcementDate}</p>
                            {
                                (lab === "General") && <Link to="/announcements">{t("general-announcements")}</Link>
                            }
                            {
                                (lab !== "General") && <Link to={(`../../../${lab[title]}`).replace(" ", "-")} state={{ labid: lab._id, labname: lab[title] }} >
                                    <div className={styles["announcement-section-body-lab"]}>
                                        {lab[title]}
                                    </div>
                                </Link>
                            }
                            {
                                (announcement.files) && (announcement.files.length > 0) && <div>
                                    Files: &nbsp;
                                    <a download={`${announcement[title]}.${fileExtension}`} href={announcement.files} target="_blank" rel='noreferrer' className={styles['staff-member-view-cv']}>{`${announcement[title]}.${fileExtension}`}</a>
                                </div>
                            }
                            <hr />
                            <p>{parse(`${announcement[description]}`)}</p>
                        </div>
                    </div>
                    {
                        (isLoggedIn) && <AdminButtons url={'/edit-announcement'} setOpen={setOpen} id={{ announcementID: announcement._id }} />
                    }
                </div>
            }

            <Footer />

            <Modal
                title={announcement[title]}
                open={open}
                onOk={handleDelete}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <p>

                    {`Are you sure you want to delete announcement "${announcement[title]}"?`}
                    <br />
                    Once deleted, it <strong>cannot be retrieved.</strong>
                </p>
            </Modal>
        </div>
    )
}

export default AnnouncementView